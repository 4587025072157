import { z } from 'zod';
import { AttachmentSchema } from './Attachment';
import { EquipmentDraftSchema } from './Equipment';
import { PaperworkRoute } from './Paperwork';

export enum SERNI_LIST_UPDATE_STATUS {
  DRAFT = 110,
  WAITING = 120,
  TAKEN_CHARGE = 130,
  APPROVED = 240,
  CONDITIONAL_APPROVED = 220,
  RETURNED = 230,
  REJECTED = 210,
}

const SerniListUpdateStatusSchema = z.object({
  id: z.nativeEnum(SERNI_LIST_UPDATE_STATUS),
  name: z.string(),
});

export const SerniListUpdateTypeSchema = z.object({
  id: z.number(),
  name: z.string(),
});

export const SerniListUpdatePrioritySchema = z.object({
  id: z.number(),
  name: z.string(),
});

export const SerniListUpdateSchema = z.object({
  id: z.number(),
  assignee: z.string(),
  status: SerniListUpdateStatusSchema,
  createdAt: z.string().datetime(),
  createdBy: z.string(),
  updatedAt: z.string().datetime(),
  priority: SerniListUpdatePrioritySchema,
  attachmentList: z.array(AttachmentSchema),
  equipmentList: z.array(EquipmentDraftSchema),
});

export const AddSerniListUpdateSchema = z.object({
  assignee: z.string(),
  createdAt: z.date(),
  statusId: SerniListUpdateStatusSchema.shape.id,
  priorityId: SerniListUpdatePrioritySchema.shape.id,
});

const UpdateSerniListUpdateListPayloadSchema = z.object({
  id: SerniListUpdateSchema.shape.id,
  statusId: AddSerniListUpdateSchema.shape.statusId,
  priorityId: AddSerniListUpdateSchema.shape.priorityId,
  attachmentList: SerniListUpdateSchema.shape.attachmentList,
  equipmentList: SerniListUpdateSchema.shape.equipmentList,
});

const AddSerniListUpdateRequestSchema = z.object({
  priorityId: SerniListUpdatePrioritySchema.shape.id,
  assignee: AddSerniListUpdateSchema.shape.assignee,
  statusId: AddSerniListUpdateSchema.shape.statusId,
});

const DataEntryStatusSchema = z.object({
  dataEntryCompleted: z.boolean(),
  reviewCompleted: z.boolean(),
});

export const SerniListUpdateCompletedPagesSchema = z.object({
  completedPages: z.object({
    dataEntry: DataEntryStatusSchema.partial().optional(),
  }),
  id: z.number(),
});

export type AddSerniListUpdateRequest = z.infer<typeof AddSerniListUpdateRequestSchema>;
export type AddSerniListUpdate = z.infer<typeof AddSerniListUpdateSchema>;
export type SerniListUpdatePriority = z.infer<typeof SerniListUpdatePrioritySchema>;
export type SerniListUpdateType = z.infer<typeof SerniListUpdateTypeSchema>;
export type SerniListUpdate = z.infer<typeof SerniListUpdateSchema>;
export type SerniListUpdateCompletedPages = z.infer<typeof SerniListUpdateCompletedPagesSchema>;
export type UpdateSerniListUpdateListPayload = z.infer<typeof UpdateSerniListUpdateListPayloadSchema>;

/**
 * Object describing a single branch of the serniListUpdate routing
 */
export type SerniListUpdateRoute = Omit<
  PaperworkRoute,
  'context' | 'enabled' | 'active' | 'pages' | 'sections' | 'subsections'
> & {
  pages?: Record<string, SerniListUpdateRoute>;
  sections?: Record<string, SerniListUpdateRoute>;
};
