import React from 'react';
import { GridActionsCellItem } from '@mui/x-data-grid-premium';
import { CheckConfirmDialog } from '../../../../components/CheckConfirmDialog';
import { DeleteIcon } from '../../../../components/Icons';
import { Stakeholder } from '../../../../entities/Stakeholder';
import { usePaperwork } from '../../../../hooks/usePaperwork/usePaperwork';

interface DeleteStakeholderDialogProps {
  stakeholderId?: Stakeholder['id'];
  onDelete: () => void;
}

function DeleteStakeholderDialogComponent(props: DeleteStakeholderDialogProps) {
  const { stakeholderId, onDelete } = props;
  const [open, setOpen] = React.useState(false);
  const { paperwork } = usePaperwork();

  const handleClickOpen = () => {
    if (
      stakeholderId &&
      paperwork?.paperworkAttachmentList?.find((attachment) => attachment.stakeholderIdList?.includes(stakeholderId))
    ) {
      setOpen(true);
    } else {
      onDelete();
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    setOpen(false);
    onDelete();
  };

  return (
    <React.Fragment>
      <GridActionsCellItem
        key="delete"
        icon={<DeleteIcon />}
        label="Elimina parte coinvolta"
        title="Elimina parte coinvolta"
        onClick={handleClickOpen}
      />
      <CheckConfirmDialog
        open={open}
        onClose={handleClose}
        onConfirm={handleConfirm}
        dialogTitleLabel="Elimina parte coinvolta"
        dialogDescriptionLabel={
          "Eliminando la parte coinvolta verranno eliminati anche i documenti associati ad essa. Se si conferma quest'azione, sia la parte coinvolta che i documenti verranno eliminati al salvataggio."
        }
        confirmTextLabel={'Confermo di voler eliminare la parte coinvolta e tutti i documenti associati ad essa'}
        cancelButtonLabel={'Annulla'}
        confirmButtonLabel={'Conferma'}
        maxWidth="md"
      />
    </React.Fragment>
  );
}

export const DeleteStakeholderDialog = React.memo(DeleteStakeholderDialogComponent);
