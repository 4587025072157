import React, { useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { FileDropzone } from '../../../../components/FileDropzone';
import { PaperworkAttachment } from '../../../../entities/Attachment';
import { useCreateAttachmentMutation } from '../../../../services/attachment';

interface AddAttachmentDialogProps {
  onAddAttachment: (attachment: PaperworkAttachment['attachment']) => void;
}

function AddAttachmentDialogComponent(props: AddAttachmentDialogProps) {
  const { onAddAttachment } = props;
  const [open, setOpen] = React.useState(false);
  const [uploadedAttachment, setUploadedAttachment] = useState<PaperworkAttachment['attachment']>();

  const [createAttachment, createAttachmentRequest] = useCreateAttachmentMutation();

  const AddAttachmentFormSchema = useMemo(
    () =>
      z.object({
        file: z
          .object(
            {
              name: z.string(),
            },
            { required_error: 'Caricare un documento' }
          )
          .passthrough(),
      }),
    []
  );

  type AddAttachmentForm = z.infer<typeof AddAttachmentFormSchema>;

  const { control, handleSubmit, reset } = useForm<AddAttachmentForm>({
    defaultValues: {
      file: undefined,
    },
    resolver: zodResolver(AddAttachmentFormSchema),
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  async function onSubmit() {
    if (uploadedAttachment) {
      // Save the paperwork attachments (attachmentId ⨯ stakeholderId)
      onAddAttachment(uploadedAttachment);

      // Close the dialog
      handleClose();
    }
  }

  return (
    <React.Fragment>
      <Button variant="contained" onClick={handleClickOpen}>
        {'Aggiungi documento'}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: handleSubmit(onSubmit),
        }}
        fullWidth
        maxWidth="md"
        TransitionProps={{
          onExited: () => {
            reset();
            setUploadedAttachment(undefined);
          },
        }}
      >
        <DialogTitle>Aggiungi documento</DialogTitle>
        <DialogContent>
          {createAttachmentRequest.error ? (
            <Alert severity="error">{JSON.stringify(createAttachmentRequest.error, null, 2)}</Alert>
          ) : null}
          <DialogContentText component="div" display="flex" flexDirection="column" gap={2}>
            <Controller
              control={control}
              name="file"
              render={({ field, fieldState: { error } }) => (
                <FileDropzone
                  error={error}
                  onChange={async (file) => {
                    field.onChange(file);

                    if (file) {
                      const createAttachmentResponse = await createAttachment({
                        file: file as unknown as File,
                      }).unwrap();
                      setUploadedAttachment(createAttachmentResponse);
                    } else {
                      setUploadedAttachment(undefined);
                    }
                  }}
                  value={field.value as unknown as File}
                />
              )}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="inherit" onClick={handleClose}>
            {'Annulla'}
          </Button>
          <Button type="submit" disabled={!uploadedAttachment}>
            {'Aggiungi'}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export const AddAttachmentDialog = React.memo(AddAttachmentDialogComponent);
