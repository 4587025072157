import { useMemo } from 'react';
import { GridActionsColDef, GridColDef } from '@mui/x-data-grid-premium';
import { stakeholderNameColumn } from '../../../../components/DataGrid/stakeholderAddressColumn';
import { stakeholderAddressColumn } from '../../../../components/DataGrid/stakeholderNameColumn';
import { useCountryColumn } from '../../../../components/DataGrid/useCountryColumn';

export function useAddAttachmentDialogColumns() {
  const countryColumn = useCountryColumn();

  const dataGridColumns = useMemo(() => {
    const columns: Array<GridColDef | GridActionsColDef> = [
      {
        field: 'ptId',
        type: 'string',
        headerName: 'ID',
        minWidth: 30,
        flex: 0,
      },
      {
        field: 'role',
        type: 'string',
        headerName: 'Ruolo',
        minWidth: 40,
        flex: 0,
      },
      stakeholderNameColumn,
      { ...stakeholderAddressColumn, flex: 2 },
      {
        ...countryColumn,
        field: 'country',
        minWidth: 130,
        flex: 0,
      },
    ];

    return columns;
  }, [countryColumn]);

  return dataGridColumns;
}
