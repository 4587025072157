import { useMemo } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import { GridSingleSelectColDef } from '@mui/x-data-grid-premium';
import { CountryFlag, useReadCountriesQuery } from '@top-solution/microtecnica-utils';
import { PaperworkTemplate } from '../../entities/Paperwork';
import { useReadCountryListQuery } from '../../services/countries';
import { createMultipleValuesFilterOperators } from '../../utils/datagrid';

export function useAvailableCountriesColumn(): GridSingleSelectColDef<PaperworkTemplate> {
  const { data: countries } = useReadCountriesQuery();
  const { data: availableCountries } = useReadCountryListQuery();
  const theme = useTheme();

  const filteredCountries = useMemo(
    () => countries?.list.filter((c) => availableCountries?.find((availableCountry) => availableCountry.code === c.id)),
    [availableCountries, countries?.list]
  );

  const valueOptions = useMemo(
    () => filteredCountries?.map((v) => ({ value: v.id, label: v.name })),
    [filteredCountries]
  );
  return useMemo(
    () => ({
      field: 'availableCountries',
      headerName: 'Paesi',
      type: 'singleSelect',
      valueOptions,
      filterOperators: createMultipleValuesFilterOperators((item) => item as string),
      valueFormatter: (value) => (Array.isArray(value) ? (value as string[]).sort().join(', ') : value),
      valueGetter: (value, row: PaperworkTemplate) => {
        return filteredCountries
          ?.filter((c) => (row.countryCategory === 'EU' && c.isEU) || (row.countryCategory === 'EXTRA-EU' && !c.isEU))
          .map((c) => c.id);
      },
      renderCell: ({ value }) => {
        return Array.isArray(value) && value.length > 0 ? (
          <Stack direction={'row'} sx={{ display: 'flex', gap: 1 }}>
            {value.map((id: string) => (
              <Box
                key={id}
                sx={{
                  display: 'flex',
                  alignItems: 'center',

                  '.CountryFlag-root': {
                    height: '1.5em',
                    marginRight: theme.spacing(0.5),
                    boxShadow: theme.shadows['2'],
                  },
                }}
              >
                <CountryFlag countryCode={id} />
                {countries?.byISO[id]?.name || id}
              </Box>
            ))}
          </Stack>
        ) : undefined;
      },
    }),
    [countries?.byISO, filteredCountries, theme, valueOptions]
  );
}
