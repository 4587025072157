import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { FieldGridColDef } from '../fieldGridColDef';

export const stakeholderMilitaryBaseColDef: FieldGridColDef = (field, updateValue) => {
  return {
    field: field.datatype,
    headerName: field.label,
    headerAlign: 'left',
    type: 'boolean',
    minWidth: 300,
    valueGetter: (value, row) => row.fields[field.datatype],
    renderCell: ({ value: fieldValue, row }) => {
      const value = fieldValue?.value;

      return (
        <Select
          value={`${value}`}
          size="small"
          variant="outlined"
          label=""
          onChange={(e) => {
            if (e.target.value !== '') {
              updateValue({
                ...fieldValue,
                stakeholderId: row.id,
                value: `${e.target.value === undefined ? 'false' : e.target.value === 'true'}`,
              });
            }
          }}
          fullWidth
          sx={{ color: value === undefined ? 'text.disabled' : undefined, textAlign: 'left' }}
        >
          <MenuItem value="" sx={{ color: 'text.disabled' }}>
            {'Seleziona opzione'}
          </MenuItem>
          <MenuItem value="false">{'Non Forza Armata/Base Militare'}</MenuItem>
          <MenuItem value="true">{'Forza Armata/Base Militare'}</MenuItem>
        </Select>
      );
    },
  };
};
