import React from 'react';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import { SvgIconProps } from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import { formatDateTime } from '../../../../utils/dates';

export interface BaseHistoryEntryProps {
  firstEntry: boolean;
  lastEntry: boolean;
  IconComponent: (props: SvgIconProps) => React.ReactElement;
  children: React.ReactNode[];
  timestamp: Date;
}

function BaseHistoryEntryComponent(props: BaseHistoryEntryProps) {
  const { children, timestamp, firstEntry, lastEntry, IconComponent } = props;
  return (
    <TimelineItem sx={{ flexShrink: 0 }}>
      <TimelineSeparator>
        {firstEntry ? (
          <Box sx={{ paddingX: 2.85 }}>
            <TimelineDot color="primary" />
          </Box>
        ) : (
          <>
            <Box sx={{ padding: 2 }}>
              <IconComponent color="primary" sx={{ opacity: lastEntry ? 1 : 0.7 }} />
            </Box>
            <TimelineConnector />
          </>
        )}
      </TimelineSeparator>
      <TimelineContent sx={{ paddingRight: 1, paddingLeft: 0.5 }}>
        <Card sx={{ minWidth: 320 }}>
          <Stack direction="column" alignItems="flex-start" gap={1.5} paddingX={2} paddingTop={1.5} paddingBottom={1}>
            {children}
            <Stack direction="row" justifyContent="flex-start" width="100%" marginTop={1}>
              <Typography variant="caption">{formatDateTime(timestamp)}</Typography>
            </Stack>
          </Stack>
        </Card>
      </TimelineContent>
    </TimelineItem>
  );
}

export const BaseHistoryEntry = React.memo(BaseHistoryEntryComponent);
