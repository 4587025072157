import React from 'react';
import { Link, Navigate, Route, Routes, useMatch, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { styled } from '@mui/material/styles';
import { InfoTooltip } from '../../../../components/InfoTooltip/InfoTooltip';
import { BackButton, NavigationButton } from '../../../../components/NavigationPanel/NavigationButtons';
import { MaterialsSections, PaperworkPhases, DataEntryPages } from '../../../../hooks/usePaperwork/paperworkNavigation';
import { usePaperwork } from '../../../../hooks/usePaperwork/usePaperwork';
import { usePaperworkNavigation } from '../../../../hooks/usePaperwork/usePaperworkNavigation';
import { useReadPaperworkCompletedPagesQuery } from '../../../../services/paperwork';
import { AdditionalEconomicConditionsStep } from './AdditionalEconomicConditionsStep';
import { BillingYearsStep } from './BillingYearsStep';
import { MaterialKeepHyphenStep } from './MaterialKeepHyphenStep';
import { MaterialsDataStep } from './MaterialsDataStep/MaterialsDataStep';
import { PaperworkTransactionStep } from './PaperworkTransactionStep';
import { SelectPartNumbers } from './SelectPartNumbers/SelectPartNumbers';
import { TotalQuantityStep } from './TotalQuantityStep';

const InfoTooltipPlaceholder = styled(Stack)(({ theme }) => ({ flex: `0 0 ${theme.spacing(4)}` }));

export default function PaperworkMaterialsPage() {
  const navigate = useNavigate();

  const { routes, detailsPath } = usePaperworkNavigation();
  const { paperwork } = usePaperwork();
  const materialsSteps: Array<MaterialsSections> = [];
  const materialsRoute = routes[PaperworkPhases.DataEntry].pages[DataEntryPages.Materials];
  const materialsSections = materialsRoute.sections;
  const { data: paperworkCompletedPages } = useReadPaperworkCompletedPagesQuery(
    { paperworkId: paperwork?.id ?? 1 },
    { skip: !paperwork }
  );

  for (const [pageName, section] of Object.entries(materialsRoute.sections)) {
    if (section.enabled) {
      materialsSteps.push(pageName as MaterialsSections);
    }
  }

  const activeStepName = useMatch(materialsRoute.absoluteMatcher)?.params?.['*'] as MaterialsSections | undefined;
  const activeStepIndex = activeStepName ? materialsSteps.indexOf(activeStepName) : -1;
  const activeStep = activeStepName ? materialsSections[activeStepName] : undefined;

  const handleForward = async () => {
    if (activeStepIndex > materialsSteps.length - 2) {
      if (paperwork && paperworkCompletedPages) {
        navigate(detailsPath);
      }
    } else {
      navigate(materialsSteps[activeStepIndex + 1]);
    }
  };

  const handleBack = () => {
    navigate(materialsSteps[activeStepIndex - 1]);
  };

  if (!activeStep) {
    if (materialsSections && materialsSteps[0] && materialsSections[materialsSteps[0]]) {
      return <Navigate to={materialsSections[materialsSteps[0]]?.path} replace />;
    }
    return null;
  }

  const leftActions = (
    <>
      <NavigationButton component={Link} to={detailsPath}>
        {`Torna alla dashboard pratica`}
      </NavigationButton>
    </>
  );

  const stepper =
    materialsSteps.length > 1 || activeStep?.tooltip ? (
      <Stack direction="row" alignItems="center" gap={4} sx={{ padding: 2, paddingTop: 1 }}>
        <InfoTooltipPlaceholder />
        {materialsSteps.length > 1 ? (
          <Stepper activeStep={activeStepIndex} sx={{ flex: 1, height: 32 }}>
            {materialsSteps.map((stepName) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: {
                optional?: React.ReactNode;
              } = {};
              return (
                <Step key={stepName} {...stepProps}>
                  <StepLabel {...labelProps}>{materialsSections[stepName].title}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        ) : null}
        <InfoTooltipPlaceholder>
          {activeStep.tooltip ? <InfoTooltip>{activeStep.tooltip}</InfoTooltip> : null}
        </InfoTooltipPlaceholder>
      </Stack>
    ) : (
      <Box sx={{ padding: 1 }} />
    );

  return (
    <Routes>
      <Route
        path={materialsSections[MaterialsSections.BillingYears].matcher}
        element={<BillingYearsStep leftActions={leftActions} onForward={handleForward} stepper={stepper} />}
      />
      <Route
        path={materialsSections[MaterialsSections.SelectPartNumbers].matcher}
        element={
          <SelectPartNumbers
            primaryActions={<BackButton disabled={activeStepIndex === 0} onClick={handleBack} sx={{ mr: 1 }} />}
            onForward={handleForward}
            leftActions={leftActions}
            stepper={stepper}
          />
        }
      />
      <Route
        path={materialsSections[MaterialsSections.SelectPartNumbersWithoutEquipment].matcher}
        element={
          <SelectPartNumbers
            primaryActions={<BackButton disabled={activeStepIndex === 0} onClick={handleBack} sx={{ mr: 1 }} />}
            onForward={handleForward}
            leftActions={leftActions}
            stepper={stepper}
            withoutEquipment
          />
        }
      />
      <Route
        path={materialsSections[MaterialsSections.SelectPartNumbersWithoutPn].matcher}
        element={
          <SelectPartNumbers
            primaryActions={<BackButton disabled={activeStepIndex === 0} onClick={handleBack} sx={{ mr: 1 }} />}
            onForward={handleForward}
            leftActions={leftActions}
            stepper={stepper}
            withoutPn
          />
        }
      />
      <Route
        path={materialsSections[MaterialsSections.MaterialsData].matcher}
        element={
          <MaterialsDataStep
            primaryActions={<BackButton disabled={activeStepIndex === 0} onClick={handleBack} sx={{ mr: 1 }} />}
            leftActions={leftActions}
            onForward={handleForward}
            stepper={stepper}
          />
        }
      />
      <Route
        path={materialsSections[MaterialsSections.AdditionalEconomicConditions].matcher}
        element={
          <AdditionalEconomicConditionsStep
            onBack={handleBack}
            leftActions={leftActions}
            onForward={handleForward}
            stepper={stepper}
          />
        }
      />
      <Route
        path={materialsSections[MaterialsSections.PaperworkTransaction].matcher}
        element={
          <PaperworkTransactionStep
            onBack={handleBack}
            leftActions={leftActions}
            onForward={handleForward}
            stepper={stepper}
          />
        }
      />
      <Route
        path={materialsSections[MaterialsSections.TotalQuantity].matcher}
        element={
          <TotalQuantityStep
            onBack={handleBack}
            leftActions={leftActions}
            onForward={handleForward}
            stepper={stepper}
          />
        }
      />
      <Route
        path={materialsSections[MaterialsSections.MaterialKeepHyphen].matcher}
        element={
          <MaterialKeepHyphenStep
            onBack={handleBack}
            leftActions={leftActions}
            onForward={handleForward}
            stepper={stepper}
          />
        }
      />
    </Routes>
  );
}
