import { z } from 'zod';
import { DataEntryPages } from '../hooks/usePaperwork/paperworkNavigation';
import { AttachmentSchema, PaperworkAttachmentSchema } from './Attachment';
import { MaterialSchema } from './Material';
import { MovementTypeSchema } from './MovementType';
import { PecStatusSchema } from './Pec';
import { ReviewSchema } from './Review';
import { SaleTypeSchema } from './SaleType';
import { StakeholderSchema } from './Stakeholder';

export enum PAPERWORK_STATUS {
  DRAFT = 110,
  WAITING = 120,
  TAKEN_CHARGE = 130,
  APPROVED = 240,
  CONDITIONAL_APPROVED = 220,
  RETURNED = 230,
  REJECTED = 210,
  PREPARED = 300,
  SIGNED = 400,
  SENT = 410,
}

export enum PAPERWORK_TYPE {
  ChiusuraPratica = 'Chiusura Pratica',
  DualUseGenerale = 'Dual Use - Generale',
  DualUseGlobaleIndividuale = 'Dual Use - Globale Individuale',
  DualUseIndividuale = 'Dual Use - Individuale',
  DualUseLicenzaZero = 'Dual Use - Licenza Zero',
  Agt3FornitoriProduttori = 'L. 185/90 - AGT3 (Fornitori Produttori)',
  Agt5 = 'L. 185/90 - AGT5',
  Agt5Spares = 'L. 185/90 - AGT5 (Spares)',
  Intangibile = 'L. 185/90 - Intangibile',
  IntermediazioneFramework = 'L. 185/90 - Intermediazione (Framework)',
  Lgp = 'L. 185/90 - LGP',
  Lgt = 'L. 185/90 - LGT',
  LicenzaFramework = 'L. 185/90 - Licenza Framework',
  LicenzaIndividuale = 'L. 185/90 - Licenza Individuale',
  NullaOsta = 'L. 185/90 - Nulla Osta',
  IndividualeTangibile = 'L. 185/90 - Individuale/Tangibile',
  Precisazioni = 'Precisazioni',
  Proroga = 'Proroga',
  Variazione = 'Variazione',
  IntermediazioneOrdini = 'L. 185/90 - Intermediazione (Ordini)',
}

export enum PAPERWORK_MOVEMENT {
  ImportazioneDefinitiva = 'Importazione Definitiva',
  EsportazioneDefinitiva = 'Esportazione Definitiva',
  RestituzioneMaterialeRiparatoGaranzia = 'Restituzione Materiale Riparato/Garanzia',
  EsportazioneMaterialeDaRiparare = 'Esportazione Materiale da Riparare',
  TemporaneaImportazioneESuccessivaRiesportazione = 'Temporanea Importazione e Successiva Riesportazione',
  TemporaneaEsportazioneESuccessivaReimportazione = 'Temporanea Esportazione e Successiva Reimportazione',
}

export enum PAPERWORK_FIELD_DATATYPE {
  MATERIAL_PART_NUMBER = 'material-part-number',
  MATERIAL_SERIAL_NUMBER = 'material-serial-number',
  MATERIAL_DESCRIPTION = 'material-description',
  PROGRAM = 'program',
  SERNI_CODE = 'serni-code',
  MATERIAL_ECCN = 'material-eccn',
  HTS = 'hts',
  EQUIPMENT = 'material-equipment',
  MATERIAL_ORDER_ROW = 'material-order-row',
  MATERIAL_QUANTITY = 'material-quantity',
  MATERIAL_QUANTITY_PER_PN = 'material-quantity-per-pn',
  MATERIAL_UNITY_OF_MEASURE = 'material-unity-of-measure',
  MATERIAL_UNIT_VALUE = 'material-unit-value',
  MATERIAL_REPAIR_UNIT_VALUE = 'material-repair-unit-value',
  MATERIAL_CURRENCY = 'material-currency',
  MATERIAL_PRICE_ADJUSTMENTS = 'material-price-adjustments',
  MATERIAL_PRICE_ADJUSTMENTS_GREATER_THAN = 'material-price-adjustments-greater-than',
  MATERIAL_PRICE_ADJUSTMENTS_VALUE = 'material-price-adjustments-value',
  MATERIAL_TECH_DATA = 'material-tech-data',
  MATERIAL_TECH_DATA_DESCRIPTION = 'material-tech-data-description',
  MATERIAL_TECH_DATA_MAN_MONTH_QUANTITY = 'material-tech-data-man-month-quantity',
  MATERIAL_TECH_DATA_CUSTOMS_VALUE = 'material-tech-data-customs-value',
  MATERIAL_TECH_DATA_SALE_VALUE = 'material-tech-data-sale-value',
  MATERIAL_TECH_DATA_SERNI = 'material-tech-data-serni',
  MATERIAL_TECH_DATA_ECCN = 'material-tech-data-eccn',
  MATERIAL_TOTAL_QUANTITY = 'material-total-quantity',
  MATERIAL_BILLING_YEARS = 'material-billing-years',
  MATERIAL_ORDER_DATE = 'material-order-date',
  MATERIAL_ORDER_NUMBER = 'material-order-number',
  MATERIAL_FREE_SALE = 'material-free-sale',
  MATERIAL_CUSTOMS_UNITARY_SALE = 'material-customs-unitary-sale',
  MATERIAL_MILESTONES = 'material-milestones',
  MATERIAL_NOT_RECURRING_COSTS_PAYMENTS_TRANCHES = 'material-not-recurring-costs-payments-tranches',
  STAKEHOLDER_END_USER_IS_MT_OR_MATCHES_SHIP_TO = 'stakeholder-end-user-is-mt-or-matches-ship-to',
  STAKEHOLDER_BILL_TO_MATCHES_SHIP_TO_OR_ORDER_FROM = 'stakeholder-bill-to-matches-ship-to-or-order-from',
  MATERIAL_TRANSFER_MODE = 'material-transfer-mode',
  STAKEHOLDER_MILITARY_BASE = 'stakeholder-military-base',
  MATERIAL_ANTICIPATED_PAYMENTS = 'material-anticipated-payments',
  MATERIAL_TRANSACTION_TOTAL_VALUE = 'material-total-transaction-value',
  MATERIAL_TRANSACTION_TOTAL = 'material-total-transaction',
  ATTACHMENTS_DIGITALLY_SIGNED_RADIO_BUTTON = 'attachments-digitally-signed-radio-button',
  ATTACHMENTS_ORIGINAL_AVAILABLE_RADIO_BUTTON = 'attachments-original-available-radio-button',
  ATTACHMENTS_DIGITALLY_SIGNED = 'attachments-digitally-signed',
  ATTACHMENTS_ORIGINAL_AVAILABLE = 'attachments-original-available',
  ATTACHMENTS_ORDER_NUMBER = 'attachments-order-number',
  ATTACHMENTS_ORDER_DATE = 'attachments-order-date',
  MATERIAL_FREE_SALE_JUSTIFICATION = 'material-free-sale-justification',
  MATERIAL_FREE_SALE_CUSTOM_VALUE = 'material-free-sale-custom-value',
  MATERIAL_CUSTOMER_BILLING_VALUE_PER_PN = 'material-customer-billing-value-per-pn',
  MATERIAL_VENDOR_BILLING_VALUE_PER_PN = 'material-vendor-billing-value-per-pn',
  MATERIAL_KEEP_HYPHEN = 'material-keep-hyphen',
  LOI_DATE = 'loi-date',
  LOI_REFERENCE = 'loi-reference',
  PAYMENT_TERMS_CUSTOMER_PAYMENT = 'payment-terms-customer-payment',
  PAYMENT_TERMS_VENDOR_PAYMENT = 'payment-terms-vendor-payment',
  TOTAL_QUANTITY = 'total-quantity',

  ATTACHMENTS_DECLARATION_OF_COMMITMENT = 'attachments-declaration-of-commitment',
  CONTRACTUAL_TERMS_YIELD = 'contractual-terms-yield',
  CONTRACTUAL_TERMS_INBOUND_YIELD = 'contractual-terms-inbound-yield',
  CONTRACTUAL_TERMS_OUTBOUND_YIELD = 'contractual-terms-outbound-yield',
  CONTRACTUAL_TERMS_PAYMENT_TERMS = 'contractual-terms-payment-terms',
  ATTACHMENTS_DECLARATION_OF_LEGAL_REPRESENTATIVE = 'attachments-declaration-of-legal-representative',
  ATTACHMENT_CONTRACT = 'attachment-contract',
  ATTACHMENT_TECHNICAL_SPECIFICATIONS = 'attachment-technical-specifications',
  ATTACHMENT_EUS_PROFILE = 'attachment-eus-profile',

  PAPERWORK_DIGITALLY_SIGNED = 'paperwork-digitally-signed',
}

export enum PAPERWORK_AGING {
  ON_TIME = 'In tempo',
  OVERDUE = 'In Ritardo',
  URGENT = 'Urgente',
}

const PaperworkIdSchema = z.number();

export const PaperworkStatusSchema = z.object({
  id: z.nativeEnum(PAPERWORK_STATUS),
  name: z.string(),
});

export const PaperworkTypeSchema = z.object({
  id: z.number(),
  name: z.string(),
});

export const PaperworkPrioritySchema = z.object({
  id: z.number(),
  name: z.string(),
});

const PaperworkFieldSchema = z.object({
  id: z.number(),
  datatype: z.nativeEnum(PAPERWORK_FIELD_DATATYPE),
  label: z.string(),
  linkedTo: z.string(),
  isRequired: z.boolean(),
});

export const PaperworkFieldValueSchema = z.object({
  id: z.number(),
  fieldDatatype: z.nativeEnum(PAPERWORK_FIELD_DATATYPE),
  value: z.string().nullable().optional(),
  progIndex: z.number(),
  attachmentId: AttachmentSchema.shape.id.optional(),
  materialId: z.string().optional(),
  stakeholderId: z.string().optional(),
});

export const PaperworkFieldValueDraftSchema = PaperworkFieldValueSchema.omit({
  id: true,
  value: true,
}).extend({
  id: PaperworkFieldValueSchema.shape.id.optional(),
  draftId: z.string(),
  value: PaperworkFieldValueSchema.shape.value.optional().nullable(),
});

const PatchPaperworkValueListRequestSchema = z.object({
  paperworkId: PaperworkIdSchema,
  create: PaperworkFieldValueSchema.omit({ id: true }).array(),
  update: PaperworkFieldValueSchema.array(),
  delete: PaperworkFieldValueSchema.pick({ id: true }).array(),
});

export const PaperworkContextSchema = z.object({
  id: z.number(),
  availableFromStatus: PaperworkStatusSchema,
  phase: z.string(),
  page: z.string().optional(),
  section: z.string().optional(),
  subsection: z.string().optional(),
  fieldList: z.array(PaperworkFieldSchema).optional(),
});

export const PaperworkTemplateSchema = z.object({
  id: z.number(),
  countryCategory: z.enum(['EU', 'EXTRA-EU']),
  movementType: MovementTypeSchema.nullable(),
  paperworkType: PaperworkTypeSchema,
  saleType: SaleTypeSchema,
  paperworkContextList: z.array(PaperworkContextSchema),
});

const PaperworkHistoryEntrySchema = z.object({
  id: z.number(),
  status: PaperworkStatusSchema,
  updatedAt: z.string().datetime(),
  username: z.string(),
  note: z.string().optional(),
});

export const PaperworkSchema = z.object({
  id: PaperworkIdSchema,
  assignee: z.string(),
  status: PaperworkStatusSchema,
  paperworkTemplate: PaperworkTemplateSchema,
  materialList: z.array(MaterialSchema).optional(),
  paperworkAttachmentList: z.array(PaperworkAttachmentSchema).optional(),
  programList: z.array(z.string()).optional(),
  stakeholderList: StakeholderSchema.array().optional(),
  country: z.string(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
  priority: PaperworkPrioritySchema,
  historyList: PaperworkHistoryEntrySchema.array(),
  pecStatus: PecStatusSchema.optional(),
});

const SearchPaperworkListPayloadSchema = z.object({
  pn: z.string().array(),
  serniCode: z.string().array(),
  eccn: z.string().array(),
});

export const AddPaperworkSchema = z.object({
  requester: z.string(),
  createdAt: z.date(),
  statusId: PaperworkStatusSchema.shape.id,
  movementTypeId: MovementTypeSchema.shape.id.optional().nullable(),
  paperworkTypeId: PaperworkTypeSchema.shape.id,
  saleType: SaleTypeSchema.shape.id,
  country: z.string(),
  priorityId: PaperworkPrioritySchema.shape.id,
});

const AddPaperworkRequestSchema = z.object({
  templateId: z.number(),
  country: z.string(),
  priorityId: PaperworkPrioritySchema.shape.id,
});

const DataEntryStatusSchema = z
  .object({
    dataEntryCompleted: z.boolean(),
    reviewCompleted: z.boolean(),
  })
  .partial();

const DataEntryTypeSchema = z
  .object({
    [DataEntryPages.Materials]: DataEntryStatusSchema.optional(),
    [DataEntryPages.Stakeholders]: DataEntryStatusSchema.optional(),
    [DataEntryPages.Attachments]: DataEntryStatusSchema.optional(),
    [DataEntryPages.ContractualTerms]: DataEntryStatusSchema.optional(),
  })
  .partial();

export const PaperworkCompletedPagesSchema = z.object({
  completedPages: DataEntryTypeSchema.optional(),
  id: z.number(),
});

export const PaperworkReviewSchema = z.object({
  attachmentId: AttachmentSchema.shape.id.optional(),
  contextId: PaperworkContextSchema.shape.id,
  materialId: MaterialSchema.shape.id.optional(),
  paperworkId: PaperworkSchema.shape.id,
  review: ReviewSchema,
  stakeholderId: StakeholderSchema.shape.id.optional(),
});

export const PaperworkReviewUpdateSchema = z.object({
  ...PaperworkReviewSchema.shape,
  review: z.object({
    comment: ReviewSchema.shape.comment,
    isApproved: ReviewSchema.shape.isApproved,
  }),
});

export const PaperworkSendPecPayloadSchema = z.object({
  paperworkId: PaperworkSchema.shape.id,
  attachmentIdList: z.array(AttachmentSchema.shape.id),
  body: z.string().min(1),
  subject: z.string().min(1),
  to: z.array(z.string().email()),
});

export type AddPaperworkRequest = z.infer<typeof AddPaperworkRequestSchema>;
export type AddPaperwork = z.infer<typeof AddPaperworkSchema>;
export type PaperworkPriority = z.infer<typeof PaperworkPrioritySchema>;
export type PaperworkStatus = z.infer<typeof PaperworkStatusSchema>;
export type PaperworkType = z.infer<typeof PaperworkTypeSchema>;
export type PaperworkField = z.infer<typeof PaperworkFieldSchema>;
export type PaperworkFieldValue = z.infer<typeof PaperworkFieldValueSchema>;
export type PaperworkFieldValueDraft = z.infer<typeof PaperworkFieldValueDraftSchema>;
export type PatchPaperworkValueListRequest = z.infer<typeof PatchPaperworkValueListRequestSchema>;
export type PaperworkContext = z.infer<typeof PaperworkContextSchema>;
export type PaperworkTemplate = z.infer<typeof PaperworkTemplateSchema>;
export type PaperworkHistoryEntry = z.infer<typeof PaperworkHistoryEntrySchema>;
export type Paperwork = z.infer<typeof PaperworkSchema>;
export type SearchPaperworkListPayload = z.infer<typeof SearchPaperworkListPayloadSchema>;
export type PaperworkCompletedPages = z.infer<typeof PaperworkCompletedPagesSchema>;
export type PaperworkReview = z.infer<typeof PaperworkReviewSchema>;
export type PaperworkReviewUpdate = z.infer<typeof PaperworkReviewUpdateSchema>;
export type PaperworkSendPecPayload = z.infer<typeof PaperworkSendPecPayloadSchema>;

/**
 * Object describing a single branch of the paperwork routing
 */
export type PaperworkRoute = {
  /**
   * The path of this route, relative to the parent route
   */
  path: string;
  /**
   * The absolute path of this route (relative to the app root, / )
   */
  absolutePath: string;
  /**
   * The title to display on the route page
   */
  title: string;
  tooltip?: string | undefined;
  /**
   * The route order. 0 -> the route is shown firs.
   */
  order?: number | undefined;
  /**
   * A string that can be used to match this route, relative to the Details page ( /paperworks/:id )
   */
  matcher: string;
  /**
   * A string that can be used to match this route, relative to the app root ( / )
   */
  absoluteMatcher: string;
  /**
   * The Paperwork template dictates that this route must be shown to the user
   */
  active: boolean;
  /**
   * The user is currently allowed to visit this route
   */
  enabled: boolean;
  /**
   * The context associated to this route
   */
  context?: PaperworkContext;
  /**
   * Pages sub-routes (only for Phases, undefined otherwise)
   */
  pages?: Record<string, PaperworkRoute>;
  /**
   * Sections sub-routes (only for Pages, undefined otherwise)
   */
  sections?: Record<string, PaperworkRoute>;
  /**
   * Subsection sub-routes (only for Sections, undefined otherwise)
   */
  subsections?: Record<string, PaperworkRoute>;
  icon?: React.ReactNode;
};

export type PaperworkRouteConfiguration = Pick<PaperworkRoute, 'order'> & {
  title: (paperwork: Paperwork) => string;
  icon?: React.ReactNode;
};

export function getPaperworkFieldValueDraftId(
  fieldValue: Omit<PaperworkFieldValue | PaperworkFieldValueDraft, 'id' | 'value'>
): string {
  const {
    fieldDatatype,
    attachmentId: attachmentId,
    materialId: materialId,
    stakeholderId: stakeholderId,
    progIndex,
  } = fieldValue as PaperworkFieldValueDraft;

  return ['draft', fieldDatatype, attachmentId, materialId, stakeholderId, progIndex || '0']
    .filter(Boolean)
    .map((s) => `${s}`.toLocaleLowerCase().replace(/[\W_]+/g, '-'))
    .join('---');
}

export function addDraftIdToPaperworkFieldValue(
  fieldValue: Omit<PaperworkFieldValueDraft, 'draftId'> & { draftId?: PaperworkFieldValueDraft['draftId'] | undefined }
): PaperworkFieldValueDraft {
  return {
    ...fieldValue,
    draftId: fieldValue.draftId ?? getPaperworkFieldValueDraftId(fieldValue),
  };
}
