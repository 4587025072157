import { useMemo } from 'react';
import { PAPERWORK_FIELD_DATATYPE, PAPERWORK_MOVEMENT, PAPERWORK_TYPE } from '../../entities/Paperwork';
import { STAKEHOLDER_ROLE } from '../../entities/Stakeholder';
import { useCountryChecks } from '../useCountryChecks';
import { usePaperwork } from './usePaperwork';
import { usePaperworkFields } from './usePaperworkFields';

export function useAttachmentAlerts() {
  const { paperwork, matchPaperworkType } = usePaperwork();
  const { isCountryNato, isCountryExtraNato } = useCountryChecks();
  const { fieldValues } = usePaperworkFields();

  return useMemo(() => {
    const alerts: string[] = [];

    if (!paperwork || !fieldValues) {
      return alerts;
    }

    const paperworkMovement = paperwork.paperworkTemplate.movementType?.name;
    const shipToStakeholder = paperwork.stakeholderList?.find(
      (stakeholder) => stakeholder.role === STAKEHOLDER_ROLE.SHIP_TO
    );

    const endUserStakeholder =
      paperwork.stakeholderList?.find((stakeholder) => stakeholder.role === STAKEHOLDER_ROLE.END_USERS) ??
      paperwork.stakeholderList?.find((stakeholder) => stakeholder.role === STAKEHOLDER_ROLE.SHIP_TO);

    const shipToCountry = shipToStakeholder?.country;
    const endUserCountry = endUserStakeholder?.country;

    const endUserIsMilitaryBase =
      Object.values(fieldValues).find(
        (value) =>
          endUserStakeholder &&
          value.stakeholderId === endUserStakeholder.id &&
          value.fieldDatatype === PAPERWORK_FIELD_DATATYPE.STAKEHOLDER_MILITARY_BASE
      )?.value === 'true';

    // #### Matrice 8 ############################################################
    if (matchPaperworkType(PAPERWORK_TYPE.IndividualeTangibile)) {
      // L. 185 - Licenza individuale - Esportazione definitiva

      if (isCountryExtraNato(shipToCountry) && !endUserIsMilitaryBase) {
        // Paese destinatario Extra NATO / End User azienda (non Forza Armata) Extra Nato
        // Ordine - è obbligatorio che ce ne sia almeno uno
        // Accettazione d'ordine - obbligatorio
        // EUC - facoltativi, almeno uno deve essere caricato
        // EUS - facoltativi, almeno uno deve essere caricato
        // IIC - alternativi solo nel caso si applichi l'EUS ( l'utente deve obbligatoriamente caricarne almeno uno dei 3 )
        // Certificato di ditta abilitata - alternativi solo nel caso si applichi l'EUS ( l'utente deve obbligatoriamente caricarne almeno uno dei 3 )
        // Copia di una licenza valida di esportazione - alternativi solo nel caso si applichi l'EUS ( l'utente deve obbligatoriamente caricarne almeno uno dei 3 )
        alerts.push('Se l’utilizzatore finale è una ditta privata, è obbligatorio un end user statement');
        alerts.push(
          'Se l’utilizzatore finale è una ditta o un ente governativo, è obbligatorio un end user certificate'
        );
      }

      if (paperworkMovement === PAPERWORK_MOVEMENT.TemporaneaEsportazioneESuccessivaReimportazione) {
        // Paese destinatario ed end user NATO / Extra Nato
        // Ordine - è obbligatorio che ce ne sia almeno uno
        // Accettazione d'ordine - obbligatorio
        // EUS - obbligatorio
        // IIC - alternativi - l'utente deve obbligatoriamente caricarne almeno uno dei 3
        // Certificato di ditta abilitata - l'utente deve obbligatoriamente caricarne almeno uno dei 3
        // Copia di una licenza valida di esportazione - l'utente deve obbligatoriamente caricarne almeno uno dei 3
        if (
          (isCountryNato(shipToCountry) || isCountryExtraNato(shipToCountry)) &&
          (isCountryNato(endUserCountry) || isCountryExtraNato(endUserCountry))
        ) {
          alerts.push(
            "L'utente deve obbligatoriamente caricarne un documento tra: IIC, Certificato di ditta abilitata, Copia di una licenza valida di esportazione"
          );
        }

        // #### Matrice 8 ############################################################
      }
    }

    return alerts;
  }, [fieldValues, isCountryExtraNato, isCountryNato, matchPaperworkType, paperwork]);
}
