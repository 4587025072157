import React from 'react';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { ClipboardTextIcon } from '../../../../components/Icons';
import { UserName } from '../../../../components/User/UserName';
import { PaperworkReview } from '../../../../entities/Paperwork';
import { usePaperwork } from '../../../../hooks/usePaperwork/usePaperwork';
import { DetailPanelRow } from '../DetailPanel';
import { BaseHistoryEntry, BaseHistoryEntryProps } from './BaseHistoryEntry';

function ReviewCommentHistoryEntryComponent(
  props: Pick<BaseHistoryEntryProps, 'lastEntry'> & {
    review: PaperworkReview;
  }
) {
  const { review, lastEntry, ...baseHistoryEntryProps } = props;

  const { paperwork } = usePaperwork();

  const reviewMaterial =
    props.review.materialId !== undefined
      ? paperwork?.materialList?.find((material) => material.id === props.review.materialId)
      : undefined;
  const reviewStakeholder =
    props.review.stakeholderId !== undefined
      ? paperwork?.stakeholderList?.find((stakeholder) => stakeholder.id === props.review.stakeholderId)
      : undefined;
  const reviewAttachment =
    props.review.attachmentId !== undefined
      ? paperwork?.paperworkAttachmentList?.find((attachment) => attachment.attachment.id === props.review.attachmentId)
      : undefined;

  return (
    <BaseHistoryEntry
      {...baseHistoryEntryProps}
      timestamp={new Date(review.review.updatedAt)}
      lastEntry={lastEntry}
      firstEntry={false}
      IconComponent={ClipboardTextIcon}
    >
      <Stack direction="row" justifyContent="space-between" width="100%">
        <Typography variant="body1" fontWeight={500}>
          <UserName username={review.review.author} />
        </Typography>
        <Chip label="Dato rifiutato" color="error" variant="outlined" size="small" />
      </Stack>
      <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
        <Typography variant="body2" sx={{ opacity: 0.9 }}>{`“${review.review.comment}”`}</Typography>
        {/* <PaperworkStatusChip status={historyEntry.status.id} /> */}
      </Stack>
      <Typography component="div" variant="body2" width="100%">
        <Box component="ul" sx={{ padding: 0, width: '100%', '&, & span, & div': { fontSize: 'inherit' } }}>
          {reviewMaterial ? (
            <>
              <DetailPanelRow rowTitle="PN" direction="row" rowValue={reviewMaterial?.pn} sx={{ padding: 0 }} />
              <DetailPanelRow
                rowTitle="Program"
                direction="row"
                rowValue={reviewMaterial?.program}
                sx={{ padding: 0 }}
              />
            </>
          ) : null}
          {reviewStakeholder ? (
            <>
              <DetailPanelRow
                ellipsis
                rowTitle="Nome"
                direction="row"
                rowValue={reviewStakeholder?.name}
                sx={{ padding: 0 }}
              />
              <DetailPanelRow
                rowTitle="Paese"
                direction="row"
                rowValue={reviewStakeholder?.country}
                sx={{ padding: 0 }}
              />
            </>
          ) : null}
          {reviewAttachment ? (
            <>
              <DetailPanelRow
                rowTitle="Nome"
                direction="row"
                rowValue={reviewAttachment.attachment.name}
                sx={{ padding: 0 }}
              />
            </>
          ) : null}
        </Box>
      </Typography>
    </BaseHistoryEntry>
  );
}

export const ReviewCommentHistoryEntry = React.memo(ReviewCommentHistoryEntryComponent);
