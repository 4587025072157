import { useEffect, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Material } from '../entities/Material';
import { usePatchMaterialListMutation } from '../services/material';
import {
  discardOperations,
  selectMaterialsState,
  createMaterial,
  updateMaterial,
  deleteMaterial,
  setSavingState,
  replaceMaterials,
} from '../store/materialsStateSlice';
import { usePaperwork } from './usePaperwork/usePaperwork';

export function useMaterials() {
  const dispatch = useDispatch();
  const { paperwork } = usePaperwork();

  const [_, patchMaterialListRequest] = usePatchMaterialListMutation();

  const { paperworkId, materials, operations } = useSelector(selectMaterialsState);

  useEffect(() => {
    if (paperwork) {
      if (paperwork.id !== paperworkId) {
        dispatch(replaceMaterials({ paperworkId: paperwork.id, materials: paperwork.materialList ?? [] }));
      }
    }
  }, [dispatch, paperwork, paperworkId]);

  return useMemo(() => {
    function checkDuplicateMaterial(newMaterial: Material): boolean {
      return Boolean(
        Object.values(materials).find((material) => {
          if (newMaterial.pn !== material.pn) {
            return false;
          }
          if (newMaterial.description !== material.description) {
            return false;
          }
          if (newMaterial.serniCode !== material.serniCode) {
            return false;
          }
          if (newMaterial.program !== material.program) {
            return false;
          }
          if (newMaterial.hts !== material.hts) {
            return false;
          }
          if (newMaterial.eccn !== material.eccn) {
            return false;
          }

          return true;
        })
      );
    }

    return {
      /**
       * Save the state of all materials for the current paperwork.
       */
      saveMaterials: async () => {
        /**
         * Dispatch the saving state
         * Do not save directly but "enqueue" the saving action, so that redux does that after
         * applying all the operations
         */
        dispatch(setSavingState({ paperworkId: paperwork?.id ?? -1, saving: true }));
      },
      /**
       * Create a material. All changes are kept on redux until `saveMaterials` is called.
       */
      createMaterial: (material: Material) => dispatch(createMaterial({ material })),
      /**
       * Update a material. All changes are kept on redux until `saveMaterials` is called.
       */
      updateMaterial: (material: Material) => dispatch(updateMaterial({ material })),
      /**
       * Delete a material. All changes are kept on redux until `saveMaterials` is called.
       */
      deleteMaterial: (materialId: Material['id']) => dispatch(deleteMaterial({ materialId })),
      /**
       * The RTKQuery request object for the PATCH save values request.
       */
      patchMaterialListRequest,
      discardOperations: () => dispatch(discardOperations()),
      /**
       * The redux field values state. This propery contains the "local" changes, waiting to be saved on BE.
       */
      materials: materials,
      /**
       * Key-value registry for the pending CRUD operations for each field.
       */
      operations: operations,
      /**
       * The count of pending field values operations.
       */
      operationsCount: Object.values(operations).filter((value) => value !== undefined).length,
      /**
       * Check if the given material is identical to an already added material
       */
      checkDuplicateMaterial,
    };
  }, [patchMaterialListRequest, materials, operations, dispatch, paperwork?.id]);
}
