import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { v4 } from 'uuid';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Stack from '@mui/material/Stack';
import { PartNumberListAutocomplete } from '../../../../../../components/Autocompletes/PartNumberListAutocomplete';
import { BottomUpSearchResult } from '../../../../../../entities/BottomUpSearch';
import { Material } from '../../../../../../entities/Material';
import { useLazyBottomUpSearchQuery } from '../../../../../../services/partNumberApi';
import { ImportMaterialsSearchForm, ImportMaterialsSearchFormSchema } from './ImportMaterialsSearchForm';

interface SearchTabProps {
  onNext: (searchResults: Material[]) => void;
  onCancel: () => void;
}

function isMaterialComplete(m: BottomUpSearchResult): boolean {
  if (
    m.item.length > 0 &&
    m.enditemDescription &&
    m.enditemDescription.length > 0 &&
    m.programId &&
    m.programId.length > 0 &&
    m.serniCode &&
    m.serniCode.length > 0 &&
    m.italianClassificationECCN &&
    m.italianClassificationECCN.length > 0 &&
    m.italianClassificationHTSN &&
    m.italianClassificationHTSN.length > 0
  )
    //skip technical data because it's in another view
    return true;
  return false;
}

function SearchTabComponent(props: SearchTabProps) {
  const { onNext, onCancel } = props;

  const { control, handleSubmit, formState } = useForm<ImportMaterialsSearchForm>({
    defaultValues: { partNumbers: [] },
    resolver: zodResolver(ImportMaterialsSearchFormSchema),
  });

  const [searchPartNumbers, searchPartNumbersRequest] = useLazyBottomUpSearchQuery();

  const onSubmit: Parameters<typeof handleSubmit>[0] = async (data) => {
    const res = await searchPartNumbers({
      filters: {
        pn: data.partNumbers,
        eccn: [],
        pgr: [],
        vendor: [],
      },
      fields: {
        customer: false,
        enditem: false,
        enduser: false,
        foreignClassification: true,
        italianClassification: true,
        program: true,
        serni: true,
        techDataClassification: true,
      },
    }).unwrap();

    // Create a map to filter duplicate results from bottom up
    const filteredBottomSearchResultMap = new Map<string, Material>();
    for (const r of res) {
      if (filteredBottomSearchResultMap.has(`${r.item}-${r.programId}-${r.italianClassificationECCN}-${r.serniCode}`))
        continue;
      filteredBottomSearchResultMap.set(`${r.item}-${r.programId}-${r.italianClassificationECCN}-${r.serniCode}`, {
        id: v4(),
        pn: r.item,
        description: r.enditemDescription,
        program: r.programId,
        serniCode: r.serniCode,
        eccn: r.italianClassificationECCN,
        hts: r.italianClassificationHTSN,
        techDataClassification: r.techDataClassificationECCN,
        imported: isMaterialComplete(r),
      });
    }

    onNext(Array.from(filteredBottomSearchResultMap.values()));
  };

  return (
    <Stack direction="column" component="form" onSubmit={handleSubmit(onSubmit)} onReset={onCancel} flex={1}>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2, minHeight: 0 }}>
        <Stack direction="column" alignItems="stretch" gap={4} paddingTop={1}>
          <Box>
            <Alert
              severity="info"
              sx={{
                '& p': {
                  marginY: 0.5,
                },
              }}
            >
              <AlertTitle>{'Importazione e inserimento materiali'}</AlertTitle>
              <p>
                {
                  'Inserire nel campo “Part Numbers” i componenti da includere nella richiesta di autorizzazione e premere Invio.'
                }
              </p>
              <p>
                {
                  'Selezionare “Cerca materiali”. La schermata successiva restituirà tutti i materiali attivi in SAP al momento della tua richiesta, seleziona quindi le righe di tuo interesse.'
                }
              </p>
              <p>{'Le informazioni relative alle classifiche e al codice SERNI si compileranno in automatico.'}</p>
              <p>
                {
                  'Qualora non trovassi uno dei materiali inseriti nella ricerca, potrai aggiungere manualmente i componenti ed integrare manualmente le informazioni necessarie per la presentazione dell’istanza.              '
                }
              </p>
            </Alert>
          </Box>
          <Box sx={{ flex: 1 }}>
            <Controller
              control={control}
              name="partNumbers"
              render={({ field: { ...field } }) => (
                <PartNumberListAutocomplete
                  {...field}
                  placeholder="Inserisci un P/N e premi Invio, o incolla una lista di P/N"
                />
              )}
            />
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={onCancel}>
          {'Annulla'}
        </Button>
        <LoadingButton
          type="submit"
          variant="contained"
          loading={searchPartNumbersRequest.isFetching}
          disabled={!formState.isValid}
        >
          {'Cerca materiali'}
        </LoadingButton>
      </DialogActions>
    </Stack>
  );
}

export const SearchTab = React.memo(SearchTabComponent);
