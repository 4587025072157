import { z } from 'zod';
import { EquipmentSchema } from './Equipment';

export const MaterialSchema = z.object({
  id: z.string(),
  pn: z.string().min(1).optional(),
  description: z.string().optional(),
  program: z.string().min(1).optional(),
  serniCode: EquipmentSchema.shape.serniCode.optional(),
  eccn: z.string().optional(),
  techDataClassification: z.string().optional(),
  hts: z.string().optional(),
  imported: z.boolean(),
  importReason: z.string().min(1).optional(),
});

export const PTImportedMaterialSchema = MaterialSchema.omit({ id: true, pn: true, imported: true }).extend({
  pn: MaterialSchema.shape.pn.unwrap(),
});

const PatchMaterialListRequestSchema = z.object({
  paperworkId: z.number(),
  create: MaterialSchema.omit({ id: true }).array(),
  update: MaterialSchema.array(),
  delete: MaterialSchema.pick({ id: true }).array(),
});

export type Material = z.infer<typeof MaterialSchema>;
export type PTImportedMaterial = z.infer<typeof PTImportedMaterialSchema>;
export type PatchMaterialListRequest = z.infer<typeof PatchMaterialListRequestSchema>;
