import { useMemo } from 'react';
import { EnabledCountry } from '../../entities/Country';
import { DraftOperationEnum } from '../../entities/Drafts';
import { PAPERWORK_TYPE } from '../../entities/Paperwork';
import { Stakeholder, STAKEHOLDER_ROLE } from '../../entities/Stakeholder';
import { useReadCountryListQuery } from '../../services/countries';
import { useStakeholders } from '../useStakeholders';
import { usePaperwork } from './usePaperwork';

export function useStakeholderCountryRestrictions(role: Stakeholder['role']) {
  const { paperwork, matchPaperworkType } = usePaperwork();

  const { data: enabledCountries } = useReadCountryListQuery();
  const { stakeholders, operations } = useStakeholders();

  const countriesByCode = useMemo(() => {
    const countriesByCode = new Map<string, EnabledCountry>();
    for (const country of enabledCountries ?? []) {
      countriesByCode.set(country.code, country);
    }
    return countriesByCode;
  }, [enabledCountries]);

  return useMemo(() => {
    // esiste una distinzione generale tra paesi UE, NATO ed extra NATO, ci sono 2 eccezioni specifiche (intangibile e intermediazione)

    if (role !== STAKEHOLDER_ROLE.SHIP_TO) {
      return undefined;
    }

    const paperworkCountry = countriesByCode.get(paperwork?.country ?? '');
    if (!paperwork || !paperworkCountry) {
      return undefined;
    }

    const pageStakeholders = Object.values(stakeholders).filter((stakeholder) => {
      if (operations[stakeholder.id] === DraftOperationEnum.Values.Delete) {
        return false;
      }
      if (stakeholder.role !== role) {
        return false;
      }
      return true;
    });

    //   Per i paesi UE (AGT3, AGT5, LGT): il paese dichiarato in fase di creazione della pratica non è vincolante, si possono aggiungere altri paesi ship to europei
    if (
      matchPaperworkType(
        PAPERWORK_TYPE.Agt3FornitoriProduttori,
        PAPERWORK_TYPE.Agt5,
        PAPERWORK_TYPE.Agt5Spares,
        PAPERWORK_TYPE.Lgt
      )
    ) {
      for (const stakeholder of pageStakeholders) {
        const country = countriesByCode.get(stakeholder.country);
        if (!country) {
          return 'Attenzione, è stato inserito un paese destinatario la cui classificazione EU / NATO / extra-NATO non è disponibile.';
        }
        if (country && country.category !== 'EU') {
          return 'Attenzione, stai inserendo un paese destinatario non autorizzato rispetto allo ship to dichiarato in fase di creazione della pratica';
        }
      }
    }

    //   Per i paesi non UE (individuale/tangibile, LGP, Framework) il paese destinatario è vincolante, non si possono aggiungere altri paesi destinatari diversi dallo ship to
    if (matchPaperworkType(PAPERWORK_TYPE.IndividualeTangibile, PAPERWORK_TYPE.Lgp, PAPERWORK_TYPE.LicenzaFramework)) {
      if (paperworkCountry && paperworkCountry?.category !== 'EU') {
        for (const stakeholder of pageStakeholders) {
          const country = countriesByCode.get(stakeholder.country);
          if (!country) {
            return 'Attenzione, è stato inserito un paese destinatario la cui classificazione EU / NATO / extra-NATO non è disponibile.';
          }
          if (country.code !== paperworkCountry.code) {
            return 'Attenzione, stai inserendo un paese destinatario non autorizzato rispetto allo ship to dichiarato in fase di creazione della pratica';
          }
        }
      }
    }

    if (matchPaperworkType(PAPERWORK_TYPE.Intangibile)) {
      //   Intangibile paese destinatario = UE e NATO esiste il vincolo per l’aggiunta di ship to extra NATO (si possono quindi aggiungere altri destinatari UE e NATO)
      if (paperworkCountry.category === 'EU' || paperworkCountry.category === 'NATO') {
        for (const stakeholder of pageStakeholders) {
          const country = countriesByCode.get(stakeholder.country);
          if (!country) {
            return 'Attenzione, è stato inserito un paese destinatario la cui classificazione EU / NATO / extra-NATO non è disponibile.';
          }
          if (country.category !== 'EU' && country.category !== 'NATO') {
            return 'Attenzione, stai inserendo un paese destinatario non autorizzato rispetto allo ship to dichiarato in fase di creazione della pratica';
          }
        }
      }
      //   Intangibile paese destinatario = extra NATO è vincolante, non si possono aggiungere altri paesi destinatari
      if (paperworkCountry.category === 'NON-NATO') {
        for (const stakeholder of pageStakeholders) {
          const country = countriesByCode.get(stakeholder.country);
          if (!country) {
            return 'Attenzione, è stato inserito un paese destinatario la cui classificazione EU / NATO / extra-NATO non è disponibile.';
          }
          if (country.code !== paperworkCountry.code) {
            return 'Attenzione, stai inserendo un paese destinatario non autorizzato rispetto allo ship to dichiarato in fase di creazione della pratica';
          }
        }
      }
    }

    //   Intermediazione: a prescindere dal paese destinatario inserito il vincolo esiste sempre, non si possono aggiungere paesi ship to diversi
    if (matchPaperworkType(PAPERWORK_TYPE.IntermediazioneFramework, PAPERWORK_TYPE.IntermediazioneOrdini)) {
      for (const stakeholder of pageStakeholders) {
        const country = countriesByCode.get(stakeholder.country);
        if (!country) {
          return 'Attenzione, è stato inserito un paese destinatario la cui classificazione EU / NATO / extra-NATO non è disponibile.';
        }
        if (country.code !== paperworkCountry.code) {
          return 'Attenzione, stai inserendo un paese destinatario non autorizzato rispetto allo ship to dichiarato in fase di creazione della pratica';
        }
      }
    }

    return undefined;
  }, [countriesByCode, matchPaperworkType, operations, paperwork, role, stakeholders]);
}
