import { useCallback, useMemo } from 'react';
import { useReadCountriesQuery } from '@top-solution/microtecnica-utils';
import { useReadCountryListQuery } from '../services/countries';

export function useCountryChecks() {
  const { data: commonDataCountries } = useReadCountriesQuery();
  const { data: ilcmCountries } = useReadCountryListQuery();

  const isCountryNato = useCallback(
    (countryCode: string | undefined) => {
      if (!countryCode) {
        return false;
      }
      const ilcmCountry = ilcmCountries?.find((country) => country.code === countryCode);
      if (ilcmCountry) {
        return ilcmCountry.category === 'NATO';
      }
      const commonDataCountry = commonDataCountries?.byISO[countryCode];

      if (commonDataCountry) {
        return commonDataCountry.isNato;
      }

      return false;
    },
    [commonDataCountries?.byISO, ilcmCountries]
  );

  const isCountryExtraNato = useCallback(
    (countryCode: string | undefined) => {
      if (!countryCode) {
        return false;
      }
      const ilcmCountry = ilcmCountries?.find((country) => country.code === countryCode);
      if (ilcmCountry) {
        return ilcmCountry.category === 'NON-NATO';
      }
      const commonDataCountry = commonDataCountries?.byISO[countryCode];

      if (commonDataCountry) {
        return !commonDataCountry.isEU && !commonDataCountry.isNato;
      }

      return false;
    },
    [commonDataCountries?.byISO, ilcmCountries]
  );

  return useMemo(
    () => ({
      isCountryNato,
      isCountryExtraNato,
    }),
    [isCountryExtraNato, isCountryNato]
  );
}
