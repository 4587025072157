import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { DataGridPremiumProps } from '@mui/x-data-grid-premium';
import { DataGridPremium } from '@mui/x-data-grid-premium/DataGridPremium';
import { DataGridWrapper, useCountryGridColDef } from '@top-solution/microtecnica-mui';
import { PaperworkAttachment } from '../../../../entities/Attachment';
import { Stakeholder } from '../../../../entities/Stakeholder';
import { DataEntryPages, PaperworkPhases } from '../../../../hooks/usePaperwork/paperworkNavigation';
import { usePaperworkNavigation } from '../../../../hooks/usePaperwork/usePaperworkNavigation';
import { formatDateTime } from '../../../../utils/dates';
import { DownloadButton } from './DownloadButton';

interface AttachmentsByStakeholderProps {
  attachmentsByStakeholder: Record<number, Record<number, PaperworkAttachment[]>>;
  stakeholders: Record<string, Stakeholder>;
}

function DetailPanelContent({ bySubsection }: { bySubsection: Record<number, PaperworkAttachment[]> }) {
  const lists = [];

  const { routes } = usePaperworkNavigation();

  const route = routes[PaperworkPhases.DataEntry].pages[DataEntryPages.Attachments];

  for (const [subsectionId, attachments] of Object.entries(bySubsection)) {
    let attachmentSection;
    let attachmentSubsection;

    for (const [_, section] of Object.entries(route.sections)) {
      if (section.subsections) {
        for (const [_, subsection] of Object.entries(section.subsections)) {
          if (subsection.context?.id === Number(subsectionId)) {
            attachmentSection = section;
            attachmentSubsection = subsection;
          }
        }
      } else if (section.context?.id === Number(subsectionId)) {
        attachmentSection = section;
      }
    }

    if (attachmentSection) {
      lists.push(
        <Paper key={subsectionId} sx={{ overflow: 'hidden' }}>
          <List
            component="div"
            dense
            subheader={
              attachmentSubsection ? (
                <ListSubheader>{`${attachmentSection.title} — ${attachmentSubsection.title}`}</ListSubheader>
              ) : null
            }
            sx={{ width: '100%', breakInside: 'avoid', display: 'block' }}
          >
            {attachments.map((attachment) => (
              <ListItem key={attachment.attachment.id} secondaryAction={<DownloadButton attachment={attachment} />}>
                <ListItemText
                  primary={attachment.attachment.name}
                  secondary={`Caricato il ${formatDateTime(new Date(attachment.attachment.uploadDate))}`}
                  primaryTypographyProps={{
                    sx: {
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    },
                  }}
                />
              </ListItem>
            ))}
          </List>
        </Paper>
      );
    }
  }

  return (
    <Stack direction="column" gap={1} padding={1} maxWidth="lg">
      {lists}
    </Stack>
  );
}

function AttachmentsByStakeholderComponent(props: AttachmentsByStakeholderProps) {
  const { attachmentsByStakeholder, stakeholders } = props;

  const countryColDef = useCountryGridColDef();

  const rows = Object.keys(attachmentsByStakeholder).map((key) => ({
    id: key,
    bySubsection: attachmentsByStakeholder[Number(key)],
    stakeholder: Object.values(stakeholders).find((stakeholder) => stakeholder.id === key),
  }));

  const getDetailPanelContent = React.useCallback<NonNullable<DataGridPremiumProps['getDetailPanelContent']>>(
    ({ row }) => <DetailPanelContent bySubsection={row.bySubsection} />,
    []
  );

  return (
    <DataGridWrapper offsetHeight={267}>
      <DataGridPremium
        rows={rows}
        columns={[
          {
            field: 'name',
            headerName: 'Nome',
            flex: 1,
            valueGetter: (value, row) => row.stakeholder?.name,
          },
          {
            field: 'address',
            headerName: 'Indirizzo',
            flex: 2,
            valueGetter: (value, row) => row.stakeholder?.address,
          },
          {
            ...countryColDef,
            minWidth: 120,
            valueGetter: (value, row) => row.stakeholder?.country,
          },
        ]}
        hideFooter
        getDetailPanelHeight={() => 'auto'}
        getDetailPanelContent={getDetailPanelContent}
        disableRowGrouping
        disableAggregation
        sx={{ border: 0 }}
      />
    </DataGridWrapper>
  );
}

export const AttachmentsByStakeholder = React.memo(AttachmentsByStakeholderComponent);
