import { useCallback, useMemo } from 'react';
import {
  Paperwork,
  PAPERWORK_FIELD_DATATYPE,
  PAPERWORK_MOVEMENT,
  PAPERWORK_TYPE,
  PaperworkFieldValue,
  PaperworkRoute,
} from '../../entities/Paperwork';
import { STAKEHOLDER_ROLE } from '../../entities/Stakeholder';
import { useCountryChecks } from '../useCountryChecks';
import {
  DataEntryPages,
  AttachmentsSections,
  OtherDocumentsSubsections,
  PaperworkPhases,
  PaperworksNavigationStructure,
  PreparationSigningPages,
  OrderSubsections,
} from './paperworkNavigation';

export function usePaperworkRouteExceptions() {
  const { isCountryNato, isCountryExtraNato } = useCountryChecks();

  const applyPaperworkRouteExceptions = useCallback(
    (
      routes: PaperworksNavigationStructure<PaperworkRoute>,
      paperwork: Paperwork,
      paperworkValues: PaperworkFieldValue[]
    ) => {
      const paperworkType = paperwork.paperworkTemplate.paperworkType.name;
      const paperworkMovement = paperwork.paperworkTemplate.movementType?.name;

      const shipToStakeholder = paperwork.stakeholderList?.find(
        (stakeholder) => stakeholder.role === STAKEHOLDER_ROLE.SHIP_TO
      );

      const endUserStakeholder =
        paperwork.stakeholderList?.find((stakeholder) => stakeholder.role === STAKEHOLDER_ROLE.END_USERS) ??
        paperwork.stakeholderList?.find((stakeholder) => stakeholder.role === STAKEHOLDER_ROLE.SHIP_TO);

      const shipToCountry = shipToStakeholder?.country;
      const endUserCountry = endUserStakeholder?.country;

      const otherDocumentsSubsections =
        routes[PaperworkPhases.DataEntry].pages[DataEntryPages.Attachments].sections[AttachmentsSections.OtherDocuments]
          .subsections ?? {};

      const attachmentSections = routes[PaperworkPhases.DataEntry].pages[DataEntryPages.Attachments].sections ?? {};
      const orderSubsections =
        routes[PaperworkPhases.DataEntry].pages[DataEntryPages.Attachments].sections[AttachmentsSections.Order]
          .subsections ?? {};

      const endUserMatchesValue = paperworkValues?.find(
        (value) => value.fieldDatatype === PAPERWORK_FIELD_DATATYPE.STAKEHOLDER_END_USER_IS_MT_OR_MATCHES_SHIP_TO
      );

      const endUserIsMilitaryBase =
        paperworkValues?.find(
          (value) =>
            endUserStakeholder &&
            value.stakeholderId === endUserStakeholder.id &&
            value.fieldDatatype === PAPERWORK_FIELD_DATATYPE.STAKEHOLDER_MILITARY_BASE
        )?.value === 'true';

      function disableAllAttachments() {
        for (const [_, section] of Object.entries(attachmentSections)) {
          section.enabled = false;

          for (const [_, subsection] of Object.entries(section.subsections ?? {})) {
            subsection.enabled = false;
          }
        }
      }

      // #### Matrice 8 ############################################################
      if (paperworkType === PAPERWORK_TYPE.IndividualeTangibile || paperworkType === PAPERWORK_TYPE.LicenzaFramework) {
        // Let's ignore completely the template from database and override it with absurd front-end logic
        disableAllAttachments();

        const isProgramEftr = paperwork.programList?.length === 0 && paperwork.programList[0] === 'EFTR';

        if (isProgramEftr) {
          //  se il programma è EFA (paese utilizzatore finale IT - DE - ES - UK - AT) non sono necessari documenti, il tool
          //    non proporrà nessun documento da caricare nella richiesta
          routes[PaperworkPhases.DataEntry].pages[DataEntryPages.Attachments].active = false;
        } else {
          if (paperworkType === PAPERWORK_TYPE.IndividualeTangibile) {
            // L. 185 - Licenza individuale - Esportazione definitiva

            if (paperworkMovement === PAPERWORK_MOVEMENT.ImportazioneDefinitiva) {
              // nessun documento per il momento	il tool deve segnalare all'utente di verificare con GT eventuali documenti da presentare
            }

            if (paperworkMovement === PAPERWORK_MOVEMENT.EsportazioneDefinitiva) {
              if (
                isCountryNato(shipToCountry) &&
                (isCountryNato(endUserCountry) || isCountryExtraNato(endUserCountry))
              ) {
                // Paese destinatario NATO / End User Nato o Extra Nato
                // Ordine - è obbligatorio che ce ne sia almeno uno
                // Accettazione d'ordine - obbligatorio
                // EUS - obbligatorio per end user Nato
                // IIC - obbligatorio per end user Nato
                // EUC - solo per Eud User Extra Nato

                attachmentSections[AttachmentsSections.Order].enabled = true;
                orderSubsections[OrderSubsections.Order].enabled = true;
                orderSubsections[OrderSubsections.OrderApproval].enabled = true;

                if (isCountryNato(endUserStakeholder?.country)) {
                  attachmentSections[AttachmentsSections.Eus].enabled = true;
                  attachmentSections[AttachmentsSections.OtherDocuments].enabled = true;
                  otherDocumentsSubsections[OtherDocumentsSubsections.Iic].enabled = true;
                }
                if (isCountryExtraNato(endUserStakeholder?.country)) {
                  attachmentSections[AttachmentsSections.Euc].enabled = true;
                }
              }

              if (isCountryExtraNato(shipToCountry) && endUserIsMilitaryBase) {
                // Paese destinatario Extra NATO - End User = Forza Armata
                // Ordine - è obbligatorio che ce ne sia almeno uno
                // Accettazione d'ordine - obbligatorio
                // EUC - obbligatorio
                // ~IIC~ - alternativi - l'utente deve obbligatoriamente caricarne almeno uno dei 2 // TODO:
                // ~Certificato di ditta abilitata~- l'utente deve obbligatoriamente caricarne almeno uno dei 2

                attachmentSections[AttachmentsSections.Order].enabled = true;
                orderSubsections[OrderSubsections.Order].enabled = true;
                orderSubsections[OrderSubsections.OrderApproval].enabled = true;
                attachmentSections[AttachmentsSections.OtherDocuments].enabled = true;
                attachmentSections[AttachmentsSections.Euc].enabled = true;
                // 👇 these were crossed out on the Matrice 8 specs
                // otherDocumentsSubsections[OtherDocumentsSubsections.Iic].enabled = true;
                // otherDocumentsSubsections[OtherDocumentsSubsections.QualifiedCompanyCertificate].enabled = true;
              }

              if (isCountryExtraNato(shipToCountry) && !endUserIsMilitaryBase) {
                // Paese destinatario Extra NATO / End User azienda (non Forza Armata) Extra Nato
                // Ordine - è obbligatorio che ce ne sia almeno uno
                // Accettazione d'ordine - obbligatorio
                // EUC - facoltativi, almeno uno deve essere caricato
                // EUS - facoltativi, almeno uno deve essere caricato
                // IIC - alternativi solo nel caso si applichi l'EUS ( l'utente deve obbligatoriamente caricarne almeno uno dei 3 )
                // Certificato di ditta abilitata - alternativi solo nel caso si applichi l'EUS ( l'utente deve obbligatoriamente caricarne almeno uno dei 3 )
                // Copia di una licenza valida di esportazione - alternativi solo nel caso si applichi l'EUS ( l'utente deve obbligatoriamente caricarne almeno uno dei 3 )

                attachmentSections[AttachmentsSections.Order].enabled = true;
                attachmentSections[AttachmentsSections.Euc].enabled = true;
                attachmentSections[AttachmentsSections.Eus].enabled = true;
                attachmentSections[AttachmentsSections.OtherDocuments].enabled = true;
                otherDocumentsSubsections[OtherDocumentsSubsections.Iic].enabled = true;
                otherDocumentsSubsections[OtherDocumentsSubsections.QualifiedCompanyCertificate].enabled = true;
                otherDocumentsSubsections[OtherDocumentsSubsections.CopyOfAValidExportLicence].enabled = true;
              }
            }

            if (paperworkMovement === PAPERWORK_MOVEMENT.TemporaneaImportazioneESuccessivaRiesportazione) {
              // Paese destinatario ed end user NATO / Extra Nato
              // Ordine - è obbligatorio che ce ne sia almeno uno
              // Accettazione d'ordine - obbligatorio
              // ~EUS~ - obbligatorio
              if (
                (isCountryNato(shipToCountry) || isCountryExtraNato(shipToCountry)) &&
                (isCountryNato(endUserCountry) || isCountryExtraNato(endUserCountry))
              ) {
                attachmentSections[AttachmentsSections.Order].enabled = true;
                orderSubsections[OrderSubsections.Order].enabled = true;
                orderSubsections[OrderSubsections.OrderApproval].enabled = true;
                // 👇 this was crossed out on the Matrice 8 specs
                // attachmentSections[AttachmentsSections.Eus].enabled = true;
              }
            }

            if (paperworkMovement === PAPERWORK_MOVEMENT.TemporaneaEsportazioneESuccessivaReimportazione) {
              // Paese destinatario ed end user NATO / Extra Nato
              // Ordine - è obbligatorio che ce ne sia almeno uno
              // Accettazione d'ordine - obbligatorio
              // EUS - obbligatorio
              // IIC - alternativi - l'utente deve obbligatoriamente caricarne almeno uno dei 3 // TODO:
              // Certificato di ditta abilitata - l'utente deve obbligatoriamente caricarne almeno uno dei 3
              // Copia di una licenza valida di esportazione - l'utente deve obbligatoriamente caricarne almeno uno dei 3
              if (
                (isCountryNato(shipToCountry) || isCountryExtraNato(shipToCountry)) &&
                (isCountryNato(endUserCountry) || isCountryExtraNato(endUserCountry))
              ) {
                attachmentSections[AttachmentsSections.Order].enabled = true;
                orderSubsections[OrderSubsections.Order].enabled = true;
                orderSubsections[OrderSubsections.OrderApproval].enabled = true;
                attachmentSections[AttachmentsSections.Eus].enabled = true;
                attachmentSections[AttachmentsSections.OtherDocuments].enabled = true;
                otherDocumentsSubsections[OtherDocumentsSubsections.Iic].enabled = true;
                otherDocumentsSubsections[OtherDocumentsSubsections.QualifiedCompanyCertificate].enabled = true;
                otherDocumentsSubsections[OtherDocumentsSubsections.CopyOfAValidExportLicence].enabled = true;
              }
            }
          }

          if (paperworkType === PAPERWORK_TYPE.LicenzaFramework) {
            //L. 185 - Licenza Framework
            // Framework - è obbligatorio che ce ne sia almeno uno
            attachmentSections[AttachmentsSections.Framework].enabled = true;
          }
        }

        // #### Matrice 8 ############################################################
      }

      if (paperwork.paperworkTemplate.paperworkType.name === 'L. 185/90 - Intangibile') {
        // #### Matrice 16 ###########################################################

        if (isCountryExtraNato(shipToCountry)) {
          otherDocumentsSubsections[OtherDocumentsSubsections.QualifiedCompanyCertificate].enabled = false;
        }

        // #### Matrice 16 ###########################################################
      }

      // #### Other Matrice-unrelated stuff ##########################################

      // Do not ask for End User Statements if the end user is Microtecnica itself
      if (endUserMatchesValue && endUserMatchesValue.value === 'microtecnica') {
        if (paperworkValues) {
          routes[PaperworkPhases.DataEntry].pages[DataEntryPages.Attachments].sections[
            AttachmentsSections.Eus
          ].enabled = false;
        }
      }
      // #### Other Matrice-unrelated stuff ##########################################

      // #### FIXME: DEBUG #########################################################
      routes[PaperworkPhases.PreparationSigning].enabled = true;
      routes[PaperworkPhases.PreparationSigning].active = true;
      // routes[PaperworkPhases.PreparationSigning].pages[PreparationSigningPages.Preparation].enabled = true;
      // routes[PaperworkPhases.PreparationSigning].pages[PreparationSigningPages.Preparation].active = true;
      routes[PaperworkPhases.PreparationSigning].pages[PreparationSigningPages.Signing].enabled = true;
      routes[PaperworkPhases.PreparationSigning].pages[PreparationSigningPages.Signing].active = true;
      // #### FIXME: DEBUG #########################################################
    },
    [isCountryExtraNato, isCountryNato]
  );

  return useMemo(
    () => ({
      applyPaperworkRouteExceptions,
    }),
    [applyPaperworkRouteExceptions]
  );
}
