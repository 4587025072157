import React, { useEffect, useId, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { AvailableCountryAutocomplete } from '../../../../components/Form/AvailableCountryAutocomplete/AvailableCountryAutocomplete';
import { Stakeholder, StakeholderSchema } from '../../../../entities/Stakeholder';
import { useStakeholders } from '../../../../hooks/useStakeholders';

interface EditStakeholderDialogFormProps {
  formId: string;
  stakeholder: Stakeholder;
  onSubmit: (data: Stakeholder) => Promise<void>;
  onReset: () => void;
}

function EditStakeholderDialogFormComponent(props: EditStakeholderDialogFormProps) {
  const { formId, stakeholder, onReset } = props;
  const [existingStakeholderWarning, setExistingStakeholderWarning] = useState(false);

  const { stakeholders } = useStakeholders();

  const EditStakeholderDialogFormSchema = StakeholderSchema.omit({
    id: true,
    imported: true,
    importReason: true,
  }).extend({
    importReason: props.stakeholder.imported ? z.undefined() : StakeholderSchema.shape.importReason.unwrap(),
  });
  type EditStakeholderDialogForm = z.infer<typeof EditStakeholderDialogFormSchema>;

  const { control, handleSubmit, formState } = useForm<EditStakeholderDialogForm>({
    defaultValues: stakeholder,
    resolver: zodResolver(EditStakeholderDialogFormSchema),
  });

  function onSubmit(values: EditStakeholderDialogForm) {
    const stakeholder = {
      ...props.stakeholder,
      ...values,
    };

    if (
      Object.values(stakeholders).find(
        (s) => s.name === stakeholder.name && s.country === stakeholder.country && s.address === stakeholder.address
      )
    ) {
      setExistingStakeholderWarning(true);
      return;
    }

    props.onSubmit(stakeholder);
  }

  useEffect(() => {
    if (formState.isValidating) {
      setExistingStakeholderWarning(false);
    }
  }, [formState.isValidating]);

  return (
    <Box component="form" id={formId} onSubmit={handleSubmit(onSubmit)} onReset={onReset}>
      <Grid container spacing={1} rowSpacing={2}>
        <Grid item xs={12} paddingTop={2} paddingBottom={1}>
          {existingStakeholderWarning ? (
            <Alert severity="warning">{'Questo stakeholder è già presente in tabella'}</Alert>
          ) : (
            <Alert severity="info">{'Si ricorda che la descrizione deve coincidere con i dati SAP.'}</Alert>
          )}
        </Grid>
        <Grid item xs={7} paddingTop={2}>
          <Controller
            control={control}
            name="name"
            render={({ field: { ...field }, fieldState: { error, invalid } }) => (
              <TextField
                {...field}
                label="Ragione sociale"
                type="text"
                fullWidth
                helperText={error?.message}
                error={invalid}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={5} paddingTop={2}>
          <Controller
            control={control}
            name="country"
            render={({ field: { ...field }, fieldState: { error, invalid } }) => (
              <AvailableCountryAutocomplete
                {...field}
                label="Paese"
                value={field.value}
                onChange={(e, value) => field.onChange(value)}
                helperText={error?.message}
                error={invalid}
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} paddingTop={2}>
          <Controller
            control={control}
            name="address"
            render={({ field: { ...field }, fieldState: { error, invalid } }) => (
              <TextField
                {...field}
                label="Indirizzo"
                type="text"
                fullWidth
                helperText={error?.message}
                error={invalid}
                {...field}
              />
            )}
          />
        </Grid>
        {!stakeholder.imported ? (
          <Grid item xs={12} paddingTop={2}>
            <Controller
              control={control}
              name="importReason"
              render={({ field: { ...field }, fieldState: { error, invalid } }) => (
                <TextField
                  {...field}
                  label="Giustificazione inserimento manuale"
                  type="text"
                  fullWidth
                  helperText={error?.message}
                  error={invalid}
                  disabled={stakeholder.imported}
                  multiline
                  rows={3}
                  {...field}
                />
              )}
            />
          </Grid>
        ) : null}
      </Grid>
    </Box>
  );
}

interface EditStakeholderDialogProps {
  stakeholder: Stakeholder;
  open: boolean;
  onClose: () => void;
}

function EditStakeholderDialogComponent(props: EditStakeholderDialogProps) {
  const { stakeholder, open, onClose } = props;
  const titleId = useId();
  const formId = useId();

  const { updateStakeholder } = useStakeholders();

  const handleClose = () => {
    onClose();
  };

  const onSubmit = async (stakeholder: Stakeholder) => {
    updateStakeholder(props.stakeholder.id, stakeholder);

    onClose();
  };

  return (
    <div>
      <React.Fragment>
        <Dialog open={open} aria-labelledby={titleId} fullWidth maxWidth="md">
          <DialogTitle id={titleId}>{`Modifica della parte coinvolta "${stakeholder.name}"`}</DialogTitle>
          <DialogContent>
            {stakeholder && (
              <EditStakeholderDialogFormComponent
                formId={formId}
                stakeholder={stakeholder}
                onSubmit={onSubmit}
                onReset={handleClose}
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button type="reset" form={formId} color="inherit">
              {'Annulla'}
            </Button>
            <Button type="submit" form={formId}>
              {'Modifica'}
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    </div>
  );
}

export const EditStakeholderDialog = React.memo(EditStakeholderDialogComponent);
