import React, { forwardRef } from 'react';
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import { CompactListItem } from '../../pages/Paperwork/AdvancedFilters/CompactListItem';

const renderTags: AutocompleteProps<string, true, true, true, 'div'>['renderTags'] = (
  value: readonly string[],
  getTagProps
) =>
  value.map((option, index) => (
    // eslint-disable-next-line react/jsx-key
    <Chip size="small" color="secondary" label={option} {...getTagProps({ index })} />
  ));

function pastedStringList(e: React.ClipboardEvent<HTMLDivElement>) {
  e.preventDefault();
  return e.clipboardData
    .getData('text')
    .split('\n')
    .map((pn) => pn.trim())
    .filter((pn) => pn.length > 0);
}

interface StakeholderNameAutocompleteProps {
  value: string[];
  onChange: (value: string[]) => void;
  placeholder?: string;
}

function StakeholderNameAutocompleteComponent(props: StakeholderNameAutocompleteProps): JSX.Element {
  const { value, onChange } = props;

  return (
    <Autocomplete
      multiple
      autoComplete
      freeSolo
      value={value}
      onChange={(e, value) => onChange(value)}
      options={[]}
      filterOptions={(x) => x}
      filterSelectedOptions
      renderInput={(params) => (
        <TextField
          {...params}
          label="Parti coinvolte"
          onPaste={(e) => {
            onChange?.([...value, ...pastedStringList(e)]);
          }}
          placeholder={props.placeholder}
          InputLabelProps={{ shrink: props.placeholder ? true : undefined }}
        />
      )}
      renderTags={renderTags}
      renderOption={(props, option) => <CompactListItem key={option} listItemProps={props} primary={option} />}
    />
  );
}

export const StakeholderNameAutocomplete = forwardRef(
  StakeholderNameAutocompleteComponent
) as typeof StakeholderNameAutocompleteComponent;
