import {
  AccountTieOutlineIcon,
  EmailFastOutlineIcon,
  FileCogOutlineIcon,
  FileDocumentOutlineIcon,
  FormatListCheckboxIcon,
  HandshakeOutlineIcon,
  ScrewFlatTopIcon,
} from '../../components/Icons';
import { PAPERWORK_STATUS, PAPERWORK_TYPE, PaperworkRouteConfiguration } from '../../entities/Paperwork';
import {
  PaperworksNavigationStructure,
  PaperworkPhases,
  DataEntryPages,
  MaterialsSections,
  StakeholdersSections,
  BillToSubsections,
  EndUsersSubsections,
  AttachmentsSections,
  PreparationSigningPages,
  PreparationSection,
  ModuloASubsections,
  IssuingDeductionPages,
  OtherDocumentsSubsections,
  OrderSubsections,
  SigningSection,
} from './paperworkNavigation';

/**
 * Routing configuration -> here goes anything about the configuration that can not be inferred
 * by the routing structure itself
 */
export const paperworkRoutesConfiguration: PaperworksNavigationStructure<PaperworkRouteConfiguration> = {
  [PaperworkPhases.Configuration]: {
    title: () => 'Configurazione',
    icon: <FileCogOutlineIcon />,
  },
  [PaperworkPhases.DataEntry]: {
    title: (paperwork) =>
      paperwork.status.id === PAPERWORK_STATUS.TAKEN_CHARGE ? 'Validazione dati' : 'Inserimento dati',
    icon: <ScrewFlatTopIcon />,
    pages: {
      [DataEntryPages.Materials]: {
        title: () => `Anagrafica materiali`,
        icon: <ScrewFlatTopIcon />,
        sections: {
          [MaterialsSections.BillingYears]: {
            title: () => 'Anni di fatturazione',
          },
          [MaterialsSections.SelectPartNumbers]: {
            title: () => 'Selezione P/N',
          },
          [MaterialsSections.SelectPartNumbersWithoutEquipment]: {
            title: () => 'Selezione P/N',
          },
          [MaterialsSections.SelectPartNumbersWithoutPn]: {
            title: () => 'Selezione Materiali',
          },
          [MaterialsSections.MaterialsData]: {
            title: () => 'Inserimento dati materiali',
          },
          [MaterialsSections.PaperworkTransaction]: {
            title: () => 'Transazione',
          },
          [MaterialsSections.TotalQuantity]: {
            title: () => 'Quantità Totale',
          },
          [MaterialsSections.MaterialKeepHyphen]: {
            title: () => 'Mantenere il tratto dei P/N nelle richieste di licenza',
          },
          [MaterialsSections.AdditionalEconomicConditions]: {
            title: () => 'Condizioni Economiche aggiuntive',
          },
        },
      },
      [DataEntryPages.Stakeholders]: {
        title: () => `Anagrafica parti coinvolte`,
        icon: <AccountTieOutlineIcon />,
        sections: {
          [StakeholdersSections.ShipTo]: {
            title: () => 'Ship to',
            order: 0,
          },
          [StakeholdersSections.OrderFrom]: {
            title: () => "Ente che emette l'ordine",
            order: 1,
          },
          [StakeholdersSections.Buyer]: {
            title: () => 'Acquirente',
            order: 1,
          },
          [StakeholdersSections.BillTo]: {
            title: (paperwork) =>
              paperwork.paperworkTemplate.paperworkType.name === PAPERWORK_TYPE.IntermediazioneFramework ||
              paperwork.paperworkTemplate.paperworkType.name === PAPERWORK_TYPE.IntermediazioneOrdini
                ? 'Beneficiario pagamento (Fornitore)'
                : 'Intestatario/beneficiario pagamento',
            order: 2,
            subsections: {
              [BillToSubsections.SkipStakeholdersInputPanel]: {
                title: () => '',
              },
            },
          },
          [StakeholdersSections.EndUser]: {
            title: () => 'End User',
            order: 3,
            subsections: {
              [EndUsersSubsections.SkipStakeholdersInputPanel]: {
                title: () => '',
              },
            },
          },
        },
      },
      [DataEntryPages.Attachments]: {
        title: () => 'Documenti',
        icon: <FileDocumentOutlineIcon />,
        sections: {
          [AttachmentsSections.Order]: {
            title: () => 'Ordini',
            subsections: {
              [OrderSubsections.Order]: { title: () => 'Ordine' },
              [OrderSubsections.OrderApproval]: { title: () => "Accettazione dell'ordine" },
            },
          },
          [AttachmentsSections.Euc]: {
            title: () => 'EUC',
          },
          [AttachmentsSections.Eus]: {
            title: () => 'EUS',
          },
          [AttachmentsSections.Framework]: {
            title: () => 'Framework',
          },
          [AttachmentsSections.Nda]: {
            title: () => 'NDA',
          },
          [AttachmentsSections.VisuraCamerale]: {
            title: () => 'Visura camerale',
          },
          [AttachmentsSections.OtherDocuments]: {
            title: () => 'Altri documenti',
            subsections: {
              [OtherDocumentsSubsections.LoiVendor]: { title: () => 'LOI del fornitore' },
              [OtherDocumentsSubsections.LoiCustomer]: { title: () => 'LOI del cliente' },
              [OtherDocumentsSubsections.OrderVendor]: { title: () => 'Ordine Fornitore' },
              [OtherDocumentsSubsections.OrderCustomer]: { title: () => 'Ordine Cliente' },
              [OtherDocumentsSubsections.VendorLicence]: { title: () => 'Licenza del fornitore' },
              [OtherDocumentsSubsections.QualifiedCompanyCertificate]: {
                title: () => 'Certificato di ditta abilitata',
              },
              [OtherDocumentsSubsections.CopyOfAValidExportLicence]: {
                title: () => 'Copia di una licenza valida di esportazione',
              },
              [OtherDocumentsSubsections.Iic]: {
                title: () => 'IIC',
              },
              [OtherDocumentsSubsections.OrderDetails]: {
                title: () => 'Eventuale file excel con dettaglio ordini',
              },
            },
          },
        },
      },
      [DataEntryPages.ContractualTerms]: {
        title: () => 'Termini contrattuali',
        icon: <HandshakeOutlineIcon />,
      },
    },
  },
  [PaperworkPhases.PreparationSigning]: {
    title: () => 'Preparazione e invio',
    pages: {
      [PreparationSigningPages.Preparation]: {
        title: () => 'Preparazione pratica',
        icon: <EmailFastOutlineIcon />,
        sections: {
          [PreparationSection.ModuloA]: {
            title: () => 'Modulo A',
            subsections: {
              [ModuloASubsections.Importatore]: {
                title: () => 'Importatore',
              },
              [ModuloASubsections.Dogana]: {
                title: () => 'Dogana',
              },
              [ModuloASubsections.AnagraficaPartiCoinvolte]: {
                title: () => 'Anagrafica parti coinvolte',
              },
              [ModuloASubsections.AnagraficaMateriali]: {
                title: () => 'Anagrafica materiali',
              },
              [ModuloASubsections.TipologiaDiAttività]: {
                title: () => 'Tipologia di attività',
              },
              [ModuloASubsections.OperazioneAiFiniDoganali]: {
                title: () => 'Operazione ai fini doganali',
              },
              [ModuloASubsections.EccnMateriale]: {
                title: () => 'Eccn materiale',
              },
              [ModuloASubsections.TerminiContrattuali]: {
                title: () => 'Termini contrattuali',
              },
              [ModuloASubsections.ClausolaCircaValore]: {
                title: () => 'Clausola circa valore',
              },
              [ModuloASubsections.ContoGaranzia]: {
                title: () => 'Conto garanzia',
              },
              [ModuloASubsections.TrattativeContrattuali]: {
                title: () => 'Trattative contrattuali',
              },
              [ModuloASubsections.TipologiaDellImpegnoContrattualeSottoscrittoEValoreComplessivo]: {
                title: () => 'Tipologia dell impegno contrattuale sottoscritto e valore complessivo',
              },
              [ModuloASubsections.CondizioniLimitazioniClausoleTemporanee]: {
                title: () => 'Condizioni limitazioni clausole temporanee',
              },
            },
          },
          [PreparationSection.ModuloE]: {
            title: () => 'Modulo E',
          },
          [PreparationSection.ModuloF]: {
            title: () => 'Modulo F',
          },
        },
      },
      [PreparationSigningPages.Signing]: {
        icon: <EmailFastOutlineIcon />,
        title: () => 'Firma e invio',
        sections: {
          [SigningSection.Attachments]: {
            title: () => 'Allegati email',
          },
        },
      },
    },
  },
  [PaperworkPhases.IssuingDeduction]: {
    title: () => 'Rilascio e scarico autorizzazione',
    icon: <FormatListCheckboxIcon />,
    pages: {
      [IssuingDeductionPages.Issuing]: {
        title: () => 'Rilascio autorizzazione',
      },
      [IssuingDeductionPages.Deduction]: {
        title: () => 'Scarico',
      },
    },
  },
};
