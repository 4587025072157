import { darken, lighten, styled } from '@mui/material/styles';
import { DataGridPremium } from '@mui/x-data-grid-premium';

const getBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.85);

const getHoverBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.8);

const getSelectedBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.7);

const getSelectedHoverBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.4) : lighten(color, 0.6);

export const IncompleteRowsDatagrid = styled(DataGridPremium)(({ theme }) => ({
  '& .incomplete': {
    backgroundColor: getBackgroundColor(theme.palette.warning.light, theme.palette.mode),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(theme.palette.warning.light, theme.palette.mode),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(theme.palette.warning.light, theme.palette.mode),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(theme.palette.warning.light, theme.palette.mode),
      },
    },
  },
  '& .duplicate': {
    opacity: 0.5,
  },
}));
