import React, { useId, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

import { ProgramAutocomplete } from '../../../../components/Autocompletes/ProgramAutocomplete';
import { EquipmentDraft, EquipmentDraftSchema } from '../../../../entities/Equipment';

interface EditEquipmentDialogFormProps {
  formId: string;
  equipment?: EquipmentDraft;
  otherEquipmentNames: string[];
  onSubmit: (data: EquipmentDraft) => Promise<void>;
  onReset: () => void;
}

function EditEquipmentDialogFormComponent(props: EditEquipmentDialogFormProps) {
  const { formId, equipment, onReset, otherEquipmentNames } = props;
  const EditEquipmentDialogFormSchema = EquipmentDraftSchema.extend({
    equipment: z
      .string()
      .min(1)
      .superRefine((val, ctx) => {
        if (otherEquipmentNames.includes(val.trim())) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Questo equipaggiamento è già presente in lista',
          });
        }
      }),
  });
  type EditEquipmentDialogForm = z.infer<typeof EditEquipmentDialogFormSchema>;

  const { control, handleSubmit } = useForm<EditEquipmentDialogForm>({
    defaultValues: equipment,
    resolver: zodResolver(EditEquipmentDialogFormSchema),
  });

  function onSubmit(values: EditEquipmentDialogForm) {
    const equipment = {
      ...props.equipment,
      ...values,
    };

    props.onSubmit(equipment);
  }

  return (
    <Box component="form" id={formId} onSubmit={handleSubmit(onSubmit)} onReset={onReset}>
      <Grid container spacing={1} rowSpacing={2} sx={{ paddingBlockStart: 1 }}>
        <Grid item xs={12} paddingTop={2}>
          <Controller
            control={control}
            name="equipment"
            render={({ field: { ...field }, fieldState: { error, invalid } }) => (
              <TextField
                {...field}
                label="Equipaggiamento"
                type="text"
                fullWidth
                helperText={error?.message}
                error={invalid}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} paddingTop={2}>
          <Controller
            control={control}
            name="equipmentDescription"
            render={({ field: { ...field }, fieldState: { error, invalid } }) => (
              <TextField
                {...field}
                label="Descrizione"
                type="text"
                fullWidth
                multiline
                rows={3}
                helperText={error?.message}
                error={invalid}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} paddingTop={2}>
          <Controller
            control={control}
            name="equipmentProgram"
            render={({ field: { ...field }, fieldState: { error, invalid } }) => (
              <ProgramAutocomplete
                {...field}
                value={field.value}
                onChange={(e, value) => {
                  field.onChange(value);
                }}
                onInputChange={(event, newInputValue) => {
                  field.onChange(newInputValue);
                }}
                label="Programma"
                fullWidth
                freeSolo={true}
                helperText={error?.message}
                error={invalid}
              />
            )}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

interface EditEquipmentDialogProps {
  equipment?: EquipmentDraft;
  open: boolean;
  onClose: () => void;
  onSubmit: (equipment: EquipmentDraft) => void;
  equipments: EquipmentDraft[];
}

function EditEquipmentDialogComponent(props: EditEquipmentDialogProps) {
  const { equipment, open, onClose, equipments } = props;
  const titleId = useId();
  const formId = useId();

  const otherEquipmentNames = useMemo(() => {
    return equipments
      .filter((filteringEquipment) => filteringEquipment.equipment !== equipment?.equipment)
      .map((equipment) => equipment.equipment);
  }, [equipment?.equipment, equipments]);

  const handleClose = () => {
    onClose();
  };

  const onSubmit = async (equipment: EquipmentDraft) => {
    props.onSubmit(equipment);
    onClose();
  };

  return (
    <div>
      <React.Fragment>
        <Dialog open={open} aria-labelledby={titleId} fullWidth maxWidth="md">
          <DialogTitle id={titleId}>{`${equipment ? 'Modifica' : 'Aggiungi'} equipaggiamento`}</DialogTitle>
          <DialogContent>
            {open && (
              <EditEquipmentDialogFormComponent
                formId={formId}
                equipment={equipment}
                onSubmit={onSubmit}
                onReset={handleClose}
                otherEquipmentNames={otherEquipmentNames}
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button type="reset" form={formId} color="inherit">
              {'Annulla'}
            </Button>
            <Button type="submit" form={formId}>
              {equipment ? 'Modifica' : 'Aggiungi'}
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    </div>
  );
}

export const EditEquipmentDialog = React.memo(EditEquipmentDialogComponent);
