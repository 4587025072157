import React from 'react';
// import { serniListUpdateRoutesConfiguration } from '../../../../hooks/useSerniListUpdate/serniListUpdateRouteConfiguration';
import { Layout } from '../../../components/Layout';
import { NavigationPanelProps } from '../../../components/NavigationPanel/NavigationPanel';
import { PageTitle } from '../../../components/PageTitle';
import { SerniListUpdatePhases, DataEntryPages } from '../../../hooks/useSerniListUpdate/serniListUpdateNavigation';
import { useSerniListUpdateNavigation } from '../../../hooks/useSerniListUpdate/useSerniListUpdateNavigation';
import { SerniListUpdateInfoPanel } from '../SerniListUpdateInfoPanel';

export type DataEntryStepProps = NavigationPanelProps & {
  onBack?: () => void;
  onForward?: () => void;
  forceEnableForwardButton?: boolean;
  stepper: React.ReactNode;
  footer?: React.ReactNode;
};

function DataEntryStepLayoutComponent(props: DataEntryStepProps) {
  const { children, footer, stepper } = props;
  const { routes } = useSerniListUpdateNavigation();
  const pageTitle = routes[SerniListUpdatePhases.DataEntry].pages[DataEntryPages.DataEntry].title;

  return (
    <Layout
      title={pageTitle}
      header={<PageTitle pageTitle={pageTitle} rightItems={<SerniListUpdateInfoPanel />} />}
      footer={footer}
    >
      {stepper}
      {children}
    </Layout>
  );
}

export const DataEntryStepLayout = React.memo(DataEntryStepLayoutComponent);
