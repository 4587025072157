import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem, { ListItemProps } from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { DataGridPremium, DataGridPremiumProps, GRID_DETAIL_PANEL_TOGGLE_COL_DEF } from '@mui/x-data-grid-premium';
import { ArrowLeftIcon } from '@mui/x-date-pickers-pro';
import { PaperworkStatusChip } from '../../../components/DataGrid/usePaperworkStatusColumn';
import { Layout } from '../../../components/Layout';
import { PaperworkContext, PaperworkRoute } from '../../../entities/Paperwork';
import {
  PaperworkPhases,
  DataEntryPages,
  MaterialsSections,
  PreparationSigningPages,
  IssuingDeductionPages,
  StakeholdersSections,
  AttachmentsSections,
  SigningSection,
} from '../../../hooks/usePaperwork/paperworkNavigation';
import { usePaperwork } from '../../../hooks/usePaperwork/usePaperwork';
import { usePaperworkNavigation } from '../../../hooks/usePaperwork/usePaperworkNavigation';
import { useReadPaperworkTemplatesListQuery } from '../../../services/paperwork';

function DetailPanelContent({ row: rowProp }: { row: PaperworkContext }) {
  return (
    <Stack sx={{ p: 1, height: '100%', boxSizing: 'border-box' }} direction="column">
      <Stack direction="column" spacing={1} sx={{ height: 1 }}>
        <DataGridPremium
          density="compact"
          columns={[
            { field: 'id', width: 60 },
            { field: 'datatype', flex: 1 },
            { field: 'label', flex: 1 },
            { field: 'linkedTo', flex: 1 },
          ]}
          rows={rowProp.fieldList ?? []}
          sx={{ flex: 1 }}
          hideFooter
        />
      </Stack>
    </Stack>
  );
}

function TreeListItem(props: ListItemProps & { name: string; depth: number; route: PaperworkRoute }) {
  return (
    <ListItem
      secondaryAction={
        <Stack direction="row" gap={1}>
          <Chip
            size="small"
            color={props.route?.active ? 'primary' : undefined}
            label={props.route?.active ? 'Active' : 'Not active'}
            sx={{ width: 80 }}
          />
          <Chip
            size="small"
            color={props.route?.enabled ? 'primary' : undefined}
            label={props.route?.enabled ? 'Enabled' : 'Disabled'}
            sx={{ width: 80 }}
          />
        </Stack>
      }
      sx={{ paddingLeft: 1.5 + props.depth * 3 }}
    >
      <ListItemText primary={<Typography fontFamily={'monospace'}>{props.name}</Typography>} />
    </ListItem>
  );
}

function PaperworkDebugComponent() {
  const { paperwork, contexts } = usePaperwork();
  const { routes, detailsPath } = usePaperworkNavigation();
  const { data: templates } = useReadPaperworkTemplatesListQuery();

  const [templateOMaticId, setTemplateOMaticId] = useState('');
  const templateOMaticTemplate = useMemo(
    () => (templates ?? []).find((template) => template.id === Number(templateOMaticId)),
    [templateOMaticId, templates]
  );

  const getDetailPanelContent = React.useCallback<NonNullable<DataGridPremiumProps['getDetailPanelContent']>>(
    ({ row }) => (row.fieldList?.length > 0 ? <DetailPanelContent row={row} /> : null),
    []
  );

  const getDetailPanelHeight = React.useCallback(() => 400, []);

  return (
    <Layout title="Paperwork debug" maxWidth={false}>
      <Stack direction="row" padding={1}>
        <Button component={Link} to={detailsPath} startIcon={<ArrowLeftIcon />}>
          Dashboard pratica
        </Button>
      </Stack>
      <Grid container spacing={1} padding={1}>
        <Grid item xs={12} xl={6}>
          <Paper component={Stack} direction="column" gap={1} padding={1} sx={{ flex: 1 }}>
            <Typography variant="h6">Template</Typography>
            <Stack direction="row" gap={1} alignItems="center">
              <Typography fontWeight={500}>Template ID:</Typography>
              {`${paperwork?.paperworkTemplate.id}`}
            </Stack>
            <Stack direction="row" gap={1} alignItems="center">
              <Typography fontWeight={500}>Tipologia di vendita:</Typography>
              {`${paperwork?.paperworkTemplate.saleType.id} — ${paperwork?.paperworkTemplate.saleType.name}`}
            </Stack>
            <Stack direction="row" gap={1} alignItems="center">
              <Typography fontWeight={500}>Tipologia di autorizzazione:</Typography>
              {`${paperwork?.paperworkTemplate.paperworkType.id} — ${paperwork?.paperworkTemplate.paperworkType.name}`}
            </Stack>
            <Stack direction="row" gap={1} alignItems="center">
              <Typography fontWeight={500}>Movimentazione:</Typography>
              {`${paperwork?.paperworkTemplate.movementType?.id} — ${paperwork?.paperworkTemplate.movementType?.name}`}
            </Stack>
            <Stack direction="row" gap={1} alignItems="center">
              <Typography fontWeight={500}>Country Category:</Typography>
              {paperwork?.paperworkTemplate.countryCategory}
            </Stack>
          </Paper>
        </Grid>
        <Grid item xs={12} xl={6}>
          <Paper component={Stack} direction="column" gap={1} padding={1} sx={{ flex: 1 }}>
            <Typography variant="h6">Template-o-matic</Typography>
            <TextField
              value={templateOMaticId}
              onChange={(e) => setTemplateOMaticId(e.target.value)}
              label="Template ID"
            />
            {templateOMaticTemplate ? (
              <>
                <Stack direction="row" gap={1} alignItems="center">
                  <Typography fontWeight={500}>CountryCategory:</Typography>
                  {templateOMaticTemplate.countryCategory}
                </Stack>
                <Stack direction="row" gap={1} alignItems="center">
                  <Typography fontWeight={500}>Tipologia di vendita:</Typography>
                  {`${templateOMaticTemplate?.saleType.id} — ${templateOMaticTemplate?.saleType.name}`}
                </Stack>
                <Stack direction="row" gap={1} alignItems="center">
                  <Typography fontWeight={500}>Tipologia di autorizzazione:</Typography>
                  {`${templateOMaticTemplate?.paperworkType.id} — ${templateOMaticTemplate?.paperworkType.name}`}
                </Stack>
                <Stack direction="row" gap={1} alignItems="center">
                  <Typography fontWeight={500}>Movimentazione:</Typography>
                  {`${templateOMaticTemplate?.movementType?.id} — ${templateOMaticTemplate?.movementType?.name}`}
                </Stack>
              </>
            ) : (
              'No template found!'
            )}
          </Paper>
        </Grid>
        <Grid item xs={12} xl={6}>
          <Paper component={Stack} direction="column" gap={1} padding={1} sx={{ flex: 1 }}>
            <Typography variant="h6">Paperwork</Typography>
            <pre>{JSON.stringify({ paperwork }, null, 2)}</pre>
          </Paper>
        </Grid>
        <Grid item xs={12} xl={6}>
          <Paper sx={{ flex: 1, padding: 1 }}>
            <Typography variant="h6">Enabled Navigation</Typography>
            <List dense sx={{ '& .MuiListItem-root:nth-child(even)': { backgroundColor: 'grey.100' } }}>
              <TreeListItem
                depth={0}
                name={PaperworkPhases.Configuration}
                route={routes[PaperworkPhases.Configuration]}
              />
              <TreeListItem depth={0} name={PaperworkPhases.DataEntry} route={routes[PaperworkPhases.DataEntry]} />
              <TreeListItem
                depth={1}
                name={DataEntryPages.Materials}
                route={routes[PaperworkPhases.DataEntry].pages[DataEntryPages.Materials]}
              />
              <TreeListItem
                depth={2}
                name={MaterialsSections.BillingYears}
                route={
                  routes[PaperworkPhases.DataEntry].pages[DataEntryPages.Materials].sections[
                    MaterialsSections.BillingYears
                  ]
                }
              />
              <TreeListItem
                depth={2}
                name={MaterialsSections.SelectPartNumbers}
                route={
                  routes[PaperworkPhases.DataEntry].pages[DataEntryPages.Materials].sections[
                    MaterialsSections.SelectPartNumbers
                  ]
                }
              />
              <TreeListItem
                depth={2}
                name={MaterialsSections.SelectPartNumbersWithoutEquipment}
                route={
                  routes[PaperworkPhases.DataEntry].pages[DataEntryPages.Materials].sections[
                    MaterialsSections.SelectPartNumbersWithoutEquipment
                  ]
                }
              />
              <TreeListItem
                depth={2}
                name={MaterialsSections.SelectPartNumbersWithoutPn}
                route={
                  routes[PaperworkPhases.DataEntry].pages[DataEntryPages.Materials].sections[
                    MaterialsSections.SelectPartNumbersWithoutPn
                  ]
                }
              />
              <TreeListItem
                depth={2}
                name={MaterialsSections.MaterialsData}
                route={
                  routes[PaperworkPhases.DataEntry].pages[DataEntryPages.Materials].sections[
                    MaterialsSections.MaterialsData
                  ]
                }
              />
              <TreeListItem
                depth={2}
                name={MaterialsSections.AdditionalEconomicConditions}
                route={
                  routes[PaperworkPhases.DataEntry].pages[DataEntryPages.Materials].sections[
                    MaterialsSections.AdditionalEconomicConditions
                  ]
                }
              />
              <TreeListItem
                depth={1}
                name={DataEntryPages.Stakeholders}
                route={routes[PaperworkPhases.DataEntry].pages[DataEntryPages.Stakeholders]}
              />
              <TreeListItem
                depth={2}
                name={StakeholdersSections.ShipTo}
                route={
                  routes[PaperworkPhases.DataEntry].pages[DataEntryPages.Stakeholders].sections[
                    StakeholdersSections.ShipTo
                  ]
                }
              />
              <TreeListItem
                depth={2}
                name={StakeholdersSections.OrderFrom}
                route={
                  routes[PaperworkPhases.DataEntry].pages[DataEntryPages.Stakeholders].sections[
                    StakeholdersSections.OrderFrom
                  ]
                }
              />
              <TreeListItem
                depth={2}
                name={StakeholdersSections.BillTo}
                route={
                  routes[PaperworkPhases.DataEntry].pages[DataEntryPages.Stakeholders].sections[
                    StakeholdersSections.BillTo
                  ]
                }
              />
              <TreeListItem
                depth={2}
                name={StakeholdersSections.EndUser}
                route={
                  routes[PaperworkPhases.DataEntry].pages[DataEntryPages.Stakeholders].sections[
                    StakeholdersSections.EndUser
                  ]
                }
              />
              <TreeListItem
                depth={1}
                name={DataEntryPages.Attachments}
                route={routes[PaperworkPhases.DataEntry].pages[DataEntryPages.Attachments]}
              />
              <TreeListItem
                depth={2}
                name={AttachmentsSections.Order}
                route={
                  routes[PaperworkPhases.DataEntry].pages[DataEntryPages.Attachments].sections[
                    AttachmentsSections.Order
                  ]
                }
              />
              <TreeListItem
                depth={2}
                name={AttachmentsSections.Eus}
                route={
                  routes[PaperworkPhases.DataEntry].pages[DataEntryPages.Attachments].sections[AttachmentsSections.Eus]
                }
              />
              <TreeListItem
                depth={2}
                name={AttachmentsSections.Euc}
                route={
                  routes[PaperworkPhases.DataEntry].pages[DataEntryPages.Attachments].sections[AttachmentsSections.Euc]
                }
              />
              <TreeListItem
                depth={2}
                name={AttachmentsSections.VisuraCamerale}
                route={
                  routes[PaperworkPhases.DataEntry].pages[DataEntryPages.Attachments].sections[
                    AttachmentsSections.VisuraCamerale
                  ]
                }
              />
              <TreeListItem
                depth={2}
                name={AttachmentsSections.OtherDocuments}
                route={
                  routes[PaperworkPhases.DataEntry].pages[DataEntryPages.Attachments].sections[
                    AttachmentsSections.OtherDocuments
                  ]
                }
              />
              <TreeListItem
                depth={1}
                name={DataEntryPages.ContractualTerms}
                route={routes[PaperworkPhases.DataEntry].pages[DataEntryPages.ContractualTerms]}
              />
              <TreeListItem
                depth={0}
                name={PaperworkPhases.PreparationSigning}
                route={routes[PaperworkPhases.PreparationSigning]}
              />
              <TreeListItem
                depth={1}
                name={PreparationSigningPages.Preparation}
                route={routes[PaperworkPhases.PreparationSigning].pages[PreparationSigningPages.Preparation]}
              />
              <TreeListItem
                depth={1}
                name={PreparationSigningPages.Signing}
                route={routes[PaperworkPhases.PreparationSigning].pages[PreparationSigningPages.Signing]}
              />
              <TreeListItem
                depth={2}
                name={SigningSection.Attachments}
                route={
                  routes[PaperworkPhases.PreparationSigning].pages[PreparationSigningPages.Signing].sections[
                    SigningSection.Attachments
                  ]
                }
              />
              <TreeListItem
                depth={0}
                name={PaperworkPhases.IssuingDeduction}
                route={routes[PaperworkPhases.IssuingDeduction]}
              />
              <TreeListItem
                depth={1}
                name={IssuingDeductionPages.Issuing}
                route={routes[PaperworkPhases.IssuingDeduction].pages[IssuingDeductionPages.Issuing]}
              />
              <TreeListItem
                depth={1}
                name={IssuingDeductionPages.Deduction}
                route={routes[PaperworkPhases.IssuingDeduction].pages[IssuingDeductionPages.Deduction]}
              />
            </List>
          </Paper>
        </Grid>
        <Grid item xs={12} xl={12}>
          <Paper component={Stack} direction="column" gap={1} padding={1} sx={{ flex: 1 }}>
            <Typography variant="h6">Contexts & fields</Typography>
            <DataGridPremium
              rows={contexts}
              columns={[
                { field: 'id', width: 90 },
                {
                  field: 'availableFromStatus',
                  renderCell: ({ row }) => <PaperworkStatusChip status={row.availableFromStatus.id} />,
                  width: 150,
                },
                { field: 'phase', flex: 1 },
                { field: 'page', flex: 1 },
                { field: 'section', flex: 1 },
                { field: 'subsection', flex: 1 },
                {
                  ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
                  headerName: 'fieldList',
                },
              ]}
              disableRowSelectionOnClick
              density="compact"
              hideFooter
              getDetailPanelHeight={getDetailPanelHeight}
              getDetailPanelContent={getDetailPanelContent}
            />
          </Paper>
        </Grid>
      </Grid>
    </Layout>
  );
}

export const PaperworkDebug = React.memo(PaperworkDebugComponent);
