import { useMemo } from 'react';
import { PAPERWORK_TYPE } from '../../entities/Paperwork';
import { Stakeholder, STAKEHOLDER_ROLE } from '../../entities/Stakeholder';
import { usePaperwork } from './usePaperwork';

export function useStakeholderAlerts(role: Stakeholder['role']) {
  const { matchPaperworkType } = usePaperwork();

  return useMemo(() => {
    const alerts: string[] = [];

    if (role === STAKEHOLDER_ROLE.SHIP_TO) {
      // Matrice 24 (L. 185/90 - Licenza intangibile)
      // alert: Attenzione! Le ragioni sociali e gli indirizzi inseriti manualmente devono coincidere con quanto presente nell'anagrafica SAP e sui documenti forniti dal cliente / fornitore (ordini, EUS etc...)
      if (matchPaperworkType(PAPERWORK_TYPE.Intangibile)) {
        alerts.push(
          "Attenzione! Le ragioni sociali e gli indirizzi inseriti manualmente devono coincidere con quanto presente nell'anagrafica SAP e sui documenti forniti dal cliente / fornitore (ordini, EUS etc...)"
        );
      }
    }

    if (role === STAKEHOLDER_ROLE.END_USERS) {
      // andrebbe aggiunto in tutti i tab End User la definizione riportata nelle matrici 4-10 e 24:
      // Per end user si intende l'azienda o la base militare che utilizzerà il materiale spedito da Microtecnica
      if (
        matchPaperworkType(
          PAPERWORK_TYPE.Lgt,
          PAPERWORK_TYPE.Agt5,
          PAPERWORK_TYPE.Agt3FornitoriProduttori,
          PAPERWORK_TYPE.Lgp,
          PAPERWORK_TYPE.NullaOsta,
          PAPERWORK_TYPE.IndividualeTangibile,
          PAPERWORK_TYPE.LicenzaFramework,
          PAPERWORK_TYPE.IntermediazioneFramework,
          PAPERWORK_TYPE.IntermediazioneOrdini,
          PAPERWORK_TYPE.DualUseIndividuale,
          PAPERWORK_TYPE.DualUseGenerale,
          PAPERWORK_TYPE.DualUseGlobaleIndividuale,
          PAPERWORK_TYPE.Intangibile
        )
      ) {
        alerts.push(
          "Per end user si intende l'azienda o la base militare che utilizzerà il materiale spedito da Microtecnica"
        );
      }

      // nella matrice 24 c'è un ulteriore avviso per gli utenti: L'end user dovrebbe coincidere con il destinatario del dato, in caso contrario contattare GT
      if (matchPaperworkType(PAPERWORK_TYPE.Intangibile)) {
        alerts.push("L'end user dovrebbe coincidere con il destinatario del dato, in caso contrario contattare GT");
      }
    }

    return alerts;
  }, [matchPaperworkType, role]);
}
