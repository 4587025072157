import React, { useMemo } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { FieldComponentProps, useFieldComponentProps } from '../fieldComponentProps';

// Subset of https://en.wikipedia.org/wiki/Incoterms, relevant to MT's business
const mtIncoterms = ['FCA', 'EXW', 'DAP', 'CIP', 'CPT', 'CFR', 'DPU'];

function ContractualTermsYieldAutocompleteComponent(props: FieldComponentProps) {
  const { field } = props;
  const { fieldValue, updateValue } = useFieldComponentProps(field);

  const value = useMemo(() => {
    if (fieldValue && fieldValue.value) return fieldValue.value.split(',');
    return [];
  }, [fieldValue]);

  return (
    <Autocomplete
      disablePortal
      options={mtIncoterms}
      renderInput={(params) => <TextField {...params} label={field.label} />}
      value={value}
      onChange={(event, value) => updateValue(value.toString() ?? undefined)}
      multiple
    />
  );
}

export const ContractualTermsYieldAutocomplete = React.memo(ContractualTermsYieldAutocompleteComponent);
