import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { DraftOperationEnum } from '../entities/Drafts';
import { Paperwork } from '../entities/Paperwork';
import { Stakeholder } from '../entities/Stakeholder';
import { RootState } from './store';

export type StakeholdersStateSlice = {
  paperworkId: Paperwork['id'];
  stakeholders: Record<string, Stakeholder>;
  operations: Record<string, DraftOperationEnum>;
  saving: boolean;
};

const initialState: StakeholdersStateSlice = {
  paperworkId: -1,
  stakeholders: {},
  operations: {},
  saving: false,
};

const stakeholdersSlice = createSlice({
  name: 'stakeholdersState',
  initialState,
  reducers: {
    createStakeholder: (
      state,
      action: PayloadAction<{
        stakeholder: Stakeholder;
      }>
    ) => {
      const stakeholder = action.payload.stakeholder;

      state.operations[stakeholder.id] = DraftOperationEnum.Values.Create;
      state.stakeholders[stakeholder.id] = stakeholder;
    },
    updateStakeholder: (
      state,
      action: PayloadAction<{
        stakeholder: Stakeholder;
      }>
    ) => {
      const stakeholder = action.payload.stakeholder;

      if (state.operations[stakeholder.id] !== DraftOperationEnum.Values.Create) {
        state.operations[stakeholder.id] = DraftOperationEnum.Values.Update;
      }

      state.stakeholders[stakeholder.id] = stakeholder;
    },
    deleteStakeholder: (state, action: PayloadAction<{ id: Stakeholder['id'] }>) => {
      state.operations[action.payload.id] = DraftOperationEnum.Values.Delete;
    },
    setSavingState: (state, action: PayloadAction<{ paperworkId: Paperwork['id']; saving: boolean }>) => {
      state.saving = action.payload.saving;
    },
    replaceStakeholders: (
      state,
      action: PayloadAction<{ paperworkId: Paperwork['id']; stakeholders: Omit<Stakeholder, 'draftId'>[] }>
    ) => {
      state.paperworkId = action.payload.paperworkId;
      state.stakeholders = {};
      state.operations = {};
      for (const stakeholder of action.payload.stakeholders) {
        state.stakeholders[stakeholder.id] = stakeholder;
      }
    },
    discardOperations: (state) => {
      state.operations = {};
    },
  },
});

export const {
  createStakeholder,
  updateStakeholder,
  deleteStakeholder,
  setSavingState,
  replaceStakeholders,
  discardOperations,
} = stakeholdersSlice.actions;

export const selectStakeholdersState = (state: RootState) => state.stakeholdersState;

export default stakeholdersSlice.reducer;
