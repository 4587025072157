import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { ConfirmDialogProps, DeleteConfirmDialog } from '@top-solution/microtecnica-mui';
import { DeleteIcon } from '../../../../components/Icons';
import { PaperworkAttachment } from '../../../../entities/Attachment';
import { useAttachments } from '../../../../hooks/usePaperworkAttachments';

type OrderDeleteDialogDialogProps = Omit<
  ConfirmDialogProps,
  'title' | 'onClose' | 'open' | 'onConfirm' | 'inProgress'
> & {
  orderNumber: string;
  attachments: PaperworkAttachment[];
};

function OrderDeleteDialogDialogComponent(props: OrderDeleteDialogDialogProps): JSX.Element {
  const { orderNumber, attachments, ...dialogProps } = props;
  const [open, setOpen] = useState(false);
  const { deleteAttachment, saveAttachments } = useAttachments();

  return (
    <>
      <Tooltip title={'Elimina ordine'}>
        <IconButton onClick={() => setOpen(true)}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
      <DeleteConfirmDialog
        {...dialogProps}
        open={open}
        onClose={async () => {
          setOpen(false);
        }}
        title={'Elimina ordine'}
        description={`L'ordine "${orderNumber}" sarà eliminato. Quest'operazione è irreversibile.`}
        confirmText="elimina"
        onConfirm={async () => {
          for (const attachment of attachments) {
            deleteAttachment(attachment.attachment.id);
          }
          saveAttachments();
          setOpen(false);
        }}
      />
    </>
  );
}

export const OrderDeleteDialog = React.memo(OrderDeleteDialogDialogComponent);
