import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { PAPERWORK_TYPE } from '../../entities/Paperwork';
import { useReadPaperworkQuery, useReadPaperworkTemplatesListQuery } from '../../services/paperwork';

export function usePaperwork(options?: { skip?: boolean } | undefined) {
  const params = useParams<{ id: string }>();

  const readPaperworkTemplatesList = useReadPaperworkTemplatesListQuery();

  const readPaperworkQuery = useReadPaperworkQuery(
    { paperworkId: Number(params.id) },
    { skip: options?.skip || params.id === undefined }
  );

  const contexts = useMemo(() => {
    if (readPaperworkTemplatesList.data && readPaperworkQuery.data) {
      const template = readPaperworkTemplatesList.data?.find((template) => {
        return template.id === readPaperworkQuery.data?.paperworkTemplate.id;
      });
      return template?.paperworkContextList ?? [];
    }
    return undefined;
  }, [readPaperworkQuery, readPaperworkTemplatesList]);

  const matchPaperworkType = useCallback(
    (...paperworkTypes: PAPERWORK_TYPE[]) => {
      if (!readPaperworkQuery.data) {
        return false;
      }
      return paperworkTypes.some(
        (paperworkType) => paperworkType === readPaperworkQuery.data?.paperworkTemplate.paperworkType.name
      );
    },
    [readPaperworkQuery.data]
  );

  return useMemo(
    () => ({
      paperwork: readPaperworkQuery.data,
      readPaperworkQuery,
      /**
       * All the contexts active for this Paperwork
       */
      contexts,
      matchPaperworkType,
    }),
    [contexts, matchPaperworkType, readPaperworkQuery]
  );
}
