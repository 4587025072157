import React, { forwardRef, useCallback } from 'react';
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { Program, useReadProgramsQuery } from '@top-solution/microtecnica-utils';

export type ProgramAutocompleteProps<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> = Omit<AutocompleteProps<Program['id'], Multiple, DisableClearable, FreeSolo>, 'options' | 'renderInput'> &
  Pick<TextFieldProps, 'label' | 'error' | 'helperText' | 'variant' | 'required' | 'sx'>;

function ProgramAutocompleteComponent<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>(
  props: ProgramAutocompleteProps<Multiple, DisableClearable, FreeSolo>,
  ref: React.Ref<HTMLInputElement>
): JSX.Element {
  const { value, label, error, helperText, variant, required, sx, ...autocompleteProps } = props;
  const { data, isFetching, error: loadingError } = useReadProgramsQuery();

  const getOptionLabel = useCallback(
    (option: string) => {
      if (data?.map && data.map[option]) {
        return `${data.map[option].name}`;
      }
      return option;
    },
    [data]
  );

  return (
    <Autocomplete
      value={value}
      options={Object.keys(data?.map ?? {})}
      getOptionLabel={getOptionLabel}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant={variant}
          error={Boolean(loadingError) || error}
          helperText={(loadingError && 'message' in loadingError && loadingError.message) || helperText || undefined}
          required={required}
          sx={sx}
          inputRef={ref}
        />
      )}
      loading={isFetching}
      {...autocompleteProps}
    />
  );
}

export const ProgramAutocomplete = forwardRef(ProgramAutocompleteComponent) as typeof ProgramAutocompleteComponent;
