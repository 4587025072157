import React, { useId, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { Material } from '../../../../../../entities/Material';
import { useNotifications } from '../../../../../../hooks/useNotifications';
import { FilterTab } from './FilterTab';
import { SearchTab } from './SearchTab';

const TITLE = 'Aggiungi materiali';

interface ImportMaterialsDialogProps {
  onImportMaterials: (material: Material[]) => void;
  withoutEquipment?: boolean;
}

function ImportMaterialsDialogComponent(props: ImportMaterialsDialogProps) {
  const { onImportMaterials, withoutEquipment } = props;
  const { pushNotification } = useNotifications();

  const [activeStep, setActiveStep] = useState(0);

  const titleId = useId();
  const [open, setOpen] = useState(false);

  const [searchResults, setSearchResults] = useState<Material[]>([]);

  return (
    <React.Fragment>
      <Button variant="contained" onClick={() => setOpen(true)}>
        {TITLE}
      </Button>
      <Dialog
        open={open}
        aria-labelledby={titleId}
        fullWidth
        maxWidth="lg"
        TransitionProps={{
          onExited: () => {
            setSearchResults([]);
            setActiveStep(0);
          },
        }}
        PaperProps={{ sx: { height: 'calc(100% - 64px)', maxHeight: 600 } }}
      >
        <DialogTitle id={titleId}>{TITLE}</DialogTitle>
        <Stepper activeStep={activeStep} sx={{ paddingX: 2 }}>
          <Step key={1}>
            <StepLabel>{'Ricerca materiali'}</StepLabel>
          </Step>
          <Step key={1}>
            <StepLabel>{'Importazione materiali'}</StepLabel>
          </Step>
        </Stepper>
        <Stack direction="column" flex={1}>
          {activeStep === 0 ? (
            <SearchTab
              onNext={(searchResults) => {
                setSearchResults(searchResults);
                setActiveStep(1);
              }}
              onCancel={() => setOpen(false)}
            />
          ) : (
            <FilterTab
              searchResults={searchResults}
              onNext={(materials) => {
                onImportMaterials(materials);
                setOpen(false);
                pushNotification(`Aggiunti ${materials.length} materiali`);
              }}
              onCancel={() => setOpen(false)}
              withoutEquipment={withoutEquipment}
            />
          )}
        </Stack>
      </Dialog>
    </React.Fragment>
  );
}

export const ImportMaterialsDialog = React.memo(ImportMaterialsDialogComponent);
