import React, { useMemo } from 'react';
import { IntegerTextField } from '@top-solution/microtecnica-mui';
import { FieldComponentProps, useFieldComponentProps } from '../fieldComponentProps';

function TotalQuantityInputComponent(props: FieldComponentProps) {
  const { field } = props;
  const { fieldValue, updateValue } = useFieldComponentProps(field);

  const value = useMemo(() => {
    if (fieldValue && fieldValue.value) return Number(fieldValue.value);
    return null;
  }, [fieldValue]);

  return (
    <IntegerTextField
      label={field.label}
      variant="outlined"
      value={value ?? ('' as unknown as number)}
      onChange={(_, value) => updateValue(value?.toString() ?? undefined)}
      required={true}
    />
  );
}

export const TotalQuantityInput = React.memo(TotalQuantityInputComponent);
