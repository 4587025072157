import React, { useMemo, useState } from 'react';
import Paper from '@mui/material/Paper';
import {
  GRID_AGGREGATION_FUNCTIONS,
  GRID_AGGREGATION_ROOT_FOOTER_ROW_ID,
  GridActionsCellItem,
  GridAggregationFunction,
  GridColDef,
  GridPinnedColumnFields,
  GridRenderCellParams,
  GridValidRowModel,
} from '@mui/x-data-grid-premium';
import { eccnColumn } from '../../../../../components/DataGrid/eccnColumn';
import { htsColumn } from '../../../../../components/DataGrid/htsColumn';
import { IncompleteRowsDatagrid } from '../../../../../components/DataGrid/IncompleteRowsDatagrid';
import { materialDescriptionColumn } from '../../../../../components/DataGrid/materialDescriptionColumn';
import { partNumberColumn } from '../../../../../components/DataGrid/partNumberColumn';
import { useProgramColumn } from '../../../../../components/DataGrid/programColumn';
import { useSerniCodeColumn } from '../../../../../components/DataGrid/serniCodeColumn';
import { EditIcon } from '../../../../../components/Icons';
import { ForwardButton, SaveButton } from '../../../../../components/NavigationPanel/NavigationButtons';
import { NavigationPanel } from '../../../../../components/NavigationPanel/NavigationPanel';
import { PaperworkDebugMenu } from '../../../../../components/PaperworkDebugMenu/PaperworkDebugMenu';
import { ReviewChip } from '../../../../../components/review/ReviewChip';
import { ReviewGridActionsCellItem } from '../../../../../components/review/ReviewGridActionsCellItem';
import { ReviewGuard } from '../../../../../components/review/ReviewGuard';
import { ReviewPopover } from '../../../../../components/review/ReviewPopover';
import { usePaperworkReviews } from '../../../../../components/review/usePaperworkReviews';
import { Material } from '../../../../../entities/Material';
import {
  PAPERWORK_FIELD_DATATYPE,
  PaperworkFieldValueDraft,
  PaperworkReview,
  getPaperworkFieldValueDraftId,
} from '../../../../../entities/Paperwork';
import { usePaperwork } from '../../../../../hooks/usePaperwork/usePaperwork';
import { ContextField, usePaperworkFields } from '../../../../../hooks/usePaperwork/usePaperworkFields';
import { useReadEquipmentListQuery } from '../../../../../services/equipmentiApi';
import { useReadPaperworkReviewsQuery } from '../../../../../services/paperworkReview';
import { MaterialsStepLayout, MaterialsStepProps } from '../MaterialsStepLayout';
import { EditMaterialFieldsDialog } from './EditMaterialFieldsDialog';
import {
  useMaterialCustomerBillingValuePerPNColumn,
  useMaterialVendorBillingValuePerPNColumn,
} from './useMaterialBillingValuePerPNColumn';
import { useMaterialCurrencyColumn } from './useMaterialCurrencyColumn';
import { useMaterialCustomUnitValueColumns } from './useMaterialCustomUnitValueColumns';
import { useMaterialFreeSaleColumn } from './useMaterialFreeSaleColumn';
import {
  useMaterialOrderDateColumn,
  useMaterialOrderNumberColumn,
  useMaterialOrderRowColumn,
} from './useMaterialOrderColumns';
import { useMaterialQuantityColumn } from './useMaterialQuantityColumn';
import { useMaterialRepairUnitValueColumn } from './useMaterialRepairUnitValueColumn';
import { useMaterialTransferModeColumn } from './useMaterialTransferModeColumn';
import { useMaterialTransferTotalValueColumn } from './useMaterialTransferTotalValueColumn';
import { useMaterialUnitValueColumn } from './useMaterialUnitValueColumn';
import { useMaterialUnityOfMeasureColumn } from './useMaterialUnityOfMeasureColumn';
import { useTechDataColumn } from './useTechDataColumn';

const pinnedColumns: GridPinnedColumnFields = { right: ['review', 'actions'] };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function createTotalAggregationFunction(field: string): GridAggregationFunction<{ [key: string]: any }, number> {
  return {
    label: 'Valore totale',
    getCellValue: ({ row }) => ({
      quantity: row[PAPERWORK_FIELD_DATATYPE.MATERIAL_QUANTITY]?.value ?? 0,
      value: row[field]?.value ?? 0,
    }),
    apply: ({ values }) => {
      return values.reduce((total, value) => {
        return total + (value?.quantity ?? 0) * (value?.value ?? 0);
      }, 0);
    },
    valueFormatter: (value) => `Totale: ${new Intl.NumberFormat(navigator.language).format(value)} `,
    columnTypes: ['number'],
  };
}

function MaterialsDataStepComponent(props: MaterialsStepProps) {
  const { leftActions, primaryActions, onForward } = props;
  const { paperwork, readPaperworkQuery } = usePaperwork();
  const [selectedRow, setSelectedRow] = useState<
    (Material & Partial<Record<PAPERWORK_FIELD_DATATYPE, PaperworkFieldValueDraft>>) | null
  >(null);
  const readPaperworkReviewsQuery = useReadPaperworkReviewsQuery({ paperworkId: paperwork?.id ?? -1 });
  const { data: equipmentData } = useReadEquipmentListQuery();

  const programColumn = useProgramColumn();
  const serniCodeColumn = useSerniCodeColumn();
  const { showReviews } = usePaperworkReviews();

  const { contextFields } = usePaperworkFields();
  const { contextFields: techDataContextFields } = usePaperworkFields({
    subsection: 'tech-data',
  });
  const { fieldValues, saveValues, patchPaperworkValuesRequest, operationsCount } = usePaperworkFields();
  const techDataColDef = useTechDataColumn();
  const quantityColDef = useMaterialQuantityColumn();
  const orderNumberColDef = useMaterialOrderNumberColumn();
  const orderDateColDef = useMaterialOrderDateColumn();
  const orderRowColDef = useMaterialOrderRowColumn();
  const unityOfMeasureColDef = useMaterialUnityOfMeasureColumn();
  const freeSaleColDef = useMaterialFreeSaleColumn();
  const unitValueColDef = useMaterialUnitValueColumn();
  const repairUnitValueColDef = useMaterialRepairUnitValueColumn();
  const customUnitValueColDef = useMaterialCustomUnitValueColumns();
  const materialCurrencyColDef = useMaterialCurrencyColumn();
  const materialTransferModeColDef = useMaterialTransferModeColumn();
  const materialTransferTotalColDef = useMaterialTransferTotalValueColumn();
  const materialVendorBillingValueColDef = useMaterialVendorBillingValuePerPNColumn();
  const materialCustomerBillingValueColDef = useMaterialCustomerBillingValuePerPNColumn();

  const contextFieldsByDataType = useMemo(() => {
    const byDataType = new Map<string, ContextField>();

    Object.values(contextFields).forEach((cf) => {
      byDataType.set(cf.datatype, cf);
    });

    return byDataType;
  }, [contextFields]);

  const withoutPn = useMemo(() => {
    // PNs are missing in case of "Intangibile" paperwork types
    // If it isn't "Intangibile", well, something must be really broken
    return !readPaperworkQuery.data?.materialList?.find((material) => material.pn !== undefined);
  }, [readPaperworkQuery.data?.materialList]);

  const dataGridColumns: GridColDef<GridValidRowModel>[] = useMemo(() => {
    const columns: GridColDef<GridValidRowModel>[] = [
      materialDescriptionColumn,
      programColumn,
      serniCodeColumn,
      eccnColumn,
      htsColumn,
    ];

    if (!withoutPn) {
      columns.unshift(partNumberColumn);
    }

    if (techDataContextFields.find((f) => f.datatype === PAPERWORK_FIELD_DATATYPE.MATERIAL_TECH_DATA)) {
      columns.push(techDataColDef);
    }
    if (contextFieldsByDataType.has(PAPERWORK_FIELD_DATATYPE.MATERIAL_QUANTITY)) {
      columns.push(quantityColDef);
    }
    if (contextFieldsByDataType.has(PAPERWORK_FIELD_DATATYPE.MATERIAL_ORDER_NUMBER)) {
      columns.push(orderNumberColDef);
    }
    if (contextFieldsByDataType.has(PAPERWORK_FIELD_DATATYPE.MATERIAL_ORDER_DATE)) {
      columns.push(orderDateColDef);
    }
    if (contextFieldsByDataType.has(PAPERWORK_FIELD_DATATYPE.MATERIAL_ORDER_ROW)) {
      columns.push(orderRowColDef);
    }
    if (contextFieldsByDataType.has(PAPERWORK_FIELD_DATATYPE.MATERIAL_UNITY_OF_MEASURE)) {
      columns.push(unityOfMeasureColDef);
    }
    if (contextFieldsByDataType.has(PAPERWORK_FIELD_DATATYPE.MATERIAL_FREE_SALE)) {
      columns.push(freeSaleColDef);
    }
    if (contextFieldsByDataType.has(PAPERWORK_FIELD_DATATYPE.MATERIAL_UNIT_VALUE)) {
      columns.push(unitValueColDef);
    }
    if (contextFieldsByDataType.has(PAPERWORK_FIELD_DATATYPE.MATERIAL_REPAIR_UNIT_VALUE)) {
      columns.push(repairUnitValueColDef);
    }
    if (contextFieldsByDataType.has(PAPERWORK_FIELD_DATATYPE.MATERIAL_CUSTOMS_UNITARY_SALE)) {
      columns.push(customUnitValueColDef);
    }
    if (contextFieldsByDataType.has(PAPERWORK_FIELD_DATATYPE.MATERIAL_TRANSFER_MODE)) {
      columns.push(materialTransferModeColDef);
    }
    if (contextFieldsByDataType.has(PAPERWORK_FIELD_DATATYPE.MATERIAL_TRANSACTION_TOTAL_VALUE)) {
      columns.push(materialTransferTotalColDef);
    }
    if (contextFieldsByDataType.has(PAPERWORK_FIELD_DATATYPE.MATERIAL_CURRENCY)) {
      columns.push(materialCurrencyColDef);
    }
    if (contextFieldsByDataType.has(PAPERWORK_FIELD_DATATYPE.MATERIAL_VENDOR_BILLING_VALUE_PER_PN)) {
      columns.push(materialVendorBillingValueColDef);
    }
    if (contextFieldsByDataType.has(PAPERWORK_FIELD_DATATYPE.MATERIAL_CUSTOMER_BILLING_VALUE_PER_PN)) {
      columns.push(materialCustomerBillingValueColDef);
    }

    if (showReviews) {
      columns.push({
        field: 'review',
        type: 'boolean',
        headerName: 'Stato revisione',
        width: 130,
        renderCell: ({ id, value }: GridRenderCellParams<GridValidRowModel, PaperworkReview>) =>
          id === GRID_AGGREGATION_ROOT_FOOTER_ROW_ID ? null : <ReviewChip review={value} />,
      });
    }

    columns.push({
      field: 'actions',
      type: 'actions' as const,
      headerName: '',
      hideable: false,
      width: 90,
      getActions: ({ id, row }) => {
        if (id === GRID_AGGREGATION_ROOT_FOOTER_ROW_ID) {
          return [];
        }
        return [
          <ReviewGuard key="review" editReview>
            <ReviewPopover
              paperworkReview={{
                materialId: row.id,
              }}
              review={row.review}
            >
              {(onCLick) => <ReviewGridActionsCellItem onClick={onCLick} highlight={!row.review} />}
            </ReviewPopover>
          </ReviewGuard>,
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Modifica"
            key="edit"
            className="textPrimary"
            onClick={() =>
              setSelectedRow(row as Material & Partial<Record<PAPERWORK_FIELD_DATATYPE, PaperworkFieldValueDraft>>)
            }
            color="inherit"
          />,
        ];
      },
    });

    return columns;
  }, [
    contextFieldsByDataType,
    customUnitValueColDef,
    freeSaleColDef,
    materialCurrencyColDef,
    materialCustomerBillingValueColDef,
    materialTransferModeColDef,
    materialTransferTotalColDef,
    materialVendorBillingValueColDef,
    orderDateColDef,
    orderNumberColDef,
    orderRowColDef,
    programColumn,
    quantityColDef,
    repairUnitValueColDef,
    serniCodeColumn,
    showReviews,
    techDataColDef,
    techDataContextFields,
    unitValueColDef,
    unityOfMeasureColDef,
    withoutPn,
  ]);

  const rows: Array<Material & Partial<Record<string, unknown>>> = useMemo(() => {
    if (!equipmentData) return [];
    return (readPaperworkQuery.data?.materialList ?? []).map((material) => {
      const review = readPaperworkReviewsQuery.data?.find(
        (review) => review.paperworkId === paperwork?.id && material.id === review.materialId
      );
      const serniCodeDescription = material.serniCode ? equipmentData.bySerniCode[material.serniCode].description : '';
      const techDataSerniCode = equipmentData.list.find(
        (eq) =>
          eq.description.replace(' - TECNOLOGIA', '') === serniCodeDescription &&
          eq.category === '22' &&
          eq.equipmentType === '1'
      )?.serniCode;

      const row: Material & Partial<Record<string, unknown>> = { ...material, review };

      for (const field of [...contextFields, ...techDataContextFields]) {
        row[field.datatype as string] =
          fieldValues[
            getPaperworkFieldValueDraftId({
              fieldDatatype: field.datatype,
              materialId: material.id,
              progIndex: 0,
            })
          ] ?? undefined;
      }

      return { ...row, serniCodeDescription, techDataSerniCode };
    });
  }, [
    readPaperworkQuery.data?.materialList,
    readPaperworkReviewsQuery.data,
    equipmentData,
    paperwork?.id,
    contextFields,
    techDataContextFields,
    fieldValues,
  ]);

  const areMaterialRowsComplete = useMemo(() => {
    for (const r of rows) {
      for (const [k, v] of Object.entries(r)) {
        if (contextFieldsByDataType.has(k)) {
          const cf = contextFieldsByDataType.get(k);
          if (cf!.isRequired && (v === undefined || v === null)) {
            return false;
          }
        }
      }
    }

    return true;
  }, [contextFieldsByDataType, rows]);

  const primaryActionsWithForward = (
    <>
      {primaryActions}
      <ForwardButton onClick={onForward} disabled={operationsCount > 0 || !areMaterialRowsComplete} />
    </>
  );

  return (
    <MaterialsStepLayout
      stepper={props.stepper}
      footer={
        <NavigationPanel
          primaryActions={primaryActionsWithForward}
          secondaryActions={
            <SaveButton
              onClick={() => {
                saveValues();
              }}
              loading={patchPaperworkValuesRequest.isLoading}
              disabled={operationsCount === 0}
            />
          }
          leftActions={
            <>
              {leftActions}
              <PaperworkDebugMenu />
            </>
          }
          operationsCount={operationsCount}
        />
      }
    >
      <Paper variant="outlined" sx={{ flex: 1, minHeight: 0 }}>
        <IncompleteRowsDatagrid
          density="standard"
          rows={rows}
          columns={dataGridColumns}
          pagination
          disableRowGrouping
          disableDensitySelector
          disableColumnSelector
          disableColumnPinning
          editMode="row"
          loading={!equipmentData}
          pinnedColumns={pinnedColumns}
          sx={{ border: 0 }}
          aggregationFunctions={{
            ...GRID_AGGREGATION_FUNCTIONS,
            ...(contextFieldsByDataType.has(PAPERWORK_FIELD_DATATYPE.MATERIAL_VENDOR_BILLING_VALUE_PER_PN)
              ? {
                  totalValue: createTotalAggregationFunction(
                    PAPERWORK_FIELD_DATATYPE.MATERIAL_VENDOR_BILLING_VALUE_PER_PN
                  ),
                }
              : {}),
            ...(contextFieldsByDataType.has(PAPERWORK_FIELD_DATATYPE.MATERIAL_CUSTOMER_BILLING_VALUE_PER_PN)
              ? {
                  totalValue: createTotalAggregationFunction(
                    PAPERWORK_FIELD_DATATYPE.MATERIAL_CUSTOMER_BILLING_VALUE_PER_PN
                  ),
                }
              : {}),
            ...(contextFieldsByDataType.has(PAPERWORK_FIELD_DATATYPE.MATERIAL_UNIT_VALUE)
              ? {
                  totalValue: createTotalAggregationFunction(PAPERWORK_FIELD_DATATYPE.MATERIAL_UNIT_VALUE),
                }
              : {}),
            ...(contextFieldsByDataType.has(PAPERWORK_FIELD_DATATYPE.MATERIAL_REPAIR_UNIT_VALUE)
              ? {
                  totalValue: createTotalAggregationFunction(PAPERWORK_FIELD_DATATYPE.MATERIAL_REPAIR_UNIT_VALUE),
                }
              : {}),
            ...(contextFieldsByDataType.has(PAPERWORK_FIELD_DATATYPE.MATERIAL_CUSTOMS_UNITARY_SALE)
              ? {
                  totalValue: createTotalAggregationFunction(PAPERWORK_FIELD_DATATYPE.MATERIAL_CUSTOMS_UNITARY_SALE),
                }
              : {}),
          }}
          initialState={{
            sorting: {
              sortModel: [{ field: partNumberColumn.field, sort: 'asc' }],
            },
            aggregation: {
              model: {
                ...(contextFieldsByDataType.has(PAPERWORK_FIELD_DATATYPE.MATERIAL_QUANTITY)
                  ? { [PAPERWORK_FIELD_DATATYPE.MATERIAL_QUANTITY]: 'sum' }
                  : {}),
                ...(contextFieldsByDataType.has(PAPERWORK_FIELD_DATATYPE.MATERIAL_VENDOR_BILLING_VALUE_PER_PN)
                  ? { [PAPERWORK_FIELD_DATATYPE.MATERIAL_VENDOR_BILLING_VALUE_PER_PN]: 'totalValue' }
                  : {}),
                ...(contextFieldsByDataType.has(PAPERWORK_FIELD_DATATYPE.MATERIAL_CUSTOMER_BILLING_VALUE_PER_PN)
                  ? { [PAPERWORK_FIELD_DATATYPE.MATERIAL_CUSTOMER_BILLING_VALUE_PER_PN]: 'totalValue' }
                  : {}),
                ...(contextFieldsByDataType.has(PAPERWORK_FIELD_DATATYPE.MATERIAL_UNIT_VALUE)
                  ? { [PAPERWORK_FIELD_DATATYPE.MATERIAL_UNIT_VALUE]: 'totalValue' }
                  : {}),
                ...(contextFieldsByDataType.has(PAPERWORK_FIELD_DATATYPE.MATERIAL_REPAIR_UNIT_VALUE)
                  ? { [PAPERWORK_FIELD_DATATYPE.MATERIAL_REPAIR_UNIT_VALUE]: 'totalValue' }
                  : {}),
                ...(contextFieldsByDataType.has(PAPERWORK_FIELD_DATATYPE.MATERIAL_CUSTOMS_UNITARY_SALE)
                  ? { [PAPERWORK_FIELD_DATATYPE.MATERIAL_CUSTOMS_UNITARY_SALE]: 'totalValue' }
                  : {}),
              },
            },
          }}
        />
        {!!selectedRow && (
          <EditMaterialFieldsDialog open={!!selectedRow} onClose={() => setSelectedRow(null)} row={selectedRow} />
        )}
      </Paper>
    </MaterialsStepLayout>
  );
}

export const MaterialsDataStep = MaterialsDataStepComponent;
