import React, { useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { BackButton, ForwardButton, SaveButton } from '../../../../components/NavigationPanel/NavigationButtons';
import { NavigationPanel } from '../../../../components/NavigationPanel/NavigationPanel';
import { ReviewButton } from '../../../../components/review/ReviewButton';
import { ReviewChip } from '../../../../components/review/ReviewChip';
import { ReviewGuard } from '../../../../components/review/ReviewGuard';
import { ReviewPopover } from '../../../../components/review/ReviewPopover';
import { usePaperworkReviews } from '../../../../components/review/usePaperworkReviews';
import { PAPERWORK_FIELD_DATATYPE, PaperworkFieldValueDraft } from '../../../../entities/Paperwork';
import { usePaperwork } from '../../../../hooks/usePaperwork/usePaperwork';
import { usePaperworkFields } from '../../../../hooks/usePaperwork/usePaperworkFields';
import { usePaperworkNavigation } from '../../../../hooks/usePaperwork/usePaperworkNavigation';
import { MaterialsStepLayout, MaterialsStepProps } from './MaterialsStepLayout';
import { PaperworkTransactionForm } from './PaperworkTransaction/PaperworkTransactionForm';

function PaperworkTransactionStepComponent(props: MaterialsStepProps) {
  const { leftActions, onBack, onForward } = props;
  const { paperwork } = usePaperwork();
  const { saveValues, patchPaperworkValuesRequest, operationsCount, fieldValues, isPaperworkDataLoading } =
    usePaperworkFields();
  const { route } = usePaperworkNavigation();
  const { reviews } = usePaperworkReviews();
  const [formIsValid, setFormIsValid] = useState(false);

  const fieldValuesMap = useMemo(() => {
    if (!isPaperworkDataLoading) {
      const resultMap = new Map<string, PaperworkFieldValueDraft>();

      for (const key in fieldValues) {
        const draft = fieldValues[key];
        if (draft.fieldDatatype) {
          resultMap.set(draft.fieldDatatype, draft);
        }
      }

      return resultMap;
    }
    return null;
  }, [fieldValues, isPaperworkDataLoading]);

  const review = useMemo(
    () => reviews.find((review) => review.paperworkId === paperwork?.id && route?.context?.id === review.contextId),
    [paperwork?.id, reviews, route?.context?.id]
  );

  const primaryActions = (
    <>
      <BackButton onClick={onBack} sx={{ mr: 1 }} />
      <ForwardButton
        onClick={onForward}
        disabled={operationsCount !== 0 || !fieldValuesMap?.get(PAPERWORK_FIELD_DATATYPE.MATERIAL_TRANSFER_MODE)?.value}
      />
    </>
  );

  return (
    <MaterialsStepLayout
      stepper={props.stepper}
      footer={
        <NavigationPanel
          primaryActions={primaryActions}
          secondaryActions={
            <SaveButton
              loading={patchPaperworkValuesRequest.isLoading}
              onClick={() => saveValues()}
              disabled={operationsCount === 0 || !formIsValid}
            />
          }
          leftActions={leftActions}
          operationsCount={operationsCount}
        />
      }
    >
      <ReviewGuard>
        <Stack direction="row">
          <Stack direction="row" justifyContent="flex-end" alignItems="baseline" gap={2} padding={1}>
            <ReviewChip review={review} size="medium" />
            <ReviewGuard editReview>
              <ReviewPopover review={review}>
                {(onClick) => <ReviewButton onClick={onClick} highlight={!review} />}
              </ReviewPopover>
            </ReviewGuard>
          </Stack>
        </Stack>
      </ReviewGuard>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        {fieldValuesMap && (
          <PaperworkTransactionForm
            fieldValuesMap={fieldValuesMap}
            key={fieldValuesMap.toString()}
            setFormIsValid={setFormIsValid}
          />
        )}
      </Box>
    </MaterialsStepLayout>
  );
}

export const PaperworkTransactionStep = React.memo(PaperworkTransactionStepComponent);
