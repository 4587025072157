import React, { useCallback, useMemo, useState } from 'react';

import IconButton from '@mui/material/IconButton';

import Typography from '@mui/material/Typography';
import { GridActionsCellItem, GridActionsColDef, GridColDef } from '@mui/x-data-grid-premium';
import { DataGrid, DataGridWrapper } from '@top-solution/microtecnica-mui';
import { AddIcon, DeleteIcon } from '../../../../../../components/Icons';
import { SerialNumber } from './SerialNumber';

import SerialNumberAddDialog from './SerialNumberAddDialog';

interface SerialNumberComponentProps {
  value: SerialNumber[];
  onChange: (v: SerialNumber[]) => void;
  required?: boolean;
  errorMessage?: string;
}

export default function SerialNumberComponent(props: SerialNumberComponentProps) {
  const { value, onChange, required, errorMessage } = props;
  const [openAdd, setOpenAdd] = useState(false);

  const handleAdd = useCallback(
    (v: SerialNumber) => {
      onChange([...value, v]);
      setOpenAdd(false);
    },
    [onChange, value]
  );

  const handleDelete = useCallback(
    (v: SerialNumber) => {
      onChange(value.filter((s) => s.serialNumber !== v.serialNumber));
    },
    [onChange, value]
  );

  const columns = useMemo<(GridColDef | GridActionsColDef)[]>(
    () => [
      {
        field: 'serialNumber',
        headerName: `S/N${required ? '*' : ''}`,
        flex: 1,
      },
      {
        field: 'orderRow',
        headerName: "Riga d'ordine",
        flex: 1,
      },
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Actions',
        hideable: false,
        width: 90,
        renderHeader: () => (
          <IconButton color="primary" onClick={() => setOpenAdd(true)}>
            <AddIcon />
          </IconButton>
        ),
        getActions: ({ row }) => {
          return [
            <GridActionsCellItem
              icon={<DeleteIcon />}
              key={'delete'}
              label="Delete"
              onClick={() => handleDelete(row)}
              color="inherit"
            />,
          ];
        },
      },
    ],
    [handleDelete, required]
  );

  return (
    <>
      <DataGridWrapper maxHeight={200}>
        <DataGrid
          getRowId={(r) => r.serialNumber}
          density="standard"
          columns={columns}
          disableRowGrouping
          disableAggregation
          rows={value}
          hideFooter
          localeText={{ noRowsLabel: 'Inserire i S/N se presenti' }}
        />
      </DataGridWrapper>
      <SerialNumberAddDialog
        open={openAdd}
        onClose={() => setOpenAdd(false)}
        onSubmit={handleAdd}
        title="Aggiungi S/N"
      />
      {errorMessage && (
        <Typography variant="caption" color={'error'}>
          {errorMessage}
        </Typography>
      )}
    </>
  );
}
