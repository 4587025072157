import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Attachment, PaperworkAttachment } from '../entities/Attachment';
import { DraftOperationEnum } from '../entities/Drafts';
import { Paperwork } from '../entities/Paperwork';
import { RootState } from './store';

export type AttachmentsStateSlice = {
  paperworkId: Paperwork['id'];
  paperworkAttachments: Record<Attachment['id'], PaperworkAttachment>;
  operations: Record<Attachment['id'], DraftOperationEnum>;
  saving: boolean;
};

const initialState: AttachmentsStateSlice = {
  paperworkId: -1,
  paperworkAttachments: {},
  operations: {},
  saving: false,
};

const attachmentSlice = createSlice({
  name: 'attachmentsState',
  initialState,
  reducers: {
    createAttachment: (
      state,
      action: PayloadAction<{
        paperworkAttachment: PaperworkAttachment;
      }>
    ) => {
      const paperworkAttachment = action.payload.paperworkAttachment;
      const attachmentId = paperworkAttachment.attachment.id;

      state.operations[attachmentId] = DraftOperationEnum.Values.Create;
      state.paperworkAttachments[attachmentId] = paperworkAttachment;
    },
    updateAttachment: (
      state,
      action: PayloadAction<{
        currentDraftId: string | undefined;
        paperworkAttachment: PaperworkAttachment;
      }>
    ) => {
      const paperworkAttachment = action.payload.paperworkAttachment;
      const attachmentId = paperworkAttachment.attachment.id;

      if (state.operations[attachmentId] !== DraftOperationEnum.Values.Create) {
        state.operations[attachmentId] = DraftOperationEnum.Values.Update;
      }

      state.paperworkAttachments[attachmentId] = paperworkAttachment;
    },
    deleteAttachment: (
      state,
      action: PayloadAction<{
        attachmentId: Attachment['id'];
      }>
    ) => {
      const attachmentId = action.payload.attachmentId;

      if (attachmentId === undefined) {
        // The attachment is not on DB: just remove it from the store
        delete state.paperworkAttachments[attachmentId];
        delete state.operations[attachmentId];
      } else {
        // The attachment had an ID and was on DB: mark it for deletion
        state.operations[attachmentId] = DraftOperationEnum.Values.Delete;
      }
    },
    setSavingState: (state, action: PayloadAction<{ paperworkId: Paperwork['id']; saving: boolean }>) => {
      state.saving = action.payload.saving;
    },
    replaceAttachments: (
      state,
      action: PayloadAction<{ paperworkId: Paperwork['id']; paperworkAttachments: PaperworkAttachment[] }>
    ) => {
      state.paperworkId = action.payload.paperworkId;
      state.paperworkAttachments = {};
      for (const paperworkAttachment of action.payload.paperworkAttachments) {
        const attachmentId = paperworkAttachment.attachment.id;
        state.paperworkAttachments[attachmentId] = paperworkAttachment;
      }
      state.operations = {};
    },
    discardOperations: (state) => {
      state.operations = {};
    },
  },
});

export const {
  createAttachment,
  updateAttachment,
  deleteAttachment,
  setSavingState,
  replaceAttachments,
  discardOperations,
} = attachmentSlice.actions;

export const selectAttachmentsState = (state: RootState) => state.attachmentsState;

export default attachmentSlice.reducer;
