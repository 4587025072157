import { createListenerMiddleware } from '@reduxjs/toolkit';

import { DraftOperationEnum } from '../../entities/Drafts';
import { PatchStakeholderListRequest } from '../../entities/Stakeholder';
import { paperworksApi } from '../../services/paperwork';
import { stakeholderAPi as stakeholderAPI } from '../../services/stakeholder';
import { StakeholdersStateSlice, replaceStakeholders, setSavingState } from '../stakeholdersStateSlice';

export const stakeholderListenerMiddleware = createListenerMiddleware();

/**
 * Replace cached stakeholders after the GET query is successful
 */
stakeholderListenerMiddleware.startListening({
  matcher: paperworksApi.endpoints.readPaperwork.matchFulfilled,
  effect: async (action, listenerApi) => {
    if (!action.payload.stakeholderList) {
      return;
    }

    listenerApi.dispatch(
      replaceStakeholders({
        paperworkId: action.meta.arg.originalArgs.paperworkId,
        stakeholders: action.payload.stakeholderList,
      })
    );
  },
});

/**
 * Set the "saving" state to false after a successful save
 */
stakeholderListenerMiddleware.startListening({
  matcher: stakeholderAPI.endpoints.patchStakeholderList.matchFulfilled,
  effect: async (action, listenerApi) => {
    listenerApi.dispatch(setSavingState({ paperworkId: action.meta.arg.originalArgs.paperworkId, saving: false }));
  },
});

/**
 * Save the stakeholders right after "saving"
 */
stakeholderListenerMiddleware.startListening({
  actionCreator: setSavingState,
  effect: async (action, listenerApi) => {
    if (action.payload.saving === true) {
      const state = listenerApi.getState() as { stakeholdersState: StakeholdersStateSlice };

      const paperworkId = action.payload.paperworkId;

      if (paperworkId !== undefined) {
        const patchPayload: PatchStakeholderListRequest = {
          paperworkId,
          create: [],
          update: [],
          delete: [],
        };
        const { stakeholders, operations } = state.stakeholdersState;
        for (const [id, operation] of Object.entries(operations)) {
          if (operation === DraftOperationEnum.Values.Create) {
            const stakeholder = stakeholders[id];
            patchPayload.create.push(stakeholder);
          } else if (operation === DraftOperationEnum.Values.Delete) {
            patchPayload.delete.push({ id });
          } else if (operation === DraftOperationEnum.Values.Update) {
            const stakeholder = stakeholders[id];
            patchPayload.create.push(stakeholder);
          }
        }

        listenerApi.dispatch(stakeholderAPI.endpoints.patchStakeholderList.initiate(patchPayload));

        // Every time occurs a change in the stakeholder list, flag the stakeholder page as not completed
        listenerApi.dispatch(
          paperworksApi.endpoints.patchPaperworkCompletedPages.initiate({
            id: paperworkId,
            completedPages: {
              stakeholders: { dataEntryCompleted: false },
            },
          })
        );
      }
    }
  },
});
