export enum PaperworkPhases {
  Configuration = 'configuration',
  DataEntry = 'data-entry',
  PreparationSigning = 'preparation-signing',
  IssuingDeduction = 'issuing-deduction',
}

export enum DataEntryPages {
  Materials = 'materials',
  Stakeholders = 'stakeholders',
  Attachments = 'attachments',
  ContractualTerms = 'contractual-terms',
  AdditionalEconomicConditions = 'additional-economic-conditions',
  PaperworkTransaction = 'paperwork-transaction',
  TotalQuantity = 'total-quantity',
  MaterialKeepHyphen = 'material-keep-hyphen',
}

export enum MaterialsSections {
  BillingYears = 'material-billing-years',
  SelectPartNumbers = 'select-part-numbers',
  SelectPartNumbersWithoutEquipment = 'select-part-numbers-without-equipment',
  SelectPartNumbersWithoutPn = 'select-part-numbers-without-pn',
  MaterialsData = 'materials-data',
  AdditionalEconomicConditions = 'additional-economic-conditions',
  PaperworkTransaction = 'paperwork-transaction',
  TotalQuantity = 'total-quantity',
  MaterialKeepHyphen = 'material-keep-hyphen',
}

export enum SelectPartNumbersSubsections {
  MaterialsData = 'materials-data',
}

export enum StakeholdersSections {
  ShipTo = 'ship-to',
  BillTo = 'bill-to',
  OrderFrom = 'order-from',
  EndUser = 'end-user',
  Buyer = 'buyer',
}

export enum BillToSubsections {
  SkipStakeholdersInputPanel = 'skip-stakeholders-input-panel',
}

export enum EndUsersSubsections {
  SkipStakeholdersInputPanel = 'skip-stakeholders-input-panel',
}

export enum AttachmentsSections {
  Order = 'order',
  Eus = 'eus-statement',
  Euc = 'euc',
  VisuraCamerale = 'visura-camerale',
  OtherDocuments = 'other-attachments',
  Framework = 'framework',
  Nda = 'nda',
}

export enum OrderSubsections {
  Order = 'order',
  OrderApproval = 'order-approval',
}

export enum OtherDocumentsSubsections {
  LoiVendor = 'loi-vendor',
  LoiCustomer = 'loi-customer',
  VendorLicence = 'vendor-licence',
  QualifiedCompanyCertificate = 'qualified-company-certificate',
  CopyOfAValidExportLicence = 'copy-of-a-valid-export-licence',
  Iic = 'iic',
  OrderDetails = 'order-details',
  OrderVendor = 'order-vendor',
  OrderCustomer = 'order-customer',
}

export enum PreparationSigningPages {
  Preparation = 'preparation',
  Signing = 'signing',
}

export enum PreparationSection {
  ModuloA = 'modulo-a',
  ModuloE = 'modulo-e',
  ModuloF = 'modulo-f',
}

export enum SigningSection {
  Attachments = 'attachments',
}

export enum ModuloASubsections {
  Importatore = 'importatore',
  Dogana = 'dogana',
  AnagraficaPartiCoinvolte = 'anagrafica-parti-coinvolte',
  AnagraficaMateriali = 'anagrafica-materiali',
  TipologiaDiAttività = 'tipologia-di-attività',
  OperazioneAiFiniDoganali = 'operazione-ai-fini-doganali',
  EccnMateriale = 'eccn-materiale',
  TerminiContrattuali = 'termini-contrattuali',
  ClausolaCircaValore = 'clausola-circa-valore',
  ContoGaranzia = 'conto-garanzia',
  TrattativeContrattuali = 'trattative-contrattuali',
  TipologiaDellImpegnoContrattualeSottoscrittoEValoreComplessivo = 'tipologia-dell-impegno-contrattuale-sottoscritto-e-valore-complessivo',
  CondizioniLimitazioniClausoleTemporanee = 'condizioni-limitazioni-clausole-temporanee',
}

export enum IssuingDeductionPages {
  Issuing = 'issuing',
  Deduction = 'deduction', // FIXME: the worst translation in this file.
}

// TODO: Add all Serni-list sections
export type PaperworksNavigationStructure<T> = {
  [PaperworkPhases.Configuration]: T;
  [PaperworkPhases.DataEntry]: T & {
    pages: {
      [DataEntryPages.Materials]: T & {
        sections: {
          [MaterialsSections.BillingYears]: T;
          [MaterialsSections.SelectPartNumbers]: T;
          [MaterialsSections.SelectPartNumbersWithoutEquipment]: T;
          [MaterialsSections.SelectPartNumbersWithoutPn]: T;
          [MaterialsSections.MaterialsData]: T;
          [MaterialsSections.AdditionalEconomicConditions]: T;
          [MaterialsSections.PaperworkTransaction]: T;
          [MaterialsSections.TotalQuantity]: T;
          [MaterialsSections.MaterialKeepHyphen]: T;
        };
      };
      [DataEntryPages.Stakeholders]: T & {
        sections: {
          [StakeholdersSections.OrderFrom]: T;
          [StakeholdersSections.ShipTo]: T;
          [StakeholdersSections.Buyer]: T;
          [StakeholdersSections.BillTo]: T & {
            subsections: {
              [BillToSubsections.SkipStakeholdersInputPanel]: T;
            };
          };
          [StakeholdersSections.EndUser]: T & {
            subsections: {
              [EndUsersSubsections.SkipStakeholdersInputPanel]: T;
            };
          };
        };
      };
      [DataEntryPages.Attachments]: T & {
        sections: {
          [AttachmentsSections.Order]: T & {
            subsections: {
              [OrderSubsections.Order]: T;
              [OrderSubsections.OrderApproval]: T;
            };
          };
          [AttachmentsSections.Euc]: T;
          [AttachmentsSections.Eus]: T;
          [AttachmentsSections.Framework]: T;
          [AttachmentsSections.Nda]: T;
          [AttachmentsSections.VisuraCamerale]: T;
          [AttachmentsSections.OtherDocuments]: T & {
            subsections: {
              [OtherDocumentsSubsections.LoiVendor]: T;
              [OtherDocumentsSubsections.LoiCustomer]: T;
              [OtherDocumentsSubsections.OrderVendor]: T;
              [OtherDocumentsSubsections.OrderCustomer]: T;
              [OtherDocumentsSubsections.VendorLicence]: T;
              [OtherDocumentsSubsections.QualifiedCompanyCertificate]: T;
              [OtherDocumentsSubsections.CopyOfAValidExportLicence]: T;
              [OtherDocumentsSubsections.Iic]: T;
              [OtherDocumentsSubsections.OrderDetails]: T;
            };
          };
        };
      };
      [DataEntryPages.ContractualTerms]: T;
    };
  };
  [PaperworkPhases.PreparationSigning]: T & {
    pages: {
      [PreparationSigningPages.Preparation]: T & {
        sections: {
          [PreparationSection.ModuloA]: T & {
            subsections: {
              [ModuloASubsections.Importatore]: T;
              [ModuloASubsections.Dogana]: T;
              [ModuloASubsections.AnagraficaPartiCoinvolte]: T;
              [ModuloASubsections.AnagraficaMateriali]: T;
              [ModuloASubsections.TipologiaDiAttività]: T;
              [ModuloASubsections.OperazioneAiFiniDoganali]: T;
              [ModuloASubsections.EccnMateriale]: T;
              [ModuloASubsections.TerminiContrattuali]: T;
              [ModuloASubsections.ClausolaCircaValore]: T;
              [ModuloASubsections.ContoGaranzia]: T;
              [ModuloASubsections.TrattativeContrattuali]: T;
              [ModuloASubsections.TipologiaDellImpegnoContrattualeSottoscrittoEValoreComplessivo]: T;
              [ModuloASubsections.CondizioniLimitazioniClausoleTemporanee]: T;
            };
          };
          [PreparationSection.ModuloE]: T;
          [PreparationSection.ModuloF]: T;
        };
      };
      [PreparationSigningPages.Signing]: T & {
        sections: {
          [SigningSection.Attachments]: T;
        };
      };
    };
  };
  [PaperworkPhases.IssuingDeduction]: T & {
    pages: {
      [IssuingDeductionPages.Issuing]: T;
      [IssuingDeductionPages.Deduction]: T;
    };
  };
};
