import { PAPERWORK_FIELD_DATATYPE } from '../../../../entities/Paperwork';
import { STAKEHOLDER_ROLE } from '../../../../entities/Stakeholder';
import { useStakeholderAlerts } from '../../../../hooks/usePaperwork/useStakeholderAlerts';
import { StakeholdersSection } from './StakeholdersSection';
import { StakeholdersSkipInputSectionSection } from './StakeholdersSkipInputSection';
import { StakeholdersStepProps } from './StakeholdersStepLayout';

export function ShipToStep(props: StakeholdersStepProps) {
  const role = STAKEHOLDER_ROLE.SHIP_TO;
  const stakeholderAlerts = useStakeholderAlerts(role);
  return <StakeholdersSection {...props} role={role} stakeholderAlerts={stakeholderAlerts} />;
}

export function OrderFromStep(props: StakeholdersStepProps) {
  const role = STAKEHOLDER_ROLE.ORDER_FROM;
  const stakeholderAlerts = useStakeholderAlerts(role);
  return <StakeholdersSection {...props} role={role} stakeholderAlerts={stakeholderAlerts} />;
}

export function BuyerStep(props: StakeholdersStepProps) {
  const role = STAKEHOLDER_ROLE.ORDER_FROM;
  const stakeholderAlerts = useStakeholderAlerts(role);
  return <StakeholdersSection {...props} role={role} stakeholderAlerts={stakeholderAlerts} />;
}

export function BillToStep(props: StakeholdersStepProps) {
  const role = STAKEHOLDER_ROLE.BILL_TO;
  const stakeholderAlerts = useStakeholderAlerts(role);
  return <StakeholdersSection {...props} role={role} stakeholderAlerts={stakeholderAlerts} />;
}

export function EndUsersStep(props: StakeholdersStepProps) {
  return (
    <StakeholdersSkipInputSectionSection
      {...props}
      role={STAKEHOLDER_ROLE.END_USERS}
      optionFieldDatatype={PAPERWORK_FIELD_DATATYPE.STAKEHOLDER_END_USER_IS_MT_OR_MATCHES_SHIP_TO}
    />
  );
}
