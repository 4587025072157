import React, { useMemo, useState } from 'react';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { GridRowSelectionModel } from '@mui/x-data-grid-premium';
import { eccnColumn } from '../../../../../../components/DataGrid/eccnColumn';
import { htsColumn } from '../../../../../../components/DataGrid/htsColumn';
import { IncompleteRowsDatagrid } from '../../../../../../components/DataGrid/IncompleteRowsDatagrid';
import { materialDescriptionColumn } from '../../../../../../components/DataGrid/materialDescriptionColumn';
import { partNumberColumn } from '../../../../../../components/DataGrid/partNumberColumn';
import { useProgramColumn } from '../../../../../../components/DataGrid/programColumn';
import { useSerniCodeColumn } from '../../../../../../components/DataGrid/serniCodeColumn';
import { Material } from '../../../../../../entities/Material';
import { useMaterials } from '../../../../../../hooks/useMaterials';
import { ImportMaterialsFooter } from './ImportMaterialsFooter';
import { ImportMaterialsNoRows } from './ImportMaterialsNoRows';

interface FilterTabProps {
  searchResults: Material[];
  onNext: (materials: Material[]) => void;
  onCancel: () => void;
  withoutEquipment?: boolean;
}

const gridInitialState = {
  pagination: {
    paginationModel: {
      pageSize: 50,
    },
  },
};

function FilterTabComponent(props: FilterTabProps) {
  const { searchResults, onCancel, onNext, withoutEquipment } = props;

  const programColumn = useProgramColumn();
  const serniCodeColumn = useSerniCodeColumn();
  const { checkDuplicateMaterial } = useMaterials();

  const duplicateMaterials = useMemo(() => {
    const duplicateMaterials = new Set<string>();

    for (const material of searchResults) {
      if (checkDuplicateMaterial(material)) {
        duplicateMaterials.add(material.id);
      }
    }

    return duplicateMaterials;
  }, [checkDuplicateMaterial, searchResults]);

  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);

  const dataGridColumns = useMemo(() => {
    let columns = [partNumberColumn, materialDescriptionColumn, programColumn];
    if (!withoutEquipment) {
      columns.push(serniCodeColumn);
    }
    columns = columns.concat([eccnColumn, htsColumn]);
    return columns;
  }, [programColumn, serniCodeColumn, withoutEquipment]);

  const searchResultsByDraftId = useMemo(() => {
    const byId: Record<string, Material> = {};

    if (searchResults) {
      for (const material of searchResults) {
        byId[material.id] = material;
      }
    }
    return byId;
  }, [searchResults]);

  const rows: Array<Material | Pick<Material, 'id' | 'pn'>> = useMemo(() => {
    return [...searchResults];
  }, [searchResults]);

  const incompleteManualRows = useMemo(() => rows.find((row) => !('program' in row)), [rows]);

  return (
    <Stack direction="column" flex="1 1 0" minHeight="100%">
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', flex: 1, gap: 1, minHeight: 0 }}>
        {incompleteManualRows ? (
          <Alert severity="warning">
            {
              'I materiali contrassegnati in giallo non sono state importati e i dati mancanti dovranno essere integrati manualmente.'
            }
          </Alert>
        ) : rows.length > 2 ? (
          <Alert severity="info">
            {'I risultati possono essere ulteriormente raffinati applicando i filtri della tabella.'}
          </Alert>
        ) : null}
        <Paper variant="outlined" sx={{ flex: 1, minHeight: 0 }}>
          <IncompleteRowsDatagrid
            density="compact"
            rows={rows}
            columns={dataGridColumns}
            disableRowGrouping
            disableAggregation
            disableDensitySelector
            disableColumnSelector
            disableColumnPinning
            hideFooter
            checkboxSelection
            isRowSelectable={(params) => !duplicateMaterials.has(params.row.id)}
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(newRowSelectionModel);
            }}
            initialState={gridInitialState}
            rowSelectionModel={rowSelectionModel}
            slots={{
              footer: ImportMaterialsFooter,
              noRowsOverlay: () => <ImportMaterialsNoRows />,
            }}
            getRowClassName={(params) =>
              duplicateMaterials.has(params.row.id) ? 'duplicate' : !params.row.imported ? 'incomplete' : ''
            }
            sx={{ border: 0 }}
          />
        </Paper>

        {duplicateMaterials.size > 0 ? (
          <Alert severity="warning">
            {'Uno o più PN sono già stati inseriti nella pratica e non è quindi possibile selezionarli nuovamente'}
          </Alert>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={onCancel}>
          {'Annulla'}
        </Button>
        <Button
          onClick={() => onNext(rowSelectionModel.map((draftId) => searchResultsByDraftId[draftId]))}
          disabled={rowSelectionModel.length === 0}
        >
          {rowSelectionModel.length > 0 ? `Aggiungi ${rowSelectionModel.length} materiali` : 'Aggiungi'}
        </Button>
      </DialogActions>
    </Stack>
  );
}

export const FilterTab = React.memo(FilterTabComponent);
