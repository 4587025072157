import { useMemo } from 'react';
import { GridColDef } from '@mui/x-data-grid-premium';
import { Material } from '../../../../../entities/Material';
import { PAPERWORK_FIELD_DATATYPE, PaperworkFieldValueDraft } from '../../../../../entities/Paperwork';
import { useReadCurrencyListQuery } from '../../../../../services/currency';
import { Currency } from '../../../../Currency/currency';

export function useMaterialTransferTotalValueColumn(): GridColDef {
  const { data: currencies } = useReadCurrencyListQuery();
  const currenciesById = useMemo(
    () => currencies?.reduce((map, curr) => map.set(curr.id, curr), new Map<number, Currency>()),
    [currencies]
  );
  return useMemo(
    () => ({
      headerName: 'Totale valore transazione',
      type: 'number',
      width: 200,
      field: PAPERWORK_FIELD_DATATYPE.MATERIAL_TRANSACTION_TOTAL_VALUE,
      valueGetter: (value, row: Material & Record<PAPERWORK_FIELD_DATATYPE, PaperworkFieldValueDraft>) => {
        const v = Number(row[PAPERWORK_FIELD_DATATYPE.MATERIAL_TRANSACTION_TOTAL_VALUE]?.value);
        return Number.isNaN(v) ? null : v;
      },
      valueFormatter: (value, row: Material & Record<PAPERWORK_FIELD_DATATYPE, PaperworkFieldValueDraft>) => {
        if (!currenciesById || !row[PAPERWORK_FIELD_DATATYPE.MATERIAL_CURRENCY]?.value) return value;
        return `${new Intl.NumberFormat(navigator.language).format(value)} ${currenciesById!.get(Number(row[PAPERWORK_FIELD_DATATYPE.MATERIAL_CURRENCY]!.value))?.symbol}`;
      },
    }),
    [currenciesById]
  );
}
