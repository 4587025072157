import React from 'react';
import Card, { CardProps } from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { PaperworkAttachment } from '../../../../entities/Attachment';
import { formatDateTime } from '../../../../utils/dates';
import { DownloadButton } from '../../../Paperwork/DataEntry/Documents/DownloadButton';
import { AttachmentDeleteDialog } from './AttachmentsDeleteDialog';

interface AttachmentCardProps extends CardProps {
  attachment: PaperworkAttachment['attachment'];
  onDeleteAttachment: (id: PaperworkAttachment['attachment']['id']) => void;
}

function AttachmentCardComponent(props: AttachmentCardProps) {
  const { attachment, onDeleteAttachment, ...cardProps } = props;

  return (
    <Card {...cardProps}>
      <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: 0.5, paddingTop: 1 }}>
        <Stack direction="row" alignItems="center" gap={1} flex={1}>
          <Typography variant="h6" flex={1} overflow="hidden" maxWidth="100%" textOverflow="ellipsis">
            {attachment.name}
          </Typography>
          <DownloadButton attachment={{ attachment }} />
          <AttachmentDeleteDialog attachment={attachment} onDeleteAttachment={onDeleteAttachment} />
        </Stack>
        <Stack direction="row" alignItems="center" gap={1} flex={1}>
          <Typography variant="body2" flex={1}>
            {`Documento caricato il ${formatDateTime(new Date(attachment.uploadDate))}`}
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
}

export const AttachmentCard = React.memo(AttachmentCardComponent);
