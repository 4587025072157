import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { DraftOperationEnum } from '../entities/Drafts';
import { Material } from '../entities/Material';
import { Paperwork } from '../entities/Paperwork';
import { RootState } from './store';

export type MaterialsStateSlice = {
  paperworkId: Paperwork['id'];
  materials: Record<string, Material>;
  operations: Record<string, DraftOperationEnum>;
  saving: boolean;
};

const initialState: MaterialsStateSlice = {
  paperworkId: -1,
  materials: {},
  operations: {},
  saving: false,
};

const materialsSlice = createSlice({
  name: 'materialsState',
  initialState,
  reducers: {
    createMaterial: (
      state,
      action: PayloadAction<{
        material: Material;
      }>
    ) => {
      const material = action.payload.material;
      state.operations[material.id] = DraftOperationEnum.Values.Create;
      state.materials[material.id] = material;
    },
    updateMaterial: (
      state,
      action: PayloadAction<{
        material: Material;
      }>
    ) => {
      const material = action.payload.material;
      if (state.operations[material.id] !== DraftOperationEnum.Values.Create) {
        state.operations[material.id] = DraftOperationEnum.Values.Update;
      }
      state.materials[material.id] = material;
    },
    setSavingState: (state, action: PayloadAction<{ paperworkId: Paperwork['id']; saving: boolean }>) => {
      state.saving = action.payload.saving;
    },
    deleteMaterial: (
      state,
      action: PayloadAction<{
        materialId: Material['id'];
      }>
    ) => {
      if (state.operations[action.payload.materialId] !== DraftOperationEnum.Values.Create) {
        delete state.materials[action.payload.materialId];
        delete state.operations[action.payload.materialId];
      }

      delete state.materials[action.payload.materialId];
      state.operations[action.payload.materialId] = DraftOperationEnum.Values.Delete;
    },
    replaceMaterials: (state, action: PayloadAction<{ paperworkId: Paperwork['id']; materials: Material[] }>) => {
      state.paperworkId = action.payload.paperworkId;
      state.materials = {};
      state.operations = {};
      for (const material of action.payload.materials) {
        state.materials[material.id] = material;
      }
    },
    discardOperations: (state) => {
      state.operations = {};
    },
  },
});

export const { createMaterial, updateMaterial, deleteMaterial, setSavingState, replaceMaterials, discardOperations } =
  materialsSlice.actions;

export const selectMaterialsState = (state: RootState) => state.materialsState;

export default materialsSlice.reducer;
