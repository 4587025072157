import React, { useId, useMemo } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { BackButton, ForwardButton, SaveButton } from '../../../../components/NavigationPanel/NavigationButtons';
import { NavigationPanel } from '../../../../components/NavigationPanel/NavigationPanel';
import { ReviewButton } from '../../../../components/review/ReviewButton';
import { ReviewChip } from '../../../../components/review/ReviewChip';
import { ReviewGuard } from '../../../../components/review/ReviewGuard';
import { ReviewPopover } from '../../../../components/review/ReviewPopover';
import { usePaperworkReviews } from '../../../../components/review/usePaperworkReviews';
import {
  getPaperworkFieldValueDraftId,
  PAPERWORK_FIELD_DATATYPE,
  addDraftIdToPaperworkFieldValue,
} from '../../../../entities/Paperwork';
import { usePaperwork } from '../../../../hooks/usePaperwork/usePaperwork';
import { usePaperworkFields } from '../../../../hooks/usePaperwork/usePaperworkFields';
import { usePaperworkNavigation } from '../../../../hooks/usePaperwork/usePaperworkNavigation';
import { MaterialsStepLayout, MaterialsStepProps } from './MaterialsStepLayout';

function MaterialKeepHyphenStepComponent(props: MaterialsStepProps) {
  const { leftActions, onBack, onForward, forceEnableForwardButton } = props;
  const { paperwork } = usePaperwork();

  const { route } = usePaperworkNavigation();
  const { reviews } = usePaperworkReviews();
  const {
    contextFields,
    fieldValues,
    originalFieldValues,
    updateValue,
    saveValues,
    operationsCount,
    patchPaperworkValuesRequest,
  } = usePaperworkFields();

  const radioId = useId();

  const review = useMemo(
    () => reviews.find((review) => review.paperworkId === paperwork?.id && route?.context?.id === review.contextId),
    [paperwork?.id, reviews, route?.context?.id]
  );

  const [contextField, optionFieldValue, optionFieldOriginalValue] = useMemo(() => {
    const draftId = getPaperworkFieldValueDraftId({
      fieldDatatype: PAPERWORK_FIELD_DATATYPE.MATERIAL_KEEP_HYPHEN,
      progIndex: 0,
    });
    return [
      contextFields.find((field) => field.datatype === PAPERWORK_FIELD_DATATYPE.MATERIAL_KEEP_HYPHEN),
      fieldValues[draftId],
      originalFieldValues[draftId],
    ];
  }, [contextFields, fieldValues, originalFieldValues]);

  const primaryActions = (
    <>
      <BackButton onClick={onBack} sx={{ mr: 1 }} />
      <ForwardButton
        onClick={onForward}
        disabled={(!forceEnableForwardButton && operationsCount > 0) || optionFieldValue?.value === undefined}
      />
    </>
  );

  function handleValueChange(e: React.ChangeEvent, value: string) {
    updateValue(
      addDraftIdToPaperworkFieldValue({
        ...(optionFieldValue ?? {
          fieldDatatype: PAPERWORK_FIELD_DATATYPE.MATERIAL_KEEP_HYPHEN,
          progIndex: 0,
        }),
        value: value,
      })
    );
  }

  function handleSubmit(e: React.FormEvent) {
    e.preventDefault();

    saveValues();
  }

  return (
    <MaterialsStepLayout
      stepper={props.stepper}
      footer={
        <NavigationPanel
          primaryActions={primaryActions}
          secondaryActions={
            <SaveButton
              onClick={() => {
                saveValues();
              }}
              loading={patchPaperworkValuesRequest.isLoading}
              disabled={operationsCount === 0}
            />
          }
          leftActions={leftActions}
          operationsCount={operationsCount}
        />
      }
    >
      <ReviewGuard>
        <Stack direction="row">
          <Stack direction="row" justifyContent="flex-end" alignItems="baseline" gap={2} padding={1}>
            <ReviewChip review={review} size="medium" />
            <ReviewGuard editReview>
              <ReviewPopover review={review}>
                {(onClick) => <ReviewButton onClick={onClick} highlight={!review} />}
              </ReviewPopover>
            </ReviewGuard>
          </Stack>
        </Stack>
      </ReviewGuard>
      <Box sx={{ paddingX: 0 }}>
        <Card>
          <Stack component="form" onSubmit={handleSubmit} direction="row" gap={2} sx={{ padding: 2 }}>
            <FormControl
              sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', flex: 1, gap: 2 }}
            >
              <FormLabel id={radioId}>
                <Typography color="text.primary">{`${contextField?.label ?? 'contextField.label'}?`}</Typography>
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby={radioId}
                name="coincident-microtecnica-radio-buttons-group"
                value={`${optionFieldValue?.value ?? ''}`}
                onChange={handleValueChange}
                sx={{ gap: 2 }}
              >
                <FormControlLabel value="true" control={<Radio />} label="Si" />
                <FormControlLabel value="false" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
            <Box>
              <Button type="submit" variant="contained" disabled={optionFieldValue === optionFieldOriginalValue}>
                {'Applica'}
              </Button>
            </Box>
            <ReviewGuard editReview>
              <ReviewPopover review={review}>
                {(onClick) => <ReviewButton onClick={onClick} size="medium" highlight={!review} />}
              </ReviewPopover>
            </ReviewGuard>
          </Stack>
        </Card>
      </Box>
    </MaterialsStepLayout>
  );
}

export const MaterialKeepHyphenStep = React.memo(MaterialKeepHyphenStepComponent);
