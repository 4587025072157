import { z } from 'zod';

export enum STAKEHOLDER_ROLE {
  SHIP_TO = 'Ship To',
  BILL_TO = 'Bill To',
  ORDER_FROM = 'Order From',
  END_USERS = 'End Users',
}

export const StakeholderSchema = z.object({
  id: z.string(),
  ptId: z.string().optional(),
  name: z.string(),
  country: z.string(),
  address: z.string(),
  role: z.nativeEnum(STAKEHOLDER_ROLE).optional(),
  imported: z.boolean().optional(),
  importReason: z.string().min(1).optional(),
});

const PatchStakeholderListRequestSchema = z.object({
  paperworkId: z.number(),
  create: StakeholderSchema.omit({ id: true }).array(),
  update: StakeholderSchema.array(),
  delete: StakeholderSchema.pick({ id: true }).array(),
});

export const PTVendorSchema = z.object({
  id: z.string(),
  name: z.string(),
  country: z.string(),
});

export const PTCustomerSchema = z.object({
  id: z.string(),
  name: z.string(),
  country: z.string(),
  address: z.string(),
});

export const PTEndUserSchema = z.object({
  id: z.number(),
  name: z.string(),
  country: z.string(),
  address: z.string(),
});

export type Stakeholder = z.infer<typeof StakeholderSchema>;
export type PatchStakeholderListRequest = z.infer<typeof PatchStakeholderListRequestSchema>;
export type PTVendor = z.infer<typeof PTVendorSchema>;
export type PTCustomer = z.infer<typeof PTCustomerSchema>;
export type PTEndUser = z.infer<typeof PTEndUserSchema>;
