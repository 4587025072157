import { z } from 'zod';
import { StakeholderSchema } from './Stakeholder';

export const AttachmentSchema = z.object({
  id: z.number(),
  name: z.string(),
  path: z.string(),
  uploadDate: z.string(),
  username: z.string(),
});

export const PaperworkAttachmentSchema = z.object({
  attachment: AttachmentSchema,
  savedInContextId: z.number(),
  stakeholderIdList: z.array(StakeholderSchema.shape.id).optional(),
});
const PatchAttachmentListRequestSchema = z.object({
  paperworkId: z.number(),
  add: PaperworkAttachmentSchema.pick({ savedInContextId: true, stakeholderIdList: true })
    .extend({ id: AttachmentSchema.shape.id })
    .array(),
  delete: z.object({ id: AttachmentSchema.shape.id }).array(),
});

export const AttachmentCreateResponseSchema = z.object({
  id: z.number(),
  name: z.string(),
  path: z.string(),
  uploadDate: z.string(),
  username: z.string(),
});

export type Attachment = z.infer<typeof AttachmentSchema>;
export type PaperworkAttachment = z.infer<typeof PaperworkAttachmentSchema>;
export type PatchAttachmentListRequest = z.infer<typeof PatchAttachmentListRequestSchema>;
export type AttachmentCreateResponse = z.infer<typeof AttachmentCreateResponseSchema>;
