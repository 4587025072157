import React, { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { UserAutocomplete } from '@top-solution/microtecnica-mui';
import { useAuth } from '@top-solution/microtecnica-utils';
import { DetailSection } from '../../../components/DetailSection';
import { Layout } from '../../../components/Layout';
import { PageTitle } from '../../../components/PageTitle';
import {
  AddSerniListUpdate,
  AddSerniListUpdateSchema,
  SERNI_LIST_UPDATE_STATUS,
} from '../../../entities/SerniListUpdate';
import { NOTIFICATION_PREDEFINED_MESSAGES, useNotifications } from '../../../hooks/useNotifications';
import { useSerniListUpdate } from '../../../hooks/useSerniListUpdate/useSerniListUpdate';
import { useCreateSerniListUpdateMutation, useUpdateSerniListUpdateMutation } from '../../../services/serniListUpdate';
import PaperworkStatusSelect from '../../Paperwork/PaperworkCreatePage/PaperworkStatusSelect';
import { serniListUpdateCreateSection } from '../sections';
import PaperworkPrioritySelect from './SerniListUpdatePrioritySelect';

const PAGE_TITLE = serniListUpdateCreateSection.title;

export default function SerniListUpdateCreatePage(): React.ReactElement {
  const { username } = useAuth();
  const [create, createStatus] = useCreateSerniListUpdateMutation();
  const [update, updateStatus] = useUpdateSerniListUpdateMutation();
  const navigate = useNavigate();
  const { pushPredefinedNotification } = useNotifications();

  const { serniListUpdate } = useSerniListUpdate();

  const form = useForm<AddSerniListUpdate>({
    defaultValues: {
      assignee: username || '',
      createdAt: new Date(),
      statusId: SERNI_LIST_UPDATE_STATUS.DRAFT,
      priorityId: serniListUpdate?.priority.id ?? undefined,
    },
    resolver: zodResolver(AddSerniListUpdateSchema),
  });

  const { control } = form;

  const onSubmit = useCallback(
    async (values: AddSerniListUpdate) => {
      if (serniListUpdate) {
        await update({
          id: serniListUpdate.id,
          priorityId: values.priorityId,
          statusId: SERNI_LIST_UPDATE_STATUS.DRAFT,
          attachmentList: serniListUpdate.attachmentList,
          equipmentList: serniListUpdate.equipmentList,
        }).unwrap();
        pushPredefinedNotification(NOTIFICATION_PREDEFINED_MESSAGES.PAPERWORK_SAVE_SUCCESS);
        navigate(`/serni-list-updates/${serniListUpdate.id}`);
      } else {
        const res = await create({
          priorityId: values.priorityId,
          assignee: values.assignee,
          statusId: SERNI_LIST_UPDATE_STATUS.DRAFT,
        }).unwrap();
        navigate(`/serni-list-updates/${res.id}`);
      }
    },
    [create, navigate, pushPredefinedNotification, serniListUpdate, update]
  );

  return (
    <Layout title={PAGE_TITLE} header={<PageTitle pageTitle={PAGE_TITLE} />} maxWidth="xl">
      <Grid container spacing={2} sx={{ marginTop: 1 }}>
        <Grid xs={12}>
          <Stack
            component="form"
            onSubmit={form.handleSubmit(onSubmit)}
            noValidate
            id="create-serniListUpdate-form"
            spacing={1}
          >
            <Grid container spacing={2}>
              <Grid xs={12} sm={4}>
                <Controller
                  control={control}
                  name="assignee"
                  render={({ field: { onChange, ...field }, fieldState: { error } }) => (
                    <UserAutocomplete
                      label="Richiedente"
                      onChange={(_, value) => onChange(value)}
                      disableClearable
                      error={Boolean(error)}
                      helperText={error?.message ?? ' '}
                      {...field}
                      disabled
                    />
                  )}
                />
              </Grid>
              <Grid xs={12} sm={4}>
                <Controller
                  name="createdAt"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <DatePicker
                      label="Data apertura pratica"
                      {...field}
                      disabled
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error: Boolean(error),
                          helperText: error?.message || ' ',
                        },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid xs={12} sm={4}>
                <Controller
                  name="statusId"
                  control={control}
                  render={({ field }) => <PaperworkStatusSelect label="Stato pratica" {...field} disabled fullWidth />}
                />
              </Grid>
            </Grid>
            <DetailSection sectionTitle="Priorità richiesta" gutterBottom />
            <Grid container spacing={2}>
              <Grid md={4} xs={6}>
                <Controller
                  name="priorityId"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <PaperworkPrioritySelect
                      label="Priorità della richiesta"
                      {...field}
                      error={Boolean(error)}
                      value={field.value ?? ' '}
                      helperText={error?.message ?? ' '}
                      fullWidth
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Stack>
        </Grid>
        <Grid xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <LoadingButton
            variant="contained"
            color="primary"
            type="submit"
            form="create-serniListUpdate-form"
            loading={createStatus.isLoading || updateStatus.isLoading}
          >
            {serniListUpdate ? 'Modifica richiesta' : 'Crea richiesta'}
          </LoadingButton>
        </Grid>
      </Grid>
    </Layout>
  );
}
