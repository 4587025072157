import { useMemo } from 'react';
import { GridColDef, GridValidRowModel } from '@mui/x-data-grid-premium';
import { useReadProgramsQuery } from '@top-solution/microtecnica-utils';
import { ProgramChip } from '../ProgramChip';

export function useProgramColumn() {
  const { data } = useReadProgramsQuery();

  const valueOptions = useMemo(() => data?.list.map((v) => ({ value: v.id, label: v.name })), [data]);

  return useMemo(() => {
    const programColumn: GridColDef<GridValidRowModel, string, string> = {
      headerName: 'Programmi',
      field: 'program',
      minWidth: 150,
      type: 'singleSelect',
      valueOptions,
      renderCell: ({ value }) => {
        if (value) {
          const program = data?.map[value];
          if (program) {
            return <ProgramChip key={value} program={program} />;
          }
          return <ProgramChip key={value} program={{ id: '', name: value }} />;
        }
        return '—';
      },
    };

    return programColumn;
  }, [data?.map, valueOptions]);
}
