import React, { useEffect, useMemo } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import Alert from '@mui/material/Alert';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { DecimalTextField } from '@top-solution/microtecnica-mui';
import CurrencySelect from '../../../../../components/Form/CurrencySelect/CurrencySelect';
import {
  PAPERWORK_FIELD_DATATYPE,
  PaperworkFieldValueDraft,
  addDraftIdToPaperworkFieldValue,
  getPaperworkFieldValueDraftId,
} from '../../../../../entities/Paperwork';
import { ContextField, usePaperworkFields } from '../../../../../hooks/usePaperwork/usePaperworkFields';
import { useReadCurrencyListQuery } from '../../../../../services/currency';
import { Currency } from '../../../../Currency/currency';
import { MaterialTransferModeValues } from '../MaterialsDataStep/useMaterialTransferModeColumn';

type ContextFieldsMap = {
  [key in PAPERWORK_FIELD_DATATYPE]?: ContextField;
};

const transactionResolver = z
  .object({
    [PAPERWORK_FIELD_DATATYPE.MATERIAL_ORDER_NUMBER]: z.string().nullable().optional(),
    [PAPERWORK_FIELD_DATATYPE.MATERIAL_ORDER_DATE]: z.string().nullable().optional(),
    [PAPERWORK_FIELD_DATATYPE.MATERIAL_CURRENCY]: z.number().nullable().optional(),
    [PAPERWORK_FIELD_DATATYPE.MATERIAL_TRANSFER_MODE]: z.string(),
    [PAPERWORK_FIELD_DATATYPE.MATERIAL_TRANSACTION_TOTAL_VALUE]: z.number().nullable().optional(),
    // it's a FE field to enable the selection of order if the user want to insert it
    showOrder: z.boolean(),
    [PAPERWORK_FIELD_DATATYPE.MATERIAL_TRANSACTION_TOTAL]: z.boolean().nullable().optional(),
  })
  .superRefine((val, ctx) => {
    if (val[PAPERWORK_FIELD_DATATYPE.MATERIAL_TRANSACTION_TOTAL]) {
      if (!val[PAPERWORK_FIELD_DATATYPE.MATERIAL_TRANSACTION_TOTAL_VALUE]) {
        ctx.addIssue({
          code: z.ZodIssueCode.invalid_type,
          path: [PAPERWORK_FIELD_DATATYPE.MATERIAL_TRANSACTION_TOTAL_VALUE],
          message: 'Campo obbligatorio',
          expected: 'number',
          received: 'undefined',
        });
      }
      if (!val[PAPERWORK_FIELD_DATATYPE.MATERIAL_CURRENCY]) {
        ctx.addIssue({
          code: z.ZodIssueCode.invalid_type,
          path: [PAPERWORK_FIELD_DATATYPE.MATERIAL_CURRENCY],
          message: 'Campo obbligatorio',
          expected: 'number',
          received: 'undefined',
        });
      }
    }
    if (val['showOrder']) {
      if (!val[PAPERWORK_FIELD_DATATYPE.MATERIAL_ORDER_NUMBER]) {
        ctx.addIssue({
          code: z.ZodIssueCode.invalid_type,
          path: [PAPERWORK_FIELD_DATATYPE.MATERIAL_ORDER_NUMBER],
          message: 'Campo obbligatorio',
          expected: 'string',
          received: 'undefined',
        });
      }
      if (!val[PAPERWORK_FIELD_DATATYPE.MATERIAL_ORDER_DATE]) {
        ctx.addIssue({
          code: z.ZodIssueCode.invalid_type,
          path: [PAPERWORK_FIELD_DATATYPE.MATERIAL_ORDER_DATE],
          message: 'Campo obbligatorio',
          expected: 'string',
          received: 'undefined',
        });
      }
    }
  });

export type PaperworkTransaction = z.infer<typeof transactionResolver>;

function PaperworkTransactionFormComponent(props: {
  fieldValuesMap: Map<string, PaperworkFieldValueDraft>;
  setFormIsValid: (v: boolean) => void;
}) {
  const { fieldValuesMap, setFormIsValid } = props;

  const { contextFields, updateValue, deleteValue } = usePaperworkFields();

  const { data: currencies } = useReadCurrencyListQuery();

  const currenciesById = useMemo(
    () => currencies?.reduce((map, curr) => map.set(curr.id, curr), new Map<number, Currency>()),
    [currencies]
  );

  const getSelectedCurrencySymbol = (c: Currency['id']) => {
    if (!c || !currenciesById?.has(c)) return '-';
    return currenciesById.get(c)?.symbol;
  };

  const contextFieldsMap: ContextFieldsMap = [...contextFields].reduce((acc: ContextFieldsMap, field) => {
    acc[field.datatype] = field;
    return acc;
  }, {});

  const methods = useForm({
    defaultValues: {
      [PAPERWORK_FIELD_DATATYPE.MATERIAL_ORDER_NUMBER]:
        fieldValuesMap.get(PAPERWORK_FIELD_DATATYPE.MATERIAL_ORDER_NUMBER)?.value || (null as unknown as string),
      [PAPERWORK_FIELD_DATATYPE.MATERIAL_ORDER_DATE]:
        fieldValuesMap.get(PAPERWORK_FIELD_DATATYPE.MATERIAL_ORDER_DATE)?.value || (null as unknown as string),
      [PAPERWORK_FIELD_DATATYPE.MATERIAL_CURRENCY]:
        Number(fieldValuesMap.get(PAPERWORK_FIELD_DATATYPE.MATERIAL_CURRENCY)?.value) || (null as unknown as number),
      [PAPERWORK_FIELD_DATATYPE.MATERIAL_TRANSFER_MODE]:
        fieldValuesMap.get(PAPERWORK_FIELD_DATATYPE.MATERIAL_TRANSFER_MODE)?.value || (null as unknown as string),
      [PAPERWORK_FIELD_DATATYPE.MATERIAL_TRANSACTION_TOTAL_VALUE]: isNaN(
        Number(fieldValuesMap.get(PAPERWORK_FIELD_DATATYPE.MATERIAL_TRANSACTION_TOTAL_VALUE)?.value)
      )
        ? null
        : Number(fieldValuesMap.get(PAPERWORK_FIELD_DATATYPE.MATERIAL_TRANSACTION_TOTAL_VALUE)?.value),
      [PAPERWORK_FIELD_DATATYPE.MATERIAL_TRANSACTION_TOTAL]:
        fieldValuesMap.get(PAPERWORK_FIELD_DATATYPE.MATERIAL_TRANSACTION_TOTAL)?.value === 'true',
      showOrder: !!fieldValuesMap.get(PAPERWORK_FIELD_DATATYPE.MATERIAL_ORDER_NUMBER)?.value,
    },
    mode: 'onChange',
    resolver: zodResolver(transactionResolver),
  });

  useEffect(() => {
    methods.trigger();
    setFormIsValid(methods.formState.isValid);
  }, [methods, methods.formState.isValid, setFormIsValid]);

  const clearOrderFields = () => {
    methods.setValue(PAPERWORK_FIELD_DATATYPE.MATERIAL_ORDER_NUMBER, '');
    methods.setValue(PAPERWORK_FIELD_DATATYPE.MATERIAL_ORDER_DATE, '');
    deleteValue(
      getPaperworkFieldValueDraftId({
        progIndex: 0,
        fieldDatatype: PAPERWORK_FIELD_DATATYPE.MATERIAL_ORDER_NUMBER,
      }),
      fieldValuesMap.get(PAPERWORK_FIELD_DATATYPE.MATERIAL_ORDER_NUMBER)?.id
    );
    deleteValue(
      getPaperworkFieldValueDraftId({
        progIndex: 0,
        fieldDatatype: PAPERWORK_FIELD_DATATYPE.MATERIAL_ORDER_DATE,
      }),
      fieldValuesMap.get(PAPERWORK_FIELD_DATATYPE.MATERIAL_ORDER_DATE)?.id
    );
  };

  const clearTransactionFields = () => {
    methods.setValue(PAPERWORK_FIELD_DATATYPE.MATERIAL_CURRENCY, null as unknown as number);
    methods.setValue(PAPERWORK_FIELD_DATATYPE.MATERIAL_TRANSACTION_TOTAL_VALUE, 0);
    methods.setValue('showOrder', false);
    deleteValue(
      getPaperworkFieldValueDraftId({
        progIndex: 0,
        fieldDatatype: PAPERWORK_FIELD_DATATYPE.MATERIAL_CURRENCY,
      }),
      fieldValuesMap.get(PAPERWORK_FIELD_DATATYPE.MATERIAL_CURRENCY)?.id
    );
    deleteValue(
      getPaperworkFieldValueDraftId({
        progIndex: 0,
        fieldDatatype: PAPERWORK_FIELD_DATATYPE.MATERIAL_TRANSACTION_TOTAL_VALUE,
      }),
      fieldValuesMap.get(PAPERWORK_FIELD_DATATYPE.MATERIAL_TRANSACTION_TOTAL_VALUE)?.id
    );
    clearOrderFields();
  };

  return (
    <FormProvider {...methods}>
      <Stack component="form" noValidate direction="column" gap={2} sx={{ flex: 1, maxWidth: '700px' }}>
        <Controller
          control={methods.control}
          name={PAPERWORK_FIELD_DATATYPE.MATERIAL_TRANSFER_MODE}
          render={({ field, fieldState }) => (
            <TextField
              label={contextFieldsMap[PAPERWORK_FIELD_DATATYPE.MATERIAL_TRANSFER_MODE]?.label}
              variant="outlined"
              select
              {...field}
              onChange={(e) => {
                updateValue(
                  addDraftIdToPaperworkFieldValue({
                    fieldDatatype: PAPERWORK_FIELD_DATATYPE.MATERIAL_TRANSFER_MODE,
                    progIndex: 0,
                    value: e.target.value,
                    id: fieldValuesMap.get(PAPERWORK_FIELD_DATATYPE.MATERIAL_TRANSFER_MODE)?.id,
                  })
                );
                field.onChange(e.target.value);
                methods.trigger();
              }}
              error={Boolean(fieldState.error)}
              value={field.value ?? ''}
              helperText={fieldState.error?.message ?? ' '}
              sx={{ '& .MuiInputBase-input': { display: 'flex', justifyContent: 'start' }, minWidth: '300px' }}
              required={contextFieldsMap[PAPERWORK_FIELD_DATATYPE.MATERIAL_TRANSFER_MODE]?.isRequired}
            >
              {Object.values(MaterialTransferModeValues).map((v) => (
                <MenuItem key={v} value={v}>
                  {v}
                </MenuItem>
              ))}
            </TextField>
          )}
        />

        <Stack direction="row" sx={{ marginBottom: 1 }}>
          <Controller
            control={methods.control}
            name={PAPERWORK_FIELD_DATATYPE.MATERIAL_TRANSACTION_TOTAL}
            render={({ field }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={Boolean(field.value)}
                    onChange={(_, checked) => {
                      updateValue(
                        addDraftIdToPaperworkFieldValue({
                          fieldDatatype: PAPERWORK_FIELD_DATATYPE.MATERIAL_TRANSACTION_TOTAL,
                          progIndex: 0,
                          value: `${checked}`,
                          id: fieldValuesMap.get(PAPERWORK_FIELD_DATATYPE.MATERIAL_TRANSACTION_TOTAL)?.id,
                        })
                      );
                      field.onChange(checked);
                      if (!checked) clearTransactionFields();
                      methods.trigger();
                    }}
                  />
                }
                label={'Sono previsti pagamenti per la transazione?'}
                labelPlacement="start"
              />
            )}
          />
        </Stack>
        {!!methods.watch(PAPERWORK_FIELD_DATATYPE.MATERIAL_TRANSACTION_TOTAL) && (
          <>
            <Grid container columnSpacing={1} rowSpacing={2}>
              <Grid item xs={7}>
                <Controller
                  control={methods.control}
                  name={PAPERWORK_FIELD_DATATYPE.MATERIAL_TRANSACTION_TOTAL_VALUE}
                  render={({ field, fieldState }) => (
                    <DecimalTextField
                      label={contextFieldsMap[PAPERWORK_FIELD_DATATYPE.MATERIAL_TRANSACTION_TOTAL_VALUE]?.label}
                      variant="outlined"
                      fullWidth
                      {...field}
                      onChange={(e, v) => {
                        updateValue(
                          addDraftIdToPaperworkFieldValue({
                            fieldDatatype: PAPERWORK_FIELD_DATATYPE.MATERIAL_TRANSACTION_TOTAL_VALUE,
                            progIndex: 0,
                            value: `${v}`,
                            id: fieldValuesMap.get(PAPERWORK_FIELD_DATATYPE.MATERIAL_TRANSACTION_TOTAL_VALUE)?.id,
                          })
                        );
                        field.onChange(v);
                        methods.trigger();
                      }}
                      error={Boolean(fieldState.error)}
                      value={field.value ?? ('' as unknown as number)}
                      helperText={fieldState.error?.message ?? ' '}
                      required
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {getSelectedCurrencySymbol(
                              methods.watch(PAPERWORK_FIELD_DATATYPE.MATERIAL_CURRENCY) as number
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Stack direction="row" sx={{ marginBottom: 1 }}>
                  <Controller
                    control={methods.control}
                    name={PAPERWORK_FIELD_DATATYPE.MATERIAL_CURRENCY}
                    render={({ field, fieldState }) => (
                      <CurrencySelect
                        {...field}
                        onChange={(e) => {
                          updateValue(
                            addDraftIdToPaperworkFieldValue({
                              fieldDatatype: PAPERWORK_FIELD_DATATYPE.MATERIAL_CURRENCY,
                              progIndex: 0,
                              value: `${e.target.value}`,
                              id: fieldValuesMap.get(PAPERWORK_FIELD_DATATYPE.MATERIAL_CURRENCY)?.id,
                            })
                          );
                          field.onChange(e.target.value);
                          methods.trigger();
                        }}
                        error={Boolean(fieldState.error)}
                        value={field.value ?? ''}
                        helperText={fieldState.error?.message ?? ' '}
                        required
                      />
                    )}
                  />
                </Stack>
              </Grid>
            </Grid>
            <Stack direction="row" sx={{ marginBottom: 1 }}>
              <Controller
                control={methods.control}
                name={'showOrder'}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={Boolean(field.value)}
                        onChange={(_, checked) => {
                          field.onChange(checked);
                          if (!checked) clearOrderFields();
                          methods.trigger();
                        }}
                      />
                    }
                    label={'É presente un ordine?'}
                    labelPlacement="start"
                  />
                )}
              />
            </Stack>
            {!methods.watch('showOrder') && (
              <Alert
                severity="warning"
                sx={{
                  marginTop: 2,
                }}
              >
                {`Bisognerà fornire l'ordine direttamente a global trade appena disponibile`}
              </Alert>
            )}
          </>
        )}
        {!!methods.watch('showOrder') && (
          <>
            <Controller
              control={methods.control}
              name={PAPERWORK_FIELD_DATATYPE.MATERIAL_ORDER_NUMBER}
              render={({ field, fieldState }) => (
                <TextField
                  label={contextFieldsMap[PAPERWORK_FIELD_DATATYPE.MATERIAL_ORDER_NUMBER]?.label}
                  variant="outlined"
                  fullWidth
                  {...field}
                  error={Boolean(fieldState.error)}
                  value={field.value ?? ''}
                  helperText={fieldState.error?.message ?? ' '}
                  required
                  onChange={(e) => {
                    updateValue(
                      addDraftIdToPaperworkFieldValue({
                        fieldDatatype: PAPERWORK_FIELD_DATATYPE.MATERIAL_ORDER_NUMBER,
                        progIndex: 0,
                        value: e.target.value,
                        id: fieldValuesMap.get(PAPERWORK_FIELD_DATATYPE.MATERIAL_ORDER_NUMBER)?.id,
                      })
                    );
                    field.onChange(e.target.value);
                    methods.trigger();
                  }}
                />
              )}
            />
            <Controller
              control={methods.control}
              name={PAPERWORK_FIELD_DATATYPE.MATERIAL_ORDER_DATE}
              render={({ field, fieldState }) => (
                <DatePicker
                  label={contextFieldsMap[PAPERWORK_FIELD_DATATYPE.MATERIAL_ORDER_DATE]?.label}
                  {...field}
                  value={field.value ? new Date(field.value) : null}
                  onChange={(date) => {
                    updateValue(
                      addDraftIdToPaperworkFieldValue({
                        fieldDatatype: PAPERWORK_FIELD_DATATYPE.MATERIAL_ORDER_DATE,
                        progIndex: 0,
                        value: date?.toISOString(),
                        id: fieldValuesMap.get(PAPERWORK_FIELD_DATATYPE.MATERIAL_ORDER_DATE)?.id,
                      })
                    );
                    methods.trigger();
                    field.onChange(date?.toISOString());
                  }}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      helperText: fieldState.error?.message ?? ' ',
                      error: Boolean(fieldState.error),
                      required: true,
                    },
                  }}
                  sx={{ marginTop: 1 }}
                />
              )}
            />
          </>
        )}
      </Stack>
    </FormProvider>
  );
}

export const PaperworkTransactionForm = React.memo(PaperworkTransactionFormComponent);
