import { useEffect, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Stakeholder } from '../entities/Stakeholder';
import { usePatchStakeholderListMutation } from '../services/stakeholder';
import {
  createStakeholder,
  deleteStakeholder,
  discardOperations,
  replaceStakeholders,
  selectStakeholdersState,
  setSavingState,
  updateStakeholder,
} from '../store/stakeholdersStateSlice';
import { usePaperwork } from './usePaperwork/usePaperwork';

export function useStakeholders() {
  const dispatch = useDispatch();
  const { paperwork } = usePaperwork();
  const [_, patchStakeholderListRequest] = usePatchStakeholderListMutation();

  const { paperworkId, stakeholders, operations } = useSelector(selectStakeholdersState);

  useEffect(() => {
    if (paperwork) {
      if (paperwork.id !== paperworkId) {
        dispatch(
          replaceStakeholders({
            paperworkId: paperwork.id,
            stakeholders: paperwork.stakeholderList ?? [],
          })
        );
      }
    }
  }, [dispatch, paperwork, paperworkId]);

  return useMemo(() => {
    return {
      /**
       * Save the state of all stakeholders for the current paperwork.
       */
      saveStakeholders: async () => {
        /**
         * Dispatch the saving state
         * Do not save directly but "enqueue" the saving action, so that redux does that after
         * applying all the operations
         */
        dispatch(setSavingState({ paperworkId: paperwork?.id ?? -1, saving: true }));
      },
      /**
       * Create a stakeholder. All changes are kept on redux until `saveStakeholders` is called.
       */
      createStakeholder: (stakeholder: Stakeholder) => dispatch(createStakeholder({ stakeholder: stakeholder })),
      /**
       * Update a stakeholder. All changes are kept on redux until `saveStakeholders` is called.
       */
      updateStakeholder: (currentDraftId: string | undefined, stakeholder: Stakeholder) =>
        dispatch(
          updateStakeholder({
            stakeholder: stakeholder,
          })
        ),
      /**
       * Delete a stakeholder. All changes are kept on redux until `saveStakeholders` is called.
       */
      deleteStakeholder: (id: Stakeholder['id']) =>
        dispatch(
          deleteStakeholder({
            id: id,
          })
        ),
      /**
       * The RTKQuery request object for the PATCH save values request.
       */
      patchStakeholderListRequest,
      discardOperations: () => dispatch(discardOperations()),
      /**
       * The redux field values state. This propery contains the "local" changes, waiting to be saved on BE.
       */
      stakeholders: stakeholders,
      /**
       * Key-value registry for the pending CRUD operations for each field.
       */
      operations: operations,
      /**
       * The count of pending field values operations.
       */
      operationsCount: Object.values(operations).filter((value) => value !== undefined).length,
    };
  }, [patchStakeholderListRequest, stakeholders, operations, dispatch, paperwork?.id]);
}
