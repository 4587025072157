import { useEffect, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Attachment, PaperworkAttachment } from '../entities/Attachment';
import { usePatchAttachmentListMutation } from '../services/attachment';
import {
  createAttachment,
  deleteAttachment,
  discardOperations,
  replaceAttachments,
  selectAttachmentsState,
  setSavingState,
  updateAttachment,
} from '../store/attachmentsStateSlice';
import { usePaperwork } from './usePaperwork/usePaperwork';

export function useAttachments() {
  const dispatch = useDispatch();
  const { paperwork } = usePaperwork();

  const [_, patchAttachmentListRequest] = usePatchAttachmentListMutation();

  const { paperworkId, paperworkAttachments: attachments, operations } = useSelector(selectAttachmentsState);

  // Operations grouped by the attachment id (ignores stakeholder permutations)
  const uniqueOperations = useMemo(() => {
    const operationsWithoutStakeholder = Object.keys(operations)
      .filter((value) => value !== undefined)
      .map((draftId) => draftId.replace(/--[0-9]+$/, ''));
    return Array.from(new Set(operationsWithoutStakeholder)).length;
  }, [operations]);

  useEffect(() => {
    if (paperwork) {
      if (paperwork.id !== paperworkId) {
        dispatch(
          replaceAttachments({
            paperworkId: paperwork.id,
            paperworkAttachments: paperwork.paperworkAttachmentList ?? [],
          })
        );
      }
    }
  }, [dispatch, paperwork, paperworkId]);

  return useMemo(() => {
    return {
      /**
       * Save the state of all attachments for the current paperwork.
       */
      saveAttachments: async () => {
        /**
         * Dispatch the saving state
         * Do not save directly but "enqueue" the saving action, so that redux does that after
         * applying all the operations
         */
        dispatch(setSavingState({ paperworkId: paperwork?.id ?? -1, saving: true }));
      },
      /**
       * Update a single attachment. All changes are kept on redux until `saveAttachments` is called.
       */
      createAttachment: (paperworkAttachment: PaperworkAttachment) =>
        dispatch(createAttachment({ paperworkAttachment })),
      /**
       * Update a single attachment. All changes are kept on redux until `saveAttachments` is called.
       */
      updateAttachment: (currentDraftId: string | undefined, paperworkAttachment: PaperworkAttachment) =>
        dispatch(
          updateAttachment({
            currentDraftId: currentDraftId,
            paperworkAttachment,
          })
        ),
      /**
       * Delete a single attachment. All changes are kept on redux until `saveAttachments` is called.
       */
      deleteAttachment: (attachmentId: Attachment['id']) =>
        dispatch(
          deleteAttachment({
            attachmentId,
          })
        ),
      /**
       * The RTKQuery request object for the PATCH save values request.
       */
      patchAttachmentListRequest: patchAttachmentListRequest,
      discardOperations: () => dispatch(discardOperations()),
      /**
       * The redux field values state. This propery contains the "local" changes, waiting to be saved on BE.
       */
      attachments: attachments,
      /**
       * Key-value registry for the pending CRUD operations for each field.
       */
      operations: operations,
      /**
       * The count of pending field values operations.
       */
      operationsCount: uniqueOperations,
    };
  }, [patchAttachmentListRequest, attachments, operations, uniqueOperations, dispatch, paperwork?.id]);
}
